import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  ListGroup,
  Container,
  Form,
  InputGroup,
} from "react-bootstrap";
import Pagin from "../Pagin";
import moment from "moment";
import Axios from "axios";
import { notify } from "react-notify-toast";
import BSModal from "../../Layout/BSModal";
import LoadingScreen from "../../Layout/LoadingScreen";
import AddItemForm from "./AddItemForm";
import CloseRegister from "./CloseRegister";
import SearchBox from "./SearchBox";
import DownloadExcel from "./DownloadExcel";

import ConfirmClose from "./ConfirmClose";
import ModifyConfirm from "./ModifyConfirm";

export default class Smallbox extends Component {
  constructor(i) {
    super(i);
    this.state = {
      fullData: [],
      data: [],
      pagedData: [],
      modalState: {
        download: false,
        add: false,
        view: false,
        movements: false,
        modify: false,
        modifyId: "",
        closeDay: false,
        delete: false,
        deleteId: "",
      },
      movements: [],

      editId: "",
      isSaving: false,
      concept: "",
      detail: "",
      code: "",
      amount: "",
      type: true,
      todayAmount: 3000,
      totalAmount: 3000,
      isLoading: true,
      income: false,
      outcome: false,
      date: "",
      filter: false,
      level: 0,
      filterAplicated: [],
      pageSize: 5,
    };

    this.HEADERCONFIG = {
      headers: { "auth-token": localStorage.getItem("JWT") || " "},
    };
  }

  componentDidMount() {
    this.getItems();
  }

  getItems = () => {
    const smallboxAll = Axios.post("/api/smallbox/get", { currency: this.props.currency }, this.HEADERCONFIG);
    const moneyCount = Axios.post("api/smallbox/get/moneyCount", { currency: this.props.currency }, this.HEADERCONFIG);
    const checkUser = Axios.get("api/user/checkUser", this.HEADERCONFIG);

    Axios.all([smallboxAll, moneyCount, checkUser])
      .then(
        Axios.spread((...i) => {
          if (!i[0].data.success) {
            notify.show(i[0].data.message, "error");
            this.props.history.push("/students/viewAll");
          }

          i[0].data.data.sort((a, b) => {
            if (a._id > b._id) {
              return -1;
            } else if (a._id < b._id) {
              return 1;
            }
            return 1;
          });

          this.setState({
            data: i[0].data.data,
            fullData: i[0].data.data,
            todayAmount: i[1].data.amount.today,
            totalAmount: i[1].data.amount.total,
            level: i[2].data.level,
            isLoading: false,
          });
        })
      )
      .catch((err) => {
        notify.show(err.message, "error");
        this.props.history.push("/students/viewAll");
      });
  };

  onChangePage = (pagedData) => {
    this.setState({ pagedData });
  };

  customers = () => {
    let custs = [];
    this.state.fullData.map((i) => {
      custs.push({
        Creado: moment(i.createdAt).format("L LT"),
        Concepto: i.concept,
        Código: i.code,
        Monto: i.amount,
        Tipo: i.type ? "Ingreso" : "Egreso",
        Detalle: i.detail,
      });

      return 0;
    });
    return custs;
  };

  saveCustomers = () => {
    this.setState({
      customers: this.customers(),
      fileName: "Caja Chica",
    });
  };

  render() {
    const user = this.context;
    let { totalAmount, todayAmount, totalSearch, todaySearch } = this.state;

    const adminButtons =
      this.state.level === 1 ? (
        <>
          <Button
            className="mr-1"
            variant="info"
            onClick={() =>
              this.setState((i) => ({
                modalState: { movements: !i.modalState.movements },
              }))
            }
          >
            <i className="fas fa-dot-circle mr-2" />
            Ver cierres anteriores
          </Button>
          <Button
            className="mr-1"
            variant="success"
            onClick={() =>
              this.setState((i) => ({
                modalState: { download: !i.modalState.download },
              }))
            }
          >
            <i className="fas fa-dot-circle mr-2" />
            Descargar excel
          </Button>
        </>
      ) : null;

    if (this.state.isLoading) {
      return <LoadingScreen />;
    }
    
    return (
      <Container>
        <DownloadExcel
          show={this.state.modalState.download}
          onHide={() => {
            this.setState((i) => ({
              modalState: { ...i.modalState, download: !i.modalState.download },
            }));
          }}
        />

        <ModifyConfirm
          refresh={this.getItems}
          modifyId={this.state.modalState.modifyId}
          show={this.state.modalState.modify}
          onHide={() =>
            this.setState((i) => ({
              modalState: { ...i.modalState, modify: !i.modalState.modify },
            }))
          }
        />

        <CloseRegister
          modifyAlert={(aidi) =>
            this.setState((i) => ({
              modalState: {
                ...i.modalState,
                modify: !i.modalState.modify,
                modifyId: aidi,
              },
            }))
          }
          id={this.state.modalState.modifyId}
          items={this.state.fullData}
          onHide={() =>
            this.setState((i) => ({
              modalState: {
                ...i.modalState,
                movements: !i.modalState.movements,
              },
            }))
          }
          show={this.state.modalState.movements}
        />

        <AddItemForm
          editId={this.state.editId}
          refresh={this.getItems}
          onHide={() =>
            this.setState((i) => ({
              modalState: { ...i.modalState, add: !i.modalState.add },
            }))
          }
          show={this.state.modalState.add}
        />

        <BSModal
          show={this.state.modalState.delete}
          handleClose={() => {
            this.setState((prevState) => ({
              modalState: {
                ...prevState.modalState,
                delete: !prevState.modalState.delete,
              },
            }));
          }}
          title={"Advertencia"}
          body={"¿Seguro que querés eliminar el ítem?"}
          variant={"danger"}
          action={() => {
            Axios.delete(
              `/api/smallbox/delete/${this.state.modalState.deleteId}`,
              this.HEADERCONFIG
            ).then((res) => {
              this.setState((prevState) => ({
                modalState: {
                  ...prevState.modalState,
                  delete: !prevState.modalState.delete,
                },
              }));
              this.getItems();
              notify.show(res.data.message, "success");
            });
          }}
        />

        <ConfirmClose
          {...this.props}
          additionalData={{
            amountInternal: this.state.totalAmount,
            user: user.username,
          }}
          goHome={() => this.props.history.push("/students/viewAll")}
          show={this.state.modalState.closeDay}
          onHide={() => {
            this.setState((prevState) => ({
              modalState: {
                ...prevState.modalState,
                closeDay: !prevState.modalState.closeDay,
              },
            }));
          }}
        />

        <Row>
          <Col
            className="shadow h-100 my-auto bg-white rounded p-3 py-4 text-center"
            md="3"
          >
            <Row>
              <Col className="text-left">
                <small>Monto (Total):</small>
                <h1 className="font-weight-bold">
                  $
                  {this.state.filterAplicated.length !== 0
                    ? totalSearch
                    : totalAmount}
                </h1>
                <small>
                  <b>
                    {this.state.filterAplicated.length !== 0
                      ? `Filtros: ${this.state.filterAplicated}`
                      : null}
                  </b>
                </small>
              </Col>
            </Row>
            <Row>
              <Col className="text-left">
                <small>Monto (Hoy):</small>
                <h5 className="font-weight-bold">
                  $
                  {this.state.filterAplicated.length !== 0
                    ? todaySearch
                    : todayAmount}
                </h5>
              </Col>
            </Row>
          </Col>
          <Col />
          <Col
            md="auto"
            className="shadow bg-white rounded py-3 text-center my-auto"
          >
            <Button
              variant="info"
              onClick={() => {
                this.setState((prevState) => ({
                  modalState: {
                    ...prevState.modalState,
                    add: !prevState.modalState.add,
                  },
                  editId: "",
                }));
              }}
            >
              <i className="fas fa-plus-circle mr-2" />
              Registrar movimiento
            </Button>

            <Button
              className="mx-1"
              variant="danger"
              onClick={() =>
                this.setState((i) => ({
                  modalState: { closeDay: !i.modalState.closeDay },
                }))
              }
            >
              <i className="fas fa-times-circle mr-2" />
              Cerrar caja
            </Button>

            {adminButtons}

            <Form.Group as={Col} className="pt-3 pl-0 pr-0 mb-0">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Items por página:</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="select"
                  onChange={(e) =>
                    this.setState({ pageSize: e.target.value }, () =>
                      this.getItems()
                    )
                  }
                  name="pageSize"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        {/* Formulario de búsqueda */}

        <SearchBox
          refresh={this.getItems}
          setResults={(e) =>
            this.setState({
              data: e.data,
              filterAplicated: e.filter,
              totalSearch: e.amount.total,
              todaySearch: e.amount.today,
            })
          }
          currency={this.props.currency}
        />

        {
          this.state.data.length > 0 ?

            <Row>
              <Col className="p-3 my-3 bg-light rounded shadow">
                <Row className="px-4 pb-3">
                  <Col md="2">Fecha</Col>
                  <Col md="3">Creador / Concepto</Col>
                  <Col md="2">Código</Col>
                  <Col>Monto</Col>
                  <Col>Tipo</Col>
                  <Col md="2">Notas adicionales</Col>
                  <Col md="1" />
                </Row>
                <ListGroup as="ul">

                  {this.state.pagedData.map((i, a) => (
                    <ListGroup.Item action as="li" key={a}>
                      <Row>
                        <Col md="2">
                          <span className="m-0 p-0 text-truncate">
                            {moment(i.createdAt).format("L LT")}
                          </span>
                        </Col>
                        <Col
                          md="3"
                          className="text-truncate"
                          style={{ width: "%100" }}
                        >
                          <span
                            className="m-0 p-0"
                            style={{ borderBottom: "none" }}
                          >
                            <abbr
                              title={
                                typeof i.createdBy === "object"
                                  ? i.createdBy.name + " / " + i.concept
                                  : i.createdBy + " / " + i.concept
                              }
                            >
                              {i.concept}
                            </abbr>
                          </span>
                        </Col>
                        <Col md="2">
                          <span className="m-0 p-0">{i.code}</span>
                        </Col>
                        <Col>
                          <span className={i.type ? "text-success" : "text-danger"}>
                            ${i.amount}
                          </span>
                        </Col>
                        <Col>
                          <span className="m-0 p-0">
                            {i.type ? "Ingreso" : "Egreso"}
                          </span>
                        </Col>
                        <Col
                          md="2"
                          className="text-truncate"
                          style={{ width: "%100" }}
                        >
                          <span className="m-0 p-0">
                            <abbr title={i.detail}>{i.detail}</abbr>
                          </span>
                        </Col>
                        <Col md={1}>
                          <i
                            onClick={() => {
                              this.setState((a) => ({
                                editId: i._id,
                                modalState: {
                                  ...a.modalState,
                                  add: !a.modalState.add,
                                },
                              }));
                            }}
                            style={{ cursor: "pointer" }}
                            className="fas fa-edit p-1"
                            alt="Editar"
                          />
                          <i
                            onClick={() =>
                              this.setState({
                                modalState: {
                                  deleteId: i._id,
                                  delete: true,
                                },
                              })
                            }
                            style={{ cursor: "pointer" }}
                            className="fas fa-trash p-1 text-danger"
                            alt="Eliminar"
                          />
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}

                </ListGroup>
              </Col>
            </Row>
            :
            <>
              <Row>
                <Col className="p-3 my-3 bg-light rounded shadow pt-4 pb-4 d-flex justify-content-center">
                  <span className="m-0 p-0">No existe ningún registro!</span>
                </Col>
              </Row>
            </>
        }

        <Row>
          <Col className="p-3 mb-3 bg-light rounded shadow d-flex justify-content-center">
            <Pagin
              items={this.state.data}
              onChangePage={this.onChangePage}
              pageSize={this.state.pageSize}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
