import React, { useEffect, useState } from "react";
import Axios from 'axios';
import LoadingScreen from '../../Layout/LoadingScreen';
import { Row, Col, Container, Table } from 'react-bootstrap';
import { PieChart, Pie, Cell } from "recharts";

export default function Gender() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([
    {name: "Masculino", value: 0},
    {name: "Femenino", value: 0}
  ]);

  useEffect(() => {
    const getGender = async () => {
      await Axios.get('/api/graph/gender/')
      .then((response) => {
        setData([
          {name: "Hombres", value: response.data.male},
          {name: "Mujeres", value: response.data.female},
        ])
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(!isLoading));
    }

    getGender();
  }, [])

  const COLORS = ["#33B9FF", "#F61919"];
  
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    isLoading ? (
      <LoadingScreen />
    ) : (
      <Container className="container bg-light rounded shadow p-3 mb-3">
        <Row className="justify-content-center align-items-center flex-column">
          <Row>
          {data.map((entry, index) => (
                <Col key={index} className="d-flex justify-content-center align-items-center">
                  <div className="border" style={{width: 10, height: 10, backgroundColor: COLORS[index % COLORS.length] }} /> {entry.name}
                </Col>
              ))}
          </Row>
          <PieChart width={200} height={200}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </Row>
        <Row>
          <Col lg={8} className="container my-4">
            <Table bordered hover>
              <thead style={{backgroundColor: "lightgrey"}}>
              {data.map(gender => (
                  <td>{gender.name}</td>
                ))}
              </thead>
              <tbody>
                <tr>
                {data.map(gender => (
                  <td>{gender.value}</td>
                ))}
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    )
  );
}
