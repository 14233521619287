import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Axios from "axios";
import { notify } from "react-notify-toast";

export default function MoveStudentModal({
  show,
  handleClose,
  where,
  from,
  id,
  debtorCommission,
  refresh,
}) {
  const [comment, setComment] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  let translatedWhere = "";

  switch (where) {
    case "inProgress":
      translatedWhere = "en progreso";
      break;

    case "archived":
      translatedWhere = "archivados";
      break;

    case "debtors":
      translatedWhere = "deudores";
      break;

    default:
      translatedWhere = "<ERROR>";
  }

  const moveStudent = () => {
    setIsSaving(true);

    Axios.post(
      `/api/trackingComments/`,
      { studentId: id, comment, debtorCommission },
      {
        headers: { "auth-token": localStorage.getItem("JWT") || " " },
      }
    );

    Axios.put(
      `/api/statistics/tracking/${id}`,
      {
        moveTo: where,
        comment,
        debtorCommission,
      },
      {
        headers: { "auth-token": localStorage.getItem("JWT") || " " },
      }
    )
      .then(({ data }) => {
        notify.show(data.message, "success");
        setIsSaving(false);
        setComment("");
        handleClose();
        refresh(true, where, from, id, comment);
      })
      .catch((err) => {
        notify.show(err.response.data.message, "error");
        setIsSaving(false);
      });
  };
  return (
    <Form>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Se va a mover el estudiante a {translatedWhere}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">
          <p>¿Está seguro que desea continuar?</p>
          <Form.Group>
            <Form.Label>Comentario:</Form.Label>
            <Form.Control
              type="text"
              name="comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" disabled={isSaving} onClick={handleClose}>
            <i className="fas fa-window-close" /> Cancelar
          </Button>

          <Button variant="info" disabled={isSaving} onClick={moveStudent}>
            <i className="fas fa-save" /> Guardar cambios
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}
