import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  InputGroup,
  Form,
  FormControl,
} from 'react-bootstrap';
import { ClassroomList } from '../../Context/ClassroomListContext';
import Axios from 'axios';
import moment from 'moment';
import 'moment/locale/es';
import { notify } from 'react-notify-toast';

export default class Searchbox extends Component {
  static contextType = ClassroomList;

  constructor(props) {
    super(props);

    this.state = {
      student: '',
      email: '',
      classroom: '',
      payment: '',
      dateFrom: moment()
        .subtract(this.props.defaultLastDays, 'days')
        .format('YYYY-MM-DD'),
      dateTo: '',
      amount: 0,
      isObtainData: false,
    };
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onSubmit = async (e) => {
    e?.preventDefault();
    this.setState({ isObtainData: true });
    this.props.setIsDataLoading(true);
    try {
      const response = await Axios.post(`/api/bills/search/`, this.state, {
        headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
      });
      this.props.setResults(response.data);
    } catch {
      notify.show(
        'No se pudieron obtener los datos, intente más tarde',
        'error'
      );
    } finally {
      this.setState({ isObtainData: false });
      this.props.setIsDataLoading(false);
    }
  };

  clean = () => {
    this.setState({
      student: '',
      email: '',
      classroom: '',
      payment: '',
      dateFrom: '',
      dateTo: '',
      amount: 0,
    });
    this.props.refresh();
  };

  render() {
    const crList = this.context.crList;

    return (
      <Form onSubmit={this.onSubmit}>
        <Row className='bg-light shadow rounded p-3 my-3'>
          <Col>
            <Row className='flex-row justify-content-center'>
              <Col>
                <InputGroup size='sm'>
                  <InputGroup.Prepend>
                    <InputGroup.Text>Alumno</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    name='student'
                    value={this.state.student}
                    onChange={this.handleChange}
                    className='w-auto'
                  />
                </InputGroup>
              </Col>
              <Col>
                <InputGroup size='sm'>
                  <InputGroup.Prepend>
                    <InputGroup.Text>Email</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    name='email'
                    value={this.state.email}
                    onChange={this.handleChange}
                    className='w-auto'
                  />
                </InputGroup>
              </Col>
              <Col>
                <InputGroup size='sm'>
                  <InputGroup.Prepend>
                    <InputGroup.Text>Comisión</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    as='select'
                    value={this.state.classroom}
                    name='classroom'
                    onChange={this.handleChange}
                  >
                    <option value=''>Todas</option>{' '}
                    {crList.map((i) => (
                      <option key={i.classroom}>{i.classroom}</option>
                    ))}
                  </Form.Control>
                </InputGroup>
              </Col>

              <Col>
                <InputGroup size='sm'>
                  <InputGroup.Prepend>
                    <InputGroup.Text>Forma</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    as='select'
                    value={this.state.payment}
                    name='payment'
                    onChange={this.handleChange}
                  >
                    <option value=''>Todas</option>
                    <option>Efectivo</option>
                    <option>Banco</option>
                    <option>Tarjetas</option>
                    <option>MercadoPago</option>
                    <option>Paypal</option>
                    <option>Payway</option>
                    <option>Otro</option>
                  </Form.Control>
                </InputGroup>
              </Col>

              <Col>
                <InputGroup size='sm'>
                  <InputGroup.Prepend>
                    <InputGroup.Text>$ Mayor que</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    name='amount'
                    value={this.state.amount}
                    onChange={this.handleChange}
                    className='w-auto'
                  />
                </InputGroup>
              </Col>
            </Row>

            <Row className='d-flex justify-content-end'>
              <Col>
                <InputGroup size='sm' className='mt-3'>
                  <InputGroup.Prepend>
                    <InputGroup.Text>Desde</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    type='date'
                    name='dateFrom'
                    value={this.state.dateFrom}
                    onChange={this.handleChange}
                    className='w-auto'
                  />
                </InputGroup>
              </Col>

              <Col>
                <InputGroup size='sm' className='mt-3'>
                  <InputGroup.Prepend>
                    <InputGroup.Text>Hasta</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    type='date'
                    name='dateTo'
                    value={this.state.dateTo}
                    onChange={this.handleChange}
                    className='w-auto'
                  />
                </InputGroup>
              </Col>
              <Col md='2' className='mt-3'>
                <Button
                  className='w-100'
                  type='submit'
                  size='sm'
                  onClick={this.searchButton}
                  disabled={this.state.isObtainData}
                >
                  Buscar
                </Button>
              </Col>

              <Col md='2' className='mt-3'>
                <Button
                  className='w-100'
                  variant='info'
                  size='sm'
                  onClick={this.clean}
                >
                  Limpiar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  }
}
