import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Button, Form, InputGroup } from 'react-bootstrap';
import Axios from 'axios';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  PieChart,
  Pie,
  Cell,
} from 'recharts';

// Importando pantallas internas
import LoadingScreen from '../../Layout/LoadingScreen';
import CitiesExcel from './CitiesExcel';
import moment from 'moment';
import { filterAndformatDataForChart } from '../../helpers/filterByDate';
import { colors } from '../../helpers/colors';

export default function Graphics() {
  const [data, setData] = useState([]),
    [data01, setData01] = useState([]),
    [data02, setData02] = useState([]),
    [region, setRegion] = useState([]),
    [dataRaw, setDataRaw] = useState([]),
    [countriesArray, setCountries] = useState([]),
    [regionsArray, setRegions] = useState([]),
    [show, setShow] = useState(false),
    [isLoading, setIsLoading] = useState(true),
    [checkBoxValue, setCheckBoxValue] = useState('');
  const [isPeriodChecked, setIsPeriodChecked] = useState(false);
  const [argentinaData, setArgentinaData] = useState(null);
  const [argentinaRawData, setArgentinaRawData] = useState(null);
  const [selectedInitialDate, setSelectedInitialDate] = useState(
    moment().startOf('month').format('YYYY-MM-DD')
  );
  const [selectedFinalDate, setSelectedFinalDate] = useState(
    moment().endOf('month').format('YYYY-MM-DD')
  );

  let counter = 0;

  useEffect(() => {
    if (counter === 0) {
      fetchData();
      ++counter;
    }
    // eslint-disable-next-line
  }, []);

  let countries = [];
  let regions = [];
  let fetchData = () => {
    Axios.get(`/api/graph/cities/`, {
      headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
    }).then(({ data }) => {
      setDataRaw(data);
      let dataFiltered = [];

      let secondGraphic01 = [];
      let secondGraphic02 = [];

      for (var country in data.studentsOfSpecificCountry) {
        if (country === 'Indefinido') {
          let temporaryCountryObject = {
            country: country,
            ['Region no definida']:
              data.studentsOfSpecificCountry[country].length,
          };
          dataFiltered.push(temporaryCountryObject);

          secondGraphic01.push({
            name: country,
            value: data.studentsOfSpecificCountry[country].length,
          });
          secondGraphic02.push({
            name: 'Region no definida',
            value: data.studentsOfSpecificCountry[country].length,
          });
        } else {
          countries.push(country);
          let temporaryCountryObject = {
            country: country,
          };
          let quantityPerCountry = 0;
          for (var region in data.studentsOfSpecificCountry[country]) {
            regions.push(region);
            temporaryCountryObject[region] =
              data.studentsOfSpecificCountry[country][region].length;
            if (country === 'Argentina') {
              setArgentinaData(data.studentsOfSpecificCountry[country]);
              secondGraphic02.push({
                name: region,
                value: data.studentsOfSpecificCountry[country][region].length,
              });
              setArgentinaRawData(secondGraphic02);
            }
            quantityPerCountry =
              quantityPerCountry +
              data.studentsOfSpecificCountry[country][region].length;
          }

          secondGraphic01.push({
            name: country,
            value: quantityPerCountry,
          });
          dataFiltered.push(temporaryCountryObject);
        }
      }
      setData01(secondGraphic01);
      setData02(secondGraphic02);
      setData(dataFiltered);
      setRegion(data.regionText);
      setIsLoading(false);
      setRegions(regions);
      setCountries(countries);
    });
  };

  const handleChange = (event) => {
    setCheckBoxValue(event.target.value);
  };

  const handlePeriodsCheckbox = (e) => {
    setIsPeriodChecked(!isPeriodChecked);
    setCheckBoxValue('onlyCities');
  };

  useEffect(() => {
    setData02(
      filterAndformatDataForChart(
        argentinaData,
        selectedInitialDate,
        selectedFinalDate
      )
    );
    if (
      !isPeriodChecked ||
      selectedInitialDate === '' ||
      selectedFinalDate === ''
    )
      setData02(argentinaRawData);
  }, [selectedFinalDate, selectedInitialDate, isPeriodChecked]);

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <Container>
        <CitiesExcel
          show={show}
          onHide={() => setShow(false)}
          dataRaw={dataRaw}
          countriesArray={countriesArray}
          regionsArray={regionsArray}
        />
        <Row className="container d-flex bg-light rounded shadow p-3 mb-3 justify-content-center">
          <Col md={4}>
            <Form>
              <Form.Check type="radio">
                <Form.Check.Input
                  type="radio"
                  value="onlyCities"
                  name="checkBoxCities"
                  id="radioCheckOnlyCities"
                  checked={checkBoxValue === 'onlyCities'}
                  onChange={handleChange}
                />
                <Form.Check.Label for="radioCheckOnlyCities">
                  Solo Provincias Argentinas
                </Form.Check.Label>
              </Form.Check>
              <Form.Check type="radio">
                <Form.Check.Input
                  type="radio"
                  value="citiesAndCountries"
                  name="checkBoxCities"
                  id="radioCheckCitiesAndCountries"
                  checked={checkBoxValue === 'citiesAndCountries'}
                  onChange={handleChange}
                />
                <Form.Check.Label for="radioCheckCitiesAndCountries">
                  Paises y ciudades
                </Form.Check.Label>
              </Form.Check>
            </Form>
          </Col>
          <Col md={6}>
            <Form>
              <Form.Check type="checkbox">
                <Form.Check.Input
                  type="checkbox"
                  value="altasPorPeriodo"
                  name="checkBoxAltasPorPeriodo"
                  id="checkAltasPorPeriodo"
                  checked={isPeriodChecked}
                  onChange={handlePeriodsCheckbox}
                />
                <Form.Check.Label for="checkAltasPorPeriodo">
                  Altas por periodo
                </Form.Check.Label>
              </Form.Check>
              <Form.Row>
                <Col>
                  <Form.Group className="my-auto">
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>Desde:</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="date"
                        value={selectedInitialDate}
                        name="initialDate"
                        onChange={(e) =>
                          setSelectedInitialDate(e?.target?.value)
                        }
                        className="w-100"
                        disabled={!isPeriodChecked}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="my-auto">
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>Hasta:</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="date"
                        placeholder="Hasta"
                        value={selectedFinalDate}
                        name="finalDate"
                        onChange={(e) => setSelectedFinalDate(e?.target?.value)}
                        className="w-100"
                        disabled={!isPeriodChecked}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Form.Row>
            </Form>
          </Col>
          <Row>
            <Col className="text-right">
              <Button
                className="mr-2 mt-3"
                variant="success"
                onClick={() => setShow(true)}
              >
                <i className="fas fa-dot-circle mr-2" />
                Descargar excel
              </Button>
            </Col>
          </Row>
        </Row>
        {checkBoxValue === 'citiesAndCountries' ? (
          <Row className="container d-flex bg-light rounded shadow p-3 align-items-center">
            <Col id="">
              <BarChart
                width={1000}
                height={500}
                data={data}
                style={{ overflow: 'visible' }}
                className="mx-auto"
                barSize={50}
              >
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis dataKey="country" />
                <YAxis />
                <Tooltip />
                <Legend />
                {region?.map((ac) => {
                  return (
                    <Bar
                      dataKey={ac}
                      stackId="a"
                      key={ac}
                      fill={
                        '#' + ((Math.random() * 0xdfbacb) << 0).toString(16)
                      }
                    />
                  );
                })}
              </BarChart>
            </Col>
          </Row>
        ) : checkBoxValue === 'onlyCities' ? (
          <Row className="container d-flex bg-light rounded shadow p-3 align-items-center">
            <Col>
              <BarChart
                width={1000}
                height={500}
                data={data02?.sort((a, b) => b.value - a.value).slice(0, 10)}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip formatter={(value) => [`Cantidad: ${value}`]} />
                <Bar dataKey="value">
                  {data02.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors?.length]}
                    />
                  ))}
                </Bar>
                <Legend
                  payload={data02?.map((item, index) => ({
                    id: item.name,
                    type: 'square',
                    value: item.name,
                    color: colors[index % colors?.length],
                  }))}
                />
              </BarChart>
            </Col>
          </Row>
        ) : (
          <Row className="container d-flex bg-light rounded shadow p-3 align-items-center">
            <Col>
              <p>Selecciona un ítem para mostrar</p>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}
