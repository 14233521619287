import React from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';

const SkipAutoSuspend = ({ statusStudent, action, isLoading }) => {
  const status = statusStudent ? true : false;

  const formatDate = () => {
    const formatedDate = moment(statusStudent);
    const nameOfDay = formatedDate.format('dddd');
    const numberOfDay = formatedDate.format('D');
    return `Para ser omitido el ${nameOfDay} ${numberOfDay}`;
  };

  console.log(statusStudent);
  return (
    <>
      <Form.Check
        onChange={action}
        checked={status}
        label='Omitir suspensión automatica'
        disabled={isLoading}
      />
      {status && <p>{formatDate()}</p>}
    </>
  );
};

export default SkipAutoSuspend;
