import React, { Component } from "react";
import { incomes, outcome } from "../../Layout/Categories";
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import Axios from "axios";

export default class SearchBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      concept: "",
      code: "",
      type: "",
      dateFrom: "",
      dateTo: "",
      currency: this.props.currency
    };
  }

  handleChange = (e) => {
    let { name, value } = e.target;

    this.setState({ [name]: value });
  };

  onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    Axios.post(`/api/smallbox/search`, this.state, {
      headers: { "auth-token": localStorage.getItem("JWT") || " "},
    }).then(({ data }) => this.props.setResults(data));
  };

  clean = () => {
    this.props.refresh();
    this.setState({
      concept: "",
      code: "",
      type: "",
      dateFrom: "",
      dateTo: "",
      currency: this.props.currency
    }, () => this.onSubmit());
  };

  render() {
    // Combina ambos arrays de opciones para códigos y elimina los duplicados

    let combined = incomes.concat(outcome);
    let unique = [...new Set(combined)];

    return (
      <Form onSubmit={this.onSubmit}>
        <Row className="mt-3 p-3 bg-light shadow rounded">
          <Col md={9}>
            <Row>
              <Form.Group as={Col}>
                <Form.Control
                  type="text"
                  size="sm"
                  placeholder="Concepto"
                  value={this.state.concept}
                  onChange={this.handleChange}
                  name="concept"
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Control
                  as="select"
                  size="sm"
                  value={this.state.code}
                  onChange={this.handleChange}
                  name="code"
                >
                  <option value="">Seleccione una opción...</option>
                  {unique.map((i) => (
                    <option key={i}>{i}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>Desde:</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="date"
                    value={this.state.dateFrom}
                    onChange={this.handleChange}
                    name="dateFrom"
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group as={Col}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>Hasta:</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="date"
                    value={this.state.dateTo}
                    onChange={this.handleChange}
                    name="dateTo"
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group
                as={Col}
                className="text-center border border-gray py-1"
              >
                <Form.Check
                  inline
                  type="radio"
                  name="type"
                  checked={this.state.type === "true"}
                  onChange={this.handleChange}
                  value="true"
                  label="Ingresos"
                />
                <Form.Check
                  inline
                  type="radio"
                  name="type"
                  checked={this.state.type === "false"}
                  onChange={this.handleChange}
                  value="false"
                  label="Egresos"
                />
                <Form.Check
                  inline
                  type="radio"
                  name="type"
                  checked={this.state.type === ""}
                  onChange={this.handleChange}
                  value=""
                  label="Todos"
                />
              </Form.Group>
            </Row>
          </Col>
          <Col md={3} className="text-center my-auto">
            <Button type='submit' variant="info">
              Buscar
            </Button>
            <Button onClick={this.clean} className="ml-3 my-3" variant="primary">
              Limpiar búsqueda
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}
