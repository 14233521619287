import React, { useState, useEffect } from 'react';
import { Form, ListGroup } from 'react-bootstrap';

const Autocomplete = ({
  name,
  placeholder,
  dataSourge,
  value,
  setChanges,
  commission,
  setCommission,
}) => {
  const [options, setOptions] = useState(dataSourge);
  const [searchValue, setSearchValue] = useState('');
  const [suggestionsVisible, setSuggestionsVisible] = useState(false);
  const types = { string: 'string', object: 'object' };

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  const validateDataSourge = (arr) => {
    if (arr.length > 0) {
      if (typeof arr[0] === types.string) {
        return types.string;
      } else if (typeof arr[0] === types.object) {
        return types.object;
      }
    }
    return 'invalid';
  };
  const handleChangeAutocomplete = (event) => {
    const query = event.target.value;
    setSearchValue(query);
    if (query.length >= 2) {
      setSuggestionsVisible(true);
      if (validateDataSourge(dataSourge) === types.string) {
        setOptions(
          dataSourge.filter((option) => {
            return option.toLowerCase().includes(query.toLowerCase());
          })
        );
      } else if (validateDataSourge(dataSourge) === types.object) {
        setOptions(
          dataSourge
            .map((item) => {
              return {
                mentorName: `${item.firstName} ${item.lastName}`,
                id: item._id,
              };
            })
            .filter((option) => {
              return option.mentorName
                .toLowerCase()
                .includes(query.toLowerCase());
            })
        );
      }
    } else {
      setSuggestionsVisible(false);
      setOptions([]);
    }
  };

  const handleSelectOption = (selectedOption) => {
    setSearchValue(selectedOption.mentorName);
    setSuggestionsVisible(false);
    if (name === 'helper') {
      setCommission({ ...commission, helper: selectedOption.id });
    }
    if (name === 'mentor') {
      setCommission({ ...commission, mentor: selectedOption.id });
    }
    setChanges(true);
  };

  return (
    <div className="search-bar-container">
      <Form.Control
        type="text"
        name={name}
        placeholder={placeholder}
        value={searchValue}
        onChange={handleChangeAutocomplete}
        required
      />
      {suggestionsVisible && (
        <ListGroup className="suggestions-list">
          {options.map((option, index) => (
            <ListGroup.Item
              key={index}
              onClick={() => handleSelectOption(option)}
            >
              {`${option.mentorName}`}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

export default Autocomplete;
