import React, { useContext } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";
import AdminNav from "./links/AdminNav";
import CommunicationsNav from "./links/CommunicationsNav";
import MentorNav from "./links/MentorNav";
import StatisticsNav from "./links/StatisticsNav";
import UserAdministration from "./UserAdministration";
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { USER_LEVEL_MENTOR_STATISTICS, USER_LEVEL_STATISTICS, USER_LEVEL_MENTOR, USER_LEVEL_COMMUNICATION, USER_LEVEL_ADMIN } from "../../constants";
import preval from "preval.macro";
const buildTimeStamp = preval`module.exports = new Date().toLocaleString()`;

export default function Navigation({ errorPage }: { errorPage: Boolean }) {
  const user = useContext(UserContext);

  let links = <div />;
  let title =
    window.location.hostname === "mentors.rollingcodeschool.com"
      ? "Mentor's Dashboard" :
      user.level === USER_LEVEL_STATISTICS
        ? "Statistic's Dashboard"
        : user.level === USER_LEVEL_MENTOR || user.level === USER_LEVEL_MENTOR_STATISTICS
          ? "Mentor's Dashboard"
          : user.level < USER_LEVEL_MENTOR
            ? "Student Manager"
            : "";

  if (user.level === USER_LEVEL_ADMIN) {
    links = <AdminNav />;
  } else if (user.level === USER_LEVEL_COMMUNICATION) {
    links = <CommunicationsNav />;
  } else if (user.level === USER_LEVEL_MENTOR || user.level === USER_LEVEL_MENTOR_STATISTICS) {
    links = <MentorNav />;
  } else if (user.level === USER_LEVEL_STATISTICS) {
    links = <StatisticsNav />;
  } else {
    links = <div />;
  }

  const popover = (
    <Popover id="build">
      <Popover.Title as="h3">Last Deploy - Date:</Popover.Title>
      <Popover.Content>
        {buildTimeStamp}
      </Popover.Content>
    </Popover>
  );

  return (
    <Navbar
      collapseOnSelect={true}
      expand="lg"
      bg="dark"
      variant="dark"
      className={errorPage ? "d-none" : ""}
    >
      <Link to="/">
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="bottom"
          overlay={popover}
        >
          <Navbar.Brand className="text-dark text-decoration-none">
            <img
              src={process.env.PUBLIC_URL + "/rclogowhite.png"}
              width="180"
              alt="RollingCode"
            />
            <p className="mb-2 lead text-center text-white">{title}</p>
          </Navbar.Brand>
        </OverlayTrigger>
      </Link>
      <Navbar.Toggle />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">{links}</Nav>
        <UserAdministration />
      </Navbar.Collapse>
    </Navbar>
  );
}
