import { faDownload, faShareSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Row, Button, Form, Spinner } from "react-bootstrap";

export default function Buttons({
  exportPDFToFile,
  sendEmail,
  emailFromStudent = "",
}: {
  exportPDFToFile: () => void;
  sendEmail: (date: string, email: string) => void;
  emailFromStudent: string;
}) {
  const [hideEmailInput, setHideEmailInput] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [message, showMessage] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsSending(true);
    setIsSaving(true);
    showMessage(true);
    sendEmail("12/12/2020", email);
  };

  useEffect(() => {
    setEmail(emailFromStudent);
  }, [emailFromStudent]);

  return (
    <Col className="text-center p-3 bg-light shadow rounded mb-5">
      <Row>
        <Col>
          <Button
            disabled={isSaving}
            variant="info"
            className="mr-3"
            onClick={exportPDFToFile}
          >
            <FontAwesomeIcon icon={faDownload} className="mr-2" />
            Guardar como PDF
          </Button>
          <Button
            variant="info"
            disabled={isSending}
            onClick={() => {
              setHideEmailInput((i) => !i);
            }}
          >
            <FontAwesomeIcon icon={faShareSquare} /> Enviar por mail
          </Button>
        </Col>
      </Row>
      <Row className={hideEmailInput ? "d-none" : "d-block"}>
        <Col>
          <div className={!message ? "d-block mt-3" : "d-none"}>
            <Form
              autoComplete="off"
              className="text-left px-5"
              onSubmit={handleSubmit}
            >
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Confirmar correo</Form.Label>
                <Form.Control
                  required
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Button type="submit" variant="success">
                <FontAwesomeIcon icon={faShareSquare} /> Enviar
              </Button>
            </Form>
          </div>
          <div className={message ? "mt-3" : "d-none"}>
            <Spinner
              size="sm"
              animation="border"
              role="status"
              className="mr-2"
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
            <br />
            <p className="m-0 p-0">Enviando boleta por mail...</p>
          </div>
        </Col>
      </Row>
    </Col>
  );
}
