import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function HerokuAdviceSign({
  show,
  onHide,
}: {
  show: boolean;
  onHide: () => void;
}) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>¡Ey!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Estás en el ambiente de prueba. Si sos parte del equipo de QA o Dev no
        pasa nada pero si sos parte de comunicaciónes lo mejor es que uses{" "}
        <a href="https://admin.rollingcodeschool.com/">
          admin.rollingcodeschool.com
        </a>{" "}
        ya que ahí estan los datos que se usan realmente.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Soy Dev / QA
        </Button>
        <Button variant="info" href="https://admin.rollingcodeschool.com/">
          ¡Vamos a producción!
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
