import React, { useState, useEffect, useRef } from 'react';
import './css/CourseList.css';
import {
  Col,
  ToggleButton,
  ButtonGroup,
  Popover,
  OverlayTrigger,
  Button,
} from 'react-bootstrap';
import clientAxios from '../../../../clients/clientAxios';
import { notify } from 'react-notify-toast';
import Plans from '../modals/Plans';
import { ShowPlanById } from '../modals/ShowPlanById';
const certificateBaseUrl = 'https://certs.rollingcodeschool.com';

export default function CourseList({
  scholarship,
  classroom,
  isActive,
  course,
  price,
  certificate,
  classroomActive,
  fullClassRoom,
  studentId,
  certificatesFromService,
  student,
  getStudentData,
  campusUserFromWS,
  hideLoadingLayout,
}) {
  const [classRoomActiveStatusState, setClassRoomActiveStatusState] = useState(
    classroomActive || []
  );
  const [fullPlanInfo, setFullPlanInfo] = useState({
    planId: '',
    classroom: '',
    classroomId: '',
  });
  const [popOverState, setPopOvers] = useState(0);
  const [currentClassroomId, setCurrentClassroomId] = useState('');
  const [state, setState] = useState('');
  const [indexState, setIndexState] = useState('');
  const loadingLayoutRefreshTimeout = 1000;
  const [show, setShow] = useState(false);
  const [classroomIndex, setClassroomIndex] = useState(null);
  const handlePlansModalClose = () => setShow(false);
  const handlePlansModalShow = () => setShow(true);

  useEffect(() => {
    setClassRoomActiveStatusState(classroomActive || []);
    //eslint-disable-next-line
  }, [classroomActive]);

  const setActiveStatus = async (classroomId, status) => {
    hideLoadingLayout(false);
    try {
      if (popOverState === 2 || popOverState === 1 || popOverState === 0) {
        const { data } = await clientAxios.patch('/students', {
          studentId,
          classroomId,
          status,
          email: student?.email,
        });

        if (data.success) {
          notify.show(
            'Se ha actualizado el estado del comision en Rolling Universe',
            'success'
          );
        } else {
          notify.show(data.message, 'error');
        }
        getStudentData();
        setTimeout(() => {
          hideLoadingLayout(true);
        }, loadingLayoutRefreshTimeout);
      }
      if (popOverState === 3 || popOverState === 1 || popOverState === 0) {
        if (student?.campusUserId || campusUserFromWS?.id) {
          const campusUserId = student?.campusUserId || campusUserFromWS?.id;
          clientAxios.patch('/campus/suspend', {
            campusUserId: campusUserId,
            status: status === 'suspended',
            email: student?.email,
          });
          notify.show('Se ha actualizado el estado en el campus', 'success');
        } else {
          notify.show('No se se encontro usuario en el Campus', 'error');
        }
        getStudentData();
        setTimeout(() => {
          hideLoadingLayout(true);
        }, loadingLayoutRefreshTimeout);
      }
    } catch (err) {
      getStudentData();
      hideLoadingLayout(true);
      notify.show(
        'No se pudo actualizar el testado de la comision, intenta mas tarde por favor',
        'error'
      );
    }
  };
  const translateIsActive = (currentIsActive) => {
    switch (currentIsActive) {
      case 'up':
        return {
          className: 'text-dark',
          text: 'Activo',
        };

      case 'done':
        return {
          className: 'text-success',
          text: 'Curso finalizado',
        };

      case 'posibleDown':
        return {
          className: 'text-darkYell',
          text: 'Posible baja',
        };

      case 'prepay':
        return {
          className: 'text-darkYell',
          text: 'Curso señado',
        };

      case 'down':
        return {
          className: 'text-danger',
          text: 'Baja',
        };

      case 'commissionChanged':
        return {
          className: 'text-dark',
          text: 'Activo (Cambió comisión)',
        };

      default:
        return {
          className: 'text-muted',
          text: 'No especificado',
        };
    }
  };

  const getPropertyFromArrayOfActiveOrPrice = (
    currentClassRoom,
    arrayToGetElement
  ) => {
    if (student.classroom?.length > 0) {
      const indexStudent = student.classroom.findIndex(
        (element) => element === currentClassRoom.classroom
      );
      if (indexStudent !== -1) {
        return arrayToGetElement[indexStudent];
      }
    }
    return '';
  };

  const getCertUrl = (certItem) => {
    if (certItem?.filename?.includes('.png')) {
      return `${certificateBaseUrl}/${certItem?.filename}`;
    }
    return `${certificateBaseUrl}/${certItem?.filename}_1.png`;
  };

  const getScholarshipStatus = (classroomName) => {
    if (student?.classroom?.length <= 0) {
      return false;
    }
    const scholarshipIndex = student?.classroom?.findIndex(
      (element) => element === classroomName
    );
    if (scholarshipIndex === -1) {
      return false;
    }
    return (
      scholarshipIndex <= student?.scholarship.length &&
      student?.scholarship[scholarshipIndex]
    );
  };

  const hasNoPlan = (classroomId, index, classroom) => {
    if (!student?.plan) {
      return (
        <div className='d-flex align-items-center'>
          <li className='text-danger text-uppercase'>No posee plan de pago</li>
          <Button
            size='sm'
            className='ml-1'
            onClick={() => {
              handlePlansModalShow();
              setFullPlanInfo({
                classroomId,
                classroom,
                planId: '',
              });
              setClassroomIndex(index);
            }}
          >
            Agregar Plan
          </Button>
        </div>
      );
    }
    const validPlan = student?.plan?.find(
      (plan) => plan?.classroomId === classroomId
    );
    if (validPlan) {
      return (
        <div className='text-center d-inline mx-2'>
          <ShowPlanById
            id={validPlan.planId}
            student={student}
            indexCommission={index}
          />
        </div>
      );
    }
    return (
      <div className='d-flex align-items-center'>
        <li className='text-danger text-uppercase'>No posee plan de pago</li>
        <Button
          size='sm'
          className='ml-1'
          onClick={() => {
            handlePlansModalShow();
            setFullPlanInfo({
              classroomId,
              classroom,
              planId: '',
            });
            setClassroomIndex(index);
          }}
        >
          Agregar Plan
        </Button>
      </div>
    );
  };

  const handleFunction = (opcion) => {
    setPopOvers(opcion);
  };

  useEffect(() => {
    if (popOverState !== 0) {
      setActiveStatus(currentClassroomId, state, indexState);
    }
  }, [handleFunction]);

  const popoverTop = (
    <Popover id='popover-positioned-top'>
      <button
        onClick={() => handleFunction(1)}
        className='btn w-100'
        style={{ borderBottom: '1px solid red' }}
      >
        Suspender Universe y Campus
      </button>{' '}
      <br />
      <button
        className='btn'
        onClick={() => handleFunction(2)}
        style={{ borderRight: '1px solid red' }}
      >
        {' '}
        Solo Universe
      </button>
      <button className='btn' onClick={() => handleFunction(3)}>
        Solo Campus
      </button>
    </Popover>
  );

  return (
    <>
      <Col className='ml-3 mb-5 p-3 bg-light shadow rounded'>
        <p className='lead m-0 p-0'>Cursos inscriptos:</p>
        <br />
        <div style={{ height: '85%', overflowY: 'scroll' }}>
          <ul className='list-unstyled'>
            {typeof course === 'object' && typeof classroom === 'object' ? (
              fullClassRoom.map((currentClassroom, index) => {
                return (
                  <div key={index}>
                    <li className='text-uppercase d-inline'>
                      <small className='text-muted font-weight-bold'>
                        0{index + 1} -{' '}
                        {course[index] === null ? (
                          <i className='text-lowercase'>
                            (Esta comision no tiene un curso asignado)
                          </i>
                        ) : course[index] === undefined ? (
                          <i className='text-lowercase'>
                            (Verificar que el curso esté configurado
                            correctamente en la comisión)
                          </i>
                        ) : (
                          course[index]
                        )}
                      </small>
                    </li>
                    <ul className='mt-3'>
                      <div>
                        <ButtonGroup className='mb-2'>
                          {classRoomActiveStatusState?.find(
                            (element) => element?._id === currentClassroom._id
                          ) && (
                            <ToggleButton
                              key={`radioActive-${index}`}
                              id={index}
                              type='radio'
                              variant='outline-success'
                              name={`radioActive-${index}`}
                              value='active'
                              checked={classRoomActiveStatusState?.find(
                                (element) =>
                                  element?._id === currentClassroom._id &&
                                  element.status === 'active'
                              )}
                              onClick={() => {
                                setActiveStatus(
                                  currentClassroom._id,
                                  'active',
                                  index
                                );
                              }}
                            >
                              Activo
                            </ToggleButton>
                          )}
                          <OverlayTrigger
                            trigger='click'
                            placement='top'
                            overlay={popoverTop}
                          >
                            <ToggleButton
                              key={`radioSuspended-${index}`}
                              id={`radio-${index}`}
                              type='radio'
                              variant='outline-danger'
                              name={`radioSuspended-${index}`}
                              value='suspended'
                              checked={classRoomActiveStatusState?.find(
                                (element) =>
                                  element?._id === currentClassroom._id &&
                                  element.status === 'suspended'
                              )}
                              style={{ position: 'relative' }}
                            >
                              Suspendido
                              <Button
                                className='buttonHidden'
                                key={index}
                                onClick={() => {
                                  setCurrentClassroomId(currentClassroom._id);
                                  setState('suspended');
                                  setIndexState(index);
                                }}
                              ></Button>
                            </ToggleButton>
                          </OverlayTrigger>
                          <br />
                        </ButtonGroup>
                      </div>
                      <li>
                        <small className='text-muted'>Comisión: </small>
                        {currentClassroom.classroom}
                      </li>
                      <li>
                        <small className='text-muted'>Precio: </small>$
                        {getPropertyFromArrayOfActiveOrPrice(
                          currentClassroom,
                          price
                        )}
                        {hasNoPlan(
                          currentClassroom?._id,
                          index,
                          currentClassroom?.classroom
                        )}
                      </li>
                      <li>
                        <small className={'text-muted'}>Estado: </small>
                        <span
                          className={
                            translateIsActive(
                              getPropertyFromArrayOfActiveOrPrice(
                                currentClassroom,
                                isActive
                              )
                            ).className
                          }
                        >
                          {
                            translateIsActive(
                              getPropertyFromArrayOfActiveOrPrice(
                                currentClassroom,
                                isActive
                              )
                            ).text
                          }
                        </span>
                      </li>
                      {scholarship &&
                        scholarship.length > 0 &&
                        getScholarshipStatus(currentClassroom?.classroom) && (
                          <li>
                            <small className={'text-muted'}>Becado </small>
                          </li>
                        )}
                    </ul>
                  </div>
                );
              })
            ) : (
              <>
                <li className='text-uppercase'>
                  <small className='text-muted font-weight-bold'>
                    {course}
                  </small>
                </li>
                <ul>
                  <li>
                    <small className='text-muted'>Comisión: </small>
                    {classroom}
                  </li>
                  <li>
                    <small className='text-muted'>Precio: </small>${price}
                  </li>
                  <li>
                    <small className={'text-muted'}>Estado: </small>
                    <span
                      className={translateIsActive(isActive || '').className}
                    >
                      {translateIsActive(isActive || '').text}
                    </span>
                  </li>
                  {scholarship && scholarship.length > 0 && scholarship[0] && (
                    <li>Becado</li>
                  )}
                </ul>
              </>
            )}
            <div className='mt-3'>
              <li className='text-uppercase'>Certificados</li>
              {certificate && certificate.loading && <li>...</li>}
              {certificate &&
                certificate.items.length > 0 &&
                certificate.items.map((certItem) => (
                  <li>
                    {certItem?.filename && (
                      <>
                        <a
                          href={getCertUrl(certItem)}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <i className='text-danger mr-3 fas fa-certificate '></i>
                        </a>
                        <small>{certItem?.courseName}</small>
                      </>
                    )}
                  </li>
                ))}
            </div>
          </ul>
        </div>
      </Col>
      <Plans
        show={show}
        studentId={studentId}
        student={student}
        setFullPlanInfo={setFullPlanInfo}
        fullPlanInfo={fullPlanInfo}
        classroomIndex={classroomIndex}
        getStudentData={getStudentData}
        handlePlansModalClose={handlePlansModalClose}
      />
    </>
  );
}
