import React, { Component } from "react";
import Axios from "axios";
import { notify } from "react-notify-toast";
import { incomes, outcome } from "../../Layout/Categories";

import { Form, Modal, Button } from "react-bootstrap";

export default class AddItemForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editItem: "",
      concept: "",
      detail: "",
      code: "",
      amount: "",
      type: true,
      isSaving: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (this.props.editId) {
        Axios.get(`/api/smallbox/get/single/${this.props.editId}`, {
          headers: { "auth-token": localStorage.getItem("JWT") || " "},
        }).then((i) => {
          this.setState({ ...i.data.data[0] });
        });
      } else {
        this.setState({
          editItem: "",
          concept: "",
          detail: "",
          code: "",
          amount: "",
          type: true,
          isSaving: false,
        });
      }
    }
  }

  handleChange = async (obj) => {
    const { name, value, type } = obj.target;

    if (type === "radio") {
      this.setState((i) => ({
        type: !i.type,
      }));
    } else {
      this.setState({ [name]: value });
    }
  };

  onSubmit = (obj) => {
    obj.preventDefault();
    this.setState({ isSaving: true });
    let { concept, detail, code, amount, type } = this.state;
    let currency = this.props.currency;
    if (this.props.editId) {
      Axios.put(
        `/api/smallbox/update/${this.props.editId}`,
        { concept, detail, code, amount, type, currency },
        { headers: { "auth-token": localStorage.getItem("JWT") || " "} }
      )
        .then((res) => {
          notify.show(res.data.message, res.data.success ? "success" : "error");

          if (res.data.success) {
            this.props.refresh();
            this.setState({ isSaving: false });
            this.props.onHide();
          } else {
            this.setState({ isSaving: false });
          }
        })
        .catch((res) => {
          notify.show(res.data.message, res.data.success ? "success" : "error");
          this.setState({ isSaving: false });
        });
    } else {
      Axios.post(
        "/api/smallbox/post",
        { concept, detail, code, amount, type, currency },
        { headers: { "auth-token": localStorage.getItem("JWT") || " "} }
      ).then((res) => {
        notify.show(res.data.message, res.data.success ? "success" : "error");

        if (res.data.success) {
          this.setState({ isSaving: false });
          this.props.refresh();
          this.props.onHide();
        } else {
          this.setState({ isSaving: false });
        }
      });
    }
  };

  render() {
    let { concept, detail, code, amount, type } = this.state;

    return (
      <Modal
        centered
        size="lg"
        show={this.props.show}
        onHide={this.props.onHide}
      >
        <Form onSubmit={this.onSubmit} autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title>Registrar movimiento</Modal.Title>
          </Modal.Header>

          <Modal.Body className="mx-5">
            <Form.Group controlId="concept">
              <Form.Label>Concepto:</Form.Label>
              <Form.Control
                required
                name="concept"
                type="text"
                max={25}
                value={concept}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group controlId="type">
              <Form.Label>Tipo: </Form.Label>
              <br />
              <Form.Check
                name="type"
                type="radio"
                label="Ingreso"
                id="type"
                value={true}
                checked={Boolean(this.state.type) === true}
                onChange={this.handleChange}
                inline
              />
              <Form.Check
                name="type"
                type="radio"
                label="Egreso"
                id="type"
                value={false}
                checked={Boolean(this.state.type) === false}
                onChange={this.handleChange}
                inline
              />
            </Form.Group>

            <Form.Group controlId="code">
              <Form.Label>Código:</Form.Label>
              <Form.Control
                required
                name="code"
                as="select"
                value={code}
                onChange={this.handleChange}
              >
                <option value="">Seleccione una opción...</option>
                {type
                  ? incomes.map((i) => <option key={i}>{i}</option>)
                  : outcome.map((i) => <option key={i}>{i}</option>)}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="amount">
              <Form.Label>Monto:</Form.Label>
              <Form.Control
                required
                name="amount"
                min={1}
                max={1000000}
                step="0.01"
                type="number"
                value={amount}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group controlId="detail">
              <Form.Label>Nota adicional:</Form.Label>
              <Form.Control
                name="detail"
                type="text"
                value={detail}
                onChange={this.handleChange}
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onHide}>
              Cerrar
            </Button>
            <Button
              variant="success"
              disabled={this.state.isSaving}
              type="submit"
            >
              Guardar cambios
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
