import React, { useState, useEffect } from "react";
import { Row, Col, Container, ListGroup } from "react-bootstrap";
import CustomCard from "./subcomponents/CustomCard";
import Axios from "axios";
import { notify } from "react-notify-toast";
import EditProduct from "./subcomponents/EditProduct";
import Pagin from "../Pagin";
import LoadingScreen from "../../Layout/LoadingScreen";
import { RKioskItem } from "../../Types/RKioskItem";

export default function ViewProducts() {
  const [list, setList] = useState<RKioskItem[]>([]);
  const [pagedList, setPagedList] = useState<RKioskItem[]>([]);

  const [itemToEdit, setItemToEdit] = useState<RKioskItem>({
    _id: "",
    stock: 0,
    image: "",
    name: "",
    barcode: 0,
    price: 0,
  });

  const [amounts, setAmounts] = useState({
    today: 0,
    week: 0,
    all: 0,
  });

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const headerConfig = {
    headers: { "auth-token": localStorage.getItem("JWT") || " "},
  };

  /**
   * Obtiene los datos del backend
   */
  const getData = () => {
    const getProducts = Axios.get(`/api/rollingkiosk/products`, headerConfig);
    const getTodayAmount = Axios.get(
      `/api/rollingkiosk/buylog/day`,
      headerConfig
    );
    const getWeekAmount = Axios.get(
      `/api/rollingkiosk/buylog/week`,
      headerConfig
    );
    const getAllAmount = Axios.get(
      `/api/rollingkiosk/buylog/all`,
      headerConfig
    );

    Axios.all([getProducts, getTodayAmount, getWeekAmount, getAllAmount])
      .then(
        Axios.spread((...response) => {
          let amounts = {
            today: 0,
            week: 0,
            all: 0,
          };

          amounts = {
            today: response[1].data.amount,
            week: response[2].data.amount,
            all: response[3].data.amount,
          };

          setAmounts(amounts);
          setList(response[0].data.data);
          setIsLoading(false);
        })
      )
      .catch((err) => {
        notify.show(err.response.data.message, "error");
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  /**
   * Toma un ítem y completa los datos en el modal para
   * poder ser editado.
   *
   * @param item Item a modificar
   */
  const editItem = (item: RKioskItem) => {
    setItemToEdit(item);
    setShow(!show);
  };

  /**
   * Reinicia el estado del modal de edición
   */
  const handleClose = () => {
    setItemToEdit({ stock: 0, image: "", name: "", barcode: 0, price: 0 });
    setShow(!show);
  };

  /**
   * Se encarga de la paginación, toma el resultado del componente
   * para mostrarlo en la UI
   *
   * @param pagedList Resultado de la paginación
   */
  const onChangePage = (pagedList: any) => {
    setPagedList(pagedList);
  };

  /**
   * Elimina el item seleccionado
   * @param itemId ID del item a borrar
   */
  const deleteItem = (itemId: string) => {
    if (
      window.confirm("Se va a eliminar el producto, seguro desea continuar?")
    ) {
      Axios.delete(`/api/rollingkiosk/products/delete/${itemId}`, headerConfig)
        .then((i) => {
          getData();
          notify.show(i.data.message, "success");
        })
        .catch((err) => notify.show(err.response.data.message, "error"));
    }
  };

  return (
    <Container>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <EditProduct
            getData={getData}
            editProduct={itemToEdit}
            show={show}
            headerConfig={headerConfig}
            handleClose={handleClose}
          />
          <Row>
            <Col className="bg-light shadow rounded mb-5 px-5 py-3">
              <Row>
                <Col>
                  <p className="lead">Lista de productos en RollingKiosk:</p>
                </Col>
                <Col md="auto">
                  <p className="border border-muted rounded p-1 text-right text-muted">
                    <i>
                      Hoy: <strong>${amounts.today}</strong> / Semana:{" "}
                      <strong>${amounts.week}</strong> / Total:{" "}
                      <strong>${amounts.all}</strong>
                    </i>
                  </p>
                </Col>
              </Row>
              <ListGroup>
                <ListGroup.Item active>
                  <Row>
                    <Col md="3">Nombre de producto:</Col>
                    <Col md="1" className="text-center">
                      Precio
                    </Col>
                    <Col md="1" className="text-center">
                      Stock
                    </Col>
                    <Col className="text-center">C. de barras</Col>
                    <Col className="text-center">Modificado ultima vez:</Col>
                    <Col md="2"></Col>
                  </Row>
                </ListGroup.Item>
                {pagedList.map((item, index) => (
                  <CustomCard
                    key={index}
                    data={item}
                    deleteItem={deleteItem}
                    editItem={() => editItem(item)}
                  />
                ))}
              </ListGroup>
            </Col>
          </Row>
          <Row>
            <Col className="p-3 mb-3 bg-light rounded shadow d-flex justify-content-center">
              <Pagin items={list} onChangePage={onChangePage} pageSize={5} />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}
