import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import Axios from "axios";
import { notify } from "react-notify-toast";
import { RKioskItem } from "../../../Types/RKioskItem";

export default function EditProduct({
  show,
  handleClose,
  editProduct,
  headerConfig,
  getData,
}: {
  show: boolean;
  handleClose: () => void;
  editProduct: RKioskItem;
  headerConfig: any;
  getData: () => void;
}) {
  const [form, setForm] = useState<RKioskItem>({
    _id: "",
    barcode: 0,
    image: "",
    name: "",
    price: 0,
    stock: 0,
  });

  const [isSaving, setIsSaving] = useState(false);

  const handleChange = (e: { target: { name: any; value: any } }) => {
    let { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    setForm({ ...editProduct });
  }, [editProduct]);

  const saveEdit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsSaving(!isSaving);
    Axios.put(`api/rollingkiosk/products/edit/${form._id}`, form, headerConfig)
      .then((i) => {
        setIsSaving(false);
        getData();
        notify.show(i.data.message, "success");
        handleClose();
      })
      .catch((err) => {
        setIsSaving(false);
        notify.show(err.response.data.message, "error");
      });
  };

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Form className="p-3" onSubmit={saveEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Editar producto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={handleChange}
                  value={form.name}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Código de barras</Form.Label>
                <Form.Control
                  type="text"
                  name="barcode"
                  onChange={handleChange}
                  value={form.barcode}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Stock</Form.Label>
                <Form.Control
                  type="text"
                  name="stock"
                  onChange={handleChange}
                  value={form.stock}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Precio</Form.Label>
                <Form.Control
                  type="number"
                  name="price"
                  onChange={handleChange}
                  value={form.price}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Imágen (url)</Form.Label>
                <Form.Control
                  type="text"
                  name="image"
                  onChange={handleChange}
                  value={form.image}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button disabled={isSaving} type="submit" variant="info">
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
