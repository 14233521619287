import React from 'react';
import { Col, Row, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPen,
  faFileInvoiceDollar,
  faCircleDollarToSlot,
  faTimesCircle,
  faRectangleList,
  faCertificate,
  faGraduationCap,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import ButtonPlayPause from './ButtonPlayPause';
import SkipAutoSuspend from './SkipAutoSuspend';

export default function Buttons({
  id,
  createdBy,
  modifiedBy,
  timeStamps,
  togglePaymentHistorial,
  toggleCampusInfo,
  toggleCertificateModal,
  statusCampusUSer,
  toggleConfirmDeleteModal,
  toggleConfirmCommissionModal,
  campusUserId,
  setcampusUserFromWS,
  pauseStudentButton,
  pauseClassActivity,
  changeClassActivity,
  skipAutoSuspend,
  skipAutoSuspendChange,
  skipAutoSuspendFlag,
}) {
  /* Displays the message below the buttons with the creator and the modifier if any are present */
  const showCreatedBy = (created, modified, createdAt, modifiedAt) => {
    return (
      <>
        {created ? (
          <p className='m-0 p-0'>
            Creado por: {created} el {moment(createdAt).format('L LTa')}
          </p>
        ) : null}
        {modified ? (
          <p className='m-0 p-0'>
            Modificado por: {modified} el {moment(modifiedAt).format('L LTa')}
          </p>
        ) : null}
        <div>
          <SkipAutoSuspend
            statusStudent={skipAutoSuspend}
            action={skipAutoSuspendChange}
            isLoading={skipAutoSuspendFlag}
          />
        </div>
        <div className='d-flex justify-content-center align-items-center'>
          <OverlayTrigger
            placement='right'
            overlay={
              <Tooltip className='ml-2' id={`tooltip-top`}>
                <small className='m-0 p-0'>Ver estudiante en el Campus</small>
              </Tooltip>
            }
          >
            <Button
              onClick={toggleCampusInfo}
              size='sm'
              variant={statusCampusUSer}
              className='ml-2 campus-button'
            >
              <FontAwesomeIcon icon={faGraduationCap} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement='right'
            overlay={
              <Tooltip className='ml-2' id={`tooltip-top`}>
                <small className='m-0 p-0'>Crear certificado</small>
              </Tooltip>
            }
          >
            <Button
              onClick={toggleCertificateModal}
              size='sm'
              variant='info'
              className='ml-2 campus-button'
            >
              <FontAwesomeIcon icon={faCertificate} />
            </Button>
          </OverlayTrigger>
          <ButtonPlayPause
            action={pauseStudentButton}
            statusStudent={pauseClassActivity}
            changeClassActivity={changeClassActivity}
          />
        </div>
      </>
    );
  };

  return (
    <Col md={7} className='bg-light shadow py-3 px-1 mb-5 rounded text-center'>
      <Row className='d-flex justify-content-center'>
        <Link to={`/students/viewAll`}>
          <Button size='sm' variant='info' className='ml-2'>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
        </Link>
        <Link to={`/students/edit/${id}`}>
          <Button size='sm' variant='info' className='ml-2'>
            <FontAwesomeIcon icon={faPen} /> Editar alumno
          </Button>
        </Link>

        <Link to={`/bills/create/${id}`}>
          <Button size='sm' variant='info' className='ml-2'>
            <FontAwesomeIcon icon={faFileInvoiceDollar} /> Generar comprobante
          </Button>
        </Link>

        <Button
          onClick={togglePaymentHistorial}
          size='sm'
          variant='info'
          className='ml-2'
        >
          <FontAwesomeIcon icon={faCircleDollarToSlot} /> Historial de pago
        </Button>

        <Button
          onClick={toggleConfirmDeleteModal}
          size='sm'
          variant='danger'
          className='ml-2'
        >
          <FontAwesomeIcon icon={faTimesCircle} /> Eliminar alumno
        </Button>
      </Row>
      <Row className='mt-2'>
        <Col>
          <small className='m-0 p-0 text-muted'>
            {showCreatedBy(
              createdBy,
              modifiedBy,
              timeStamps.createdAt,
              timeStamps.modifiedAt
            )}
          </small>
        </Col>
      </Row>
    </Col>
  );
}
