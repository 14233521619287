import React, { useEffect, useState } from 'react';
import { faTimesCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notify } from 'react-notify-toast';
import clientAxios from '../../../../clients/clientAxios';
import { userNormalizationForCampus } from '../../../../helpers/studentNormalization';

export default function CampusInfo({
  show,
  onHide,
  student,
  setStatusCampusUSer,
  setcampusUserFromWS,
}) {
  const STATUS_CAMPUS_USER = {
    found: 'info',
    notFound: 'danger',
    unxpectedError: 'warning',
  };

  /* Setting state */
  const [campusUser, setcampusUser] = useState({
    id: '',
  });
  const [isLoading, setIsLoading] = useState(false),
    getCampusUser = () => {
      clientAxios.get(`/campus/${student.email}`).then(
        ({ data }) => {
          if (data && data.length > 0) {
            setStatusCampusUSer(STATUS_CAMPUS_USER.found);
            let campusUser = data[0];
            campusUser.url = `${campusUser.fullUrl}/user/profile.php?id=${campusUser.id}`;
            let lastAccessFormatDate = new Date(campusUser.lastaccess * 1000);
            campusUser.lastAccess =
              lastAccessFormatDate.toLocaleDateString('es-AR');
            setcampusUser(campusUser);
            setcampusUserFromWS(campusUser);
          }
        },
        (err) => {
          if (err.response.status === 404) {
            setcampusUser({ userNotFound: true });
            setStatusCampusUSer(STATUS_CAMPUS_USER.notFound);
          } else {
            setStatusCampusUSer(STATUS_CAMPUS_USER.unxpectedError);
            if (err.response.data.message)
              notify.show(err.response.data.message, 'error');
            else notify.show(err.message, 'error');
          }
        }
      );
    };
  const createFullStackUser = () => {
    const campusUser = userNormalizationForCampus(student);
    setIsLoading(true);
    clientAxios.post(`/campus/user/`, campusUser).then(
      ({ data }) => {
        if (data) {
          getCampusUser();
          onHide();
          notify.show('usuario creado!', 'success');
        }
      },
      (err) => {
        setStatusCampusUSer(STATUS_CAMPUS_USER.unxpectedError);
        if (err.response.data.message)
          notify.show(err.response.data.message, 'error');
        else notify.show(err.message, 'error');
        onHide();
      }
    );
  };
  /* Getting data from database */
  useEffect(() => {
    if (student) getCampusUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, student]);

  return (
    <Modal
      scrollable
      size="xl"
      dialogClassName="modal-90w"
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Usuario Campus</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!campusUser.userNotFound ? (
          <div>
            <div>
              <a href={campusUser.url} target="_blank">
                Ir al perfil de usuario
              </a>
            </div>
            <p>Estundiante Suspendid@: {campusUser.suspended ? 'Si' : 'No'} </p>
            <p>Ultima conexión: {campusUser.lastAccess} </p>
          </div>
        ) : (
          <div>
            <p>No se encontro el usuario creado en el Campus :(</p>
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={createFullStackUser}
            >
              <FontAwesomeIcon icon={faPlusCircle} /> Crear Usuario y Agregar a
              Onboarding FullStack
            </Button>
            {isLoading && (
              <div className="mt-5">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>
          <FontAwesomeIcon icon={faTimesCircle} /> Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
