import React from "react";
import { useState } from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { screens } from "./Screens";
import SendNotification from "./SendNotification";
import WriteMessage from "./WriteMessage";

export default function Dashboard() {
  const [selectedScreen, setSelectedScreen] = useState(0);

  return (
    <Container fluid>
      <Row>
        <Col md="3">
          <ListGroup as="ul">
            {screens.map(
              (
                a: { disabled: boolean; icon?: string; name: string },
                i: number
              ) => (
                <ListGroup.Item
                  as="li"
                  disabled={a.disabled}
                  key={i}
                  onClick={() => {
                    setSelectedScreen(i);
                  }}
                  active={selectedScreen === i}
                >
                  <i className={`mr-1 ${a.icon}`} />
                  {a.name}
                </ListGroup.Item>
              )
            )}
          </ListGroup>
        </Col>
        <Col>
          <WriteMessage
            className={`${selectedScreen === 0 ? "d-block" : "d-none"}`}
          />
          <SendNotification
            className={`${selectedScreen === 1 ? "d-block" : "d-none"}`}
          />
        </Col>
      </Row>
    </Container>
  );
}
