import React, { useEffect, useState } from 'react';
import {
  Table,
  Row,
  Col,
  Button,
  InputGroup,
  Dropdown,
  FormControl,
  Pagination,
} from 'react-bootstrap';
import './paymentMp.css';
import { notify } from 'react-notify-toast';
import LoadingScreen from '../../Layout/LoadingScreen';
import clientAxios from '../../clients/clientAxios';
import moment from 'moment';
import ModalForRefund from './ModalForRefund';
import ModalDetail from './ModalDetail';

const PaymentsMp = () => {
  const [paymentsDataMp, setPaymentsDataMp] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dateFormatedForQuery = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
  const ALL_PAYMENTS = 'Todos los pagos';
  const INSTALLMENTS = 'Cuotas';
  const ENROLLMENTS = 'Inscripciones';
  const ON_DEMAND_COURSES = 'Cursos on demand';
  const ALL_STATES = 'Todos';
  const [paymentType, setPaymentType] = useState(ALL_PAYMENTS);
  const [paymentStatus, setPaymentStatus] = useState(ALL_STATES);
  const [selectedStatus, setSelectedStatus] = useState(ALL_STATES);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 20;
  const totalPagesCount = Math.ceil(paymentsDataMp.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = currentPage * ITEMS_PER_PAGE;
  const currentPayments = paymentsDataMp.slice(startIndex, endIndex);
  const [isModaRefundOpen, setIsModalRefundOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [dataForModal, setDataFormodal] = useState({
    email: '',
    numberOperation: '',
  });
  const [isModalDetailOpen, setIsModalDetailOpen] = useState(false);
  const [allDataOperation, setAllDataOperation] = useState(null);

  const formatDate = 'YYYY-MM-DD';
  const paymentStatusOptions = {
    Todos: ALL_STATES,
    Aprobado: 'approved',
    Pendiente: 'pending',
    Autorizado: 'authorized',
    'En proceso': 'in_process',
    'En mediación': 'in_mediation',
    Rechazado: 'rejected',
    Cancelado: 'cancelled',
    Reembolsado: 'refunded',
    'Devolución de cargo': 'charged_back',
  };
  const [initialDate, setInitialDate] = useState(
    moment().startOf('month').format(formatDate)
  );
  const [endDate, setEndDate] = useState(
    moment().startOf('day').format(formatDate)
  );
  const SEARCH_TYPE = {
    EMAIL: 'email',
    TRANSACTION_NUMBER: 'id',
  };
  const [searchValue, setSearchValue] = useState('');

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPaymentsMp = async (searchType, value) => {
    setIsLoading(true);
    try {
      const initialDateForQuery =
        moment(initialDate).format(dateFormatedForQuery);
      const endDateForQuery = moment(endDate)
        .endOf('day')
        .format(dateFormatedForQuery);
      const reference = {
        'Todos los pagos': 'ALLPAYMENTS',
        'Cuotas': 'INSTALLMENTS',
        Inscripciones: 'ENROLLMENTS',
        'Cursos on demand': 'ONDEMANDCOURSES',
      };

      const { data } = await clientAxios.get(
        `/bills/payments/mercadopago?external_reference=${reference[paymentType]}&begin_date=${initialDateForQuery}&end_date=${endDateForQuery}&limit=${ITEMS_PER_PAGE}`
      );
      let totalData = Array.isArray(data)
        ? data.flatMap((item) => item.body.results).sort((a, b)  => new Date(b.date_created) - new Date(a.date_created))
        : data?.body.results;

      if (paymentStatus !== ALL_STATES) {
        totalData = totalData
          .map((data) => data)
          .filter((payment) => payment.status === paymentStatus);
      }
      setPaymentsDataMp(totalData);

      if(searchType && value) {
        const searchData = totalData.filter((payment) => {
          if (searchType === SEARCH_TYPE.TRANSACTION_NUMBER) {
            return payment.id.toString() === value;
          } else if (searchType === SEARCH_TYPE.EMAIL) {
            return (
              payment.metadata?.email === value ||
              payment.payer?.email === value
            );
          }
          return false;
        });
        setSearchResults(searchData);
        setSearchValue('');
      }
    } catch {
      notify.show(
        'Ha ocurrido un error al intentar traer las subscripciónes.',
        'error'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentStatusChange = (selectedStatus) => {
    setSelectedStatus(selectedStatus);
    setPaymentStatus((prev) => {
      const statusInEnglish = paymentStatusOptions[selectedStatus];
      return statusInEnglish;
    });
  };

  const handleSearch = () => {
    setSearchResults([]);
    const isNumeric = /^\d+$/.test(searchValue);
    const isEmail = /\S+@\S+\.\S+/.test(searchValue);

    if (isNumeric) {
      getPaymentsMp(SEARCH_TYPE.TRANSACTION_NUMBER, searchValue);
    } else if (isEmail) {
      getPaymentsMp(SEARCH_TYPE.EMAIL, searchValue);
    } else if (!searchValue) {
      getPaymentsMp();
    }
  };

  useEffect(() => {
    getPaymentsMp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onModalRefundOpen = (email, numberOperation) => {
    setDataFormodal({ email, numberOperation });
    setIsModalRefundOpen(true);
  };

  const onModalDetailOpen = (data) => {
    const objectToJson = JSON.stringify(data, null, 2)
    setAllDataOperation(objectToJson);
    setIsModalDetailOpen(true);
  };

  return (
    <>
      <div className='h-100'>
        <Row className='d-flex justify-content-center mt-2'>
          <Col lg={2} xs={12}>
            <Dropdown onSelect={(e) => setPaymentType(e)}>
              <Dropdown.Toggle variant='dark' id='dropdown-select-payment'>
                {paymentType}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey={ALL_PAYMENTS}>
                  Todos los pagos
                </Dropdown.Item>
                <Dropdown.Item eventKey={INSTALLMENTS}>
                  Cuotas
                </Dropdown.Item>
                <Dropdown.Item eventKey={ENROLLMENTS}>
                  Inscripciones
                </Dropdown.Item>
                <Dropdown.Item eventKey={ON_DEMAND_COURSES}>
                  Cursos on demand
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col lg={3} xs={12}>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>Desde</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type='date'
                name='dateFrom'
                defaultValue={initialDate}
                max={moment().format(formatDate)}
                onChange={(e) =>
                  setInitialDate(
                    moment(e.target.value).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                  )
                }
                className='w-auto'
              />
            </InputGroup>
          </Col>
          <Col lg={3} xs={12}>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>Hasta</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type='date'
                name='dateTo'
                defaultValue={endDate}
                max={moment().format(formatDate)}
                onChange={(e) =>
                  setEndDate(
                    moment(e.target.value).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                  )
                }
                className='w-auto'
              />
            </InputGroup>
          </Col>
          <Col lg={2} xs={12}>
            <Dropdown onSelect={handlePaymentStatusChange}>
              <span>Estados </span>
              <Dropdown.Toggle variant='dark' id='dropdown-select-status'>
                {selectedStatus}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {Object.keys(paymentStatusOptions).map((status, index) => (
                  <Dropdown.Item key={index} eventKey={status} className='pt-0'>
                    {status}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col lg={4} xs={12}>
            <InputGroup className='mt-3'>
              <FormControl
                type='text'
                placeholder='Buscar por número o email'
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <InputGroup.Append className='mx-2'>
                <Button onClick={handleSearch} disabled={isLoading}>
                  Buscar
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <Row className='d-flex justify-content-center align-items-center flex-column my-5 pb-5 ml-2 mr-2'>
            <Row className='table-responsive'>
              <Col lg={12} xs={12}>
                <Table
                  striped
                  bordered
                  hover
                  variant='dark'
                  className='w-100 my-5 pt-5'
                >
                  <thead>
                    <tr>
                      <th className='text-center'>Fecha de pago</th>
                      <th className='text-center'>Numero de operación</th>
                      <th className='text-center'>Nombre y Apellido</th>
                      <th className='text-center'>Monto</th>
                      <th className='text-center'>Estado del Pago</th>
                      <th className='text-center'>Email</th>
                      <th className='text-center'>Comisión/Descripción</th>
                      <th className='text-center'>Cuota</th>
                      <th className='text-center'>Medio de pago</th>
                      <th className='text-center'>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(searchResults.length > 0 ? searchResults : currentPayments) &&
                     (searchResults.length > 0 ? searchResults : currentPayments).map((student, index) => (
                        <tr key={index}>
                          <td className='text-center'>
                            {moment(student?.date_created).format(
                              'DD/MM/YYYY HH:mm'
                            )}
                          </td>
                          <td className='text-center'>{student?.id}</td>
                          <td className='text-center'>
                            {`${
                              student?.metadata?.display_name ||
                              student?.metadata?.firstname ||
                              student?.metadata?.first_name
                            } ${
                              student?.metadata?.lastname ||
                              student?.metadata?.last_name || ''
                            }`}
                          </td>
                          <td className='text-center'>
                            $ {student?.transaction_details?.total_paid_amount}
                          </td>
                          <td>{student?.status}</td>
                          <td className='email-cell text-wrap'>
                            {student?.metadata?.email || student?.payer.email}
                          </td>
                          <td className='text-center'>
                            {student?.metadata?.class_room ||
                              student?.description}
                          </td>
                          <td className='text-center'>
                            {student?.metadata?.installment ||
                              student?.installments}
                          </td>
                          <td className='text-center'>
                            <div>
                              <p>{student?.payment_type_id}</p>
                              <p>{student?.payment_method_id}</p>
                              <p>{student?.card?.last_four_digits}</p>
                            </div>
                          </td>
                          <td className='text-center'>
                            {student?.status !== 'refunded' && student?.status === 'approved' && (
                              <Button
                                onClick={() =>
                                  onModalRefundOpen(
                                    student?.metadata?.email ||
                                      student?.payer.email,
                                    student?.id
                                  )
                                }
                              >
                                Reembolso
                              </Button>
                            ) }
                             <Button
                              style={{marginTop: '12px'}}
                              onClick={() =>
                                onModalDetailOpen(student)
                              }
                            >
                              Más detalle
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className='justify-content-center mt-3'>
              <Col>
                <Pagination className='justify-content-center'>
                  <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  {[...Array(totalPagesCount)].map((_, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === currentPage}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPagesCount}
                  />
                </Pagination>
              </Col>
            </Row>
          </Row>
        )}
      </div>
      <ModalForRefund
        isModaRefundOpen={isModaRefundOpen}
        setIsModalRefundOpen={setIsModalRefundOpen}
        dataForModal={dataForModal}
        getPaymentsMp={getPaymentsMp}
      />
      <ModalDetail
      isModalDetailOpen={isModalDetailOpen}
      setIsModalDetailOpen={setIsModalDetailOpen}
      allData={allDataOperation}
      />
    </>
  );
};

export default PaymentsMp;
