import React from 'react';
import { Col } from 'react-bootstrap';
import moment from 'moment';
import { notify } from 'react-notify-toast';

export default function StudentName({
  name = 'Error',
  statusStudent,
  dateOfTheLastPause,
}) {
  function calculateElapsedTime(oldDate) {
    try {
      const currentDate = moment();
      const oldDateMoment = moment(oldDate);
      const diffInMilliseconds = currentDate.diff(oldDateMoment);
      const duration = moment.duration(diffInMilliseconds);
      const monthOrMonths = oldDateMoment.month() === 0 ? 'mes' : 'mese';
      const unitOfTime = ['año', monthOrMonths, 'día', 'hora'];
      const valuesOfTime = [
        duration.years(),
        duration.months(),
        duration.days(),
        duration.hours(),
      ];

      let message = '';

      unitOfTime.forEach((unid, index) => {
        if (valuesOfTime[index] > 0) {
          message += `${valuesOfTime[index]} ${unid}${
            valuesOfTime[index] > 1 ? 's' : ''
          } `;
        }
      });

      return message.trim() === '' ? 'Recién ocurrió' : message;
    } catch (error) {
      notify.show(
        'Ha ocurrido un error al mostrar la fecha de pausado del estudiante.',
        'error'
      );
    }
  }

  return (
    <Col md={4} className='bg-light shadow p-3 mb-5 rounded'>
      <h1 className='lead'>Nombre del estudiante:</h1>
      <h2 className='font-weight-bold text-truncate ' style={{ fontSize: 32 }}>
        {name.length > 22 ? (
          <>
            <abbr title={name}>{name}</abbr>
            <h3 className='font-weight-bold text-danger'>
              {statusStudent && '(PAUSADO)'}
            </h3>
          </>
        ) : (
          <>
            <span>{name}</span>
            <h3 className='font-weight-bold text-danger'>
              {statusStudent && '(PAUSADO)'}
            </h3>
          </>
        )}
      </h2>
      {statusStudent && (
        <>
          <p className='font-weight-bold my-0'>{`El: ${moment(
            dateOfTheLastPause
          ).format('DD/MM/YYYY')}`}</p>
          <p className='font-weight-bold my-0'>{`Hace: ${calculateElapsedTime(
            dateOfTheLastPause
          )}`}</p>
        </>
      )}
    </Col>
  );
}
