export const screens = [
  {
    name: "Deudores",
    disabled: false,
    active: 1,
  },
  {
    name: "En progreso",
    disabled: false,
    active: 2,
  },
  {
    name: "Archivados",
    disabled: false,
    active: 3,
  },
];
