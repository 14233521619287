import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./styles/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";

// Context

import { UserProvider } from "./Context/UserContext";
import { ClassroomListProvider } from "./Context/ClassroomListContext";
import { StudentListProvider } from "./Context/StudentListContext";

ReactDOM.render(
  <BrowserRouter>
    <UserProvider>
      <StudentListProvider>
        <ClassroomListProvider>
          <App />
        </ClassroomListProvider>
      </StudentListProvider>
    </UserProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
