import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { notify } from "react-notify-toast";
import { Container, Row, Col, Button } from "react-bootstrap";
import { UserContext } from "../../../Context/UserContext";

export default function Generate2FA() {
  const user = useContext(UserContext);
  const history = useHistory();
  const [QRCode, setQRCode] = useState("");

  useEffect(() => {
    if (user.has2FA) {
      notify.show("Ya tenés 2FA andando!", "error");
      history.push("/");
    } else {
      Axios.get("/api/user/2fa/generate", {
        headers: { "auth-token": localStorage.getItem("JWT") || " "},
      })
        .then(({ data }) => {
          if (data.success) {
            setQRCode(data.data);
          } else {
            notify.show(
              "Ha ocurrido un error mostrando el QR, reintente",
              "error"
            );
          }
        })
        .catch(({ response }) => {
          notify.show(response.data.message, "error");
          history.push("/");
        });
    }
    //eslint-disable-next-line
  }, []);

  const checkForCode = () => {
    try {
      let local = localStorage.getItem("userdata") || "{}";

      let userDataStorage = JSON.parse(local);
      userDataStorage.has2FA = true;
      localStorage.setItem("userdata", JSON.stringify(userDataStorage));
      notify.show("2FA Agregado correctamente!", "success");
      history.push("/");
      window.location.reload();
    } catch (err) {
      localStorage.clear();
      notify.show("Hubo un inconveniente, volvé a loguearte", "error");
      history.push("/user/login");
    }
  };

  return (
    <Container>
      <Row className="d-flex align-items-center">
        <Col className="w-100 text-center bg-white rounded shadow p-3 mx-2 mb-5">
          <h1 className="display-4">Two-Factor Authenticator</h1>
          <p className="lead">
            Con tu celular y la app "Google Authenticator" vas a poder agregarle
            seguridad a tu cuenta.
          </p>
          <p>
            Abri la aplicación "Google Authenticator", escaneá el QR de la
            derecha, confirmá con el botón de abajo y ya estás usando 2FA!
          </p>
          <br />
          <p className="text-danger">
            ¡Este código QR se genera por única vez!
          </p>
          <br />
          <Button variant="success" onClick={checkForCode}>
            Ya escanié mi código
          </Button>
        </Col>
        <Col className="h-100 mx-2 text-center p-3 mb-5 bg-white shadow rounded">
          <img src={QRCode} height="400" alt="QR Code" />
        </Col>
      </Row>
    </Container>
  );
}
