import React, { useEffect, useState } from 'react';
import clientAxios from '../../clients/clientAxios';
import { Button, Form, Modal, Row, Col, Table, Spinner } from 'react-bootstrap';
import { notify } from 'react-notify-toast';

const PaymentPlanEditModal = ({ planData, setFetchTrigger }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [amountsPriceSchema, setAmountsPriceSchema] = useState(
    planData.priceSchema
  );
  const [editedValues, setEditedValues] = useState(
    new Array(amountsPriceSchema.length).fill('')
  );
  const [newInstallment, setNewInstallment] = useState('');
  const [editingIndex, setEditingIndex] = useState(-1);
  const [totalAmount, setTotalAmount] = useState(0);

  const normalizePriceSchema = (data) => {
    const amontQuotaFormated = data.map(Number);
    return amontQuotaFormated;
  };

  const savePriceSchemaChange = async (id, priceSchema, updateState) => {
    try {
      const formatedData = normalizePriceSchema(priceSchema);
      setIsLoading(true);
      const resp = await clientAxios.patch(`/planes/${id}`, {
        priceSchema: formatedData,
      });
      if (resp.status === 200) {
        setFetchTrigger((prevState) => !prevState);
        updateState();
        setNewInstallment('');
        notify.show('Plan modificado correctamente', 'success');
      }
    } catch {
      notify.show('No se pudo modificar el plan', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (index) => {
    const newEditedValues = [...editedValues];
    newEditedValues[index] = amountsPriceSchema[index];
    setEditedValues(newEditedValues);
    setEditingIndex(index);
  };

  useEffect (() => {
    const total = amountsPriceSchema.reduce((acc, curr) => acc + Number(curr), 0);
    setTotalAmount(total)
  },[amountsPriceSchema])

  const handleCancel = (index) => {
    const newEditedValues = [...editedValues];
    newEditedValues[index] = '';
    setEditedValues(newEditedValues);
    setEditingIndex(-1);
    console.log(amountsPriceSchema);
  };

  const handleSave = async (index) => {
    const newValue = Number(editedValues[index]);
    if (newValue < 0 || !newValue) {
      notify.show('No se permiten valores negativos o campo vacío', 'error');
      return;
    }
    const newAmounts = [...amountsPriceSchema];
    newAmounts[index] = editedValues[index];
    savePriceSchemaChange(planData._id, newAmounts, () => {
      handleCancel(index);
      setAmountsPriceSchema(newAmounts);
    });
  };

  const handleDelete = (index) => {
    const newAmounts = amountsPriceSchema.filter((_, i) => i !== index);
    setAmountsPriceSchema(newAmounts);
    savePriceSchemaChange(planData._id, newAmounts, () =>
      setAmountsPriceSchema(newAmounts)
    );
  };

  const handleAdd = async () => {
    if (newInstallment !== '') {
      const newAmounts = [...amountsPriceSchema, newInstallment];
      savePriceSchemaChange(planData._id, newAmounts, () => {
        setAmountsPriceSchema(newAmounts);
      });
    } else {
      notify.show('Debes ingresar un monto', 'error');
    }
  };

  const handleUpdateAmount = (e, index) => {
    const newEditedValues = [...editedValues];
    newEditedValues[index] = e.target.value;
    setEditedValues(newEditedValues);
  };

  useEffect(() => {
    setAmountsPriceSchema(planData.priceSchema);
  }, [planData])
  
  return (
    <>
      <button className='btn btn-primary' onClick={() => {setShowModal(true)}}>
        Ver
      </button>
      <Modal
        className='text-white'
        show={showModal}
        onHide={() => setShowModal(false)}
        key={planData._id}
        size='lg'
      >
        <Modal.Header closeButton className='bg-dark'>
          <Modal.Title>Plan: {planData.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-dark'>
          <div>
            {isLoading ? (
              <div
                className='d-flex justify-content-center align-items-center'
                style={{ height: '200px' }}
              >
                <Spinner animation='border' role='status'>
                  <span className='visually-hidden'></span>
                </Spinner>
              </div>
            ) : (
              <Table striped bordered hover variant='dark'>
                <thead>
                  <tr>
                    <th>Cuota</th>
                    <th>Monto</th>
                    <th>Acciónes</th>
                  </tr>
                </thead>
                <tbody>
                  {amountsPriceSchema.map((amount, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {editingIndex === index ? (
                          <input
                            type='number'
                            value={editedValues[index]}
                            onChange={(e) => handleUpdateAmount(e, index)}
                            min={0}
                          />
                        ) : (
                          amount
                        )}
                      </td>
                      <td>
                        <div className='d-flex justify-content-center'>
                          {editingIndex === index ? (
                            <div className='d-inline'>
                              <Button
                                variant='success'
                                onClick={() => handleSave(index)}
                              >
                                Guardar
                              </Button>
                              <Button
                                variant='secondary'
                                onClick={() => handleCancel(index)}
                                className='mx-2'
                              >
                                Cancelar
                              </Button>
                            </div>
                          ) : (
                            <Button
                              variant='info'
                              onClick={() => handleEdit(index)}
                              className='mx-2'
                            >
                              Editar
                            </Button>
                          )}

                          <Button
                            variant='danger'
                            onClick={() => handleDelete(index)}
                          >
                            Eliminar
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            <Row>
              <Col sm={6}>
                <Form>
                  <Form.Group>
                    <div className='d-flex text-success h3'>
                      <Form.Label>$ {totalAmount}</Form.Label>
                    </div>
                    <Form.Label>Añadir cuota</Form.Label>
                    <div className='d-inline-flex'>
                      <Form.Control
                        type='number'
                        value={newInstallment}
                        onChange={(e) => setNewInstallment(e.target.value)}
                      />
                      <Button
                        variant='primary'
                        onClick={handleAdd}
                        className='mx-2'
                      >
                        Añadir
                      </Button>
                    </div>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentPlanEditModal;
