import React from 'react';
import './buttonPlayPause.css';
import { Tooltip, OverlayTrigger, Spinner } from 'react-bootstrap';

const ButtonPlayPause = ({ action, statusStudent, changeClassActivity }) => {

  const buttonClass = [
    {
      containerButton: 'containerButton paused',
      buttonClass: 'buttonPlayPause paused',
    },
    {
      containerButton: 'containerButton',
      buttonClass: 'buttonPlayPause',
    },
  ];

  const handleChange = () => {
    action();
  };

  return (
    <OverlayTrigger
      placement='right'
      overlay={
        <Tooltip className='ml-2' id={`tooltip-top`}>
          <small className='m-0 p-0'>
            Pausar actividad de cursado, Este estado no afecta el pago de cuotas
          </small>
        </Tooltip>
      }
    >
      <div
        id='containerButton'
        class={
          !statusStudent
            ? buttonClass[0].containerButton
            : buttonClass[1].containerButton
        }
        onClick={() => handleChange()}
      >
        {changeClassActivity ? (
          <Spinner animation='border' size='sm' variant='light' />
        ) : (
          <div
            class={
              !statusStudent
                ? buttonClass[0].buttonClass
                : buttonClass[1].buttonClass
            }
          ></div>
        )}
      </div>
    </OverlayTrigger>
  );
};

export default ButtonPlayPause;
