// React related imports
import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import Searchbox from './Searchbox';

// Axios
import axios from 'axios';

// Bootstrap
import { ListGroup, Container, Row, Col } from 'react-bootstrap';

// Pagination
import Pagin from '../Pagin';

// Edit bills
import EditBill from './EditBill';

// Notification & modal systems
import { notify } from 'react-notify-toast';
import BSModal from '../../Layout/BSModal';
import LoadingScreen from '../../Layout/LoadingScreen';

import moment from 'moment';
import 'moment/locale/es';

const DEFAULT_LAST_DAYS = 7;

export default class ListBill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullBills: [],
      listedBills: [],
      isLoading: true,
      isDataLoading: false,

      // Modal management
      modalShow: false,
      modalTitle: '',
      modalBody: '',
      modalVariant: '',
      modalAction: console.log(''),

      showEditForm: false,
      billId: '',
    };
  }

  componentDidMount() {
    axios
      .post(
        '/api/bills/search',
        {
          dateFrom: moment()
            .subtract(DEFAULT_LAST_DAYS, 'days')
            .format('YYYY-MM-DD'),
        },
        {
          headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
        }
      )
      .then((res) => {
        this.setState({ fullBills: res.data, isLoading: false });
      })
      .catch((err) => {
        notify.show(err.message, 'error');
        this.props.history.push('/users/login');
      });
  }

  onChangePage = (listedBills) => {
    this.setState({ listedBills });
  };

  getBills = () => {
    axios
      .get('/api/bills/get', {
        headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
      })
      .then((res) => {
        this.setState({ fullBills: res.data, isLoading: false });
      })
      .catch((err) => {
        notify.show(err.message, 'error');
        this.props.history.push('/users/login');
      });
  };

  deleteBill = (id) => {
    axios
      .delete(`/api/bills/delete/${id}`, {
        headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
      })
      .then(() => {
        this.modalHandleClose();
        this.getBills();
        notify.show('Boleta eliminada', 'success');
      })
      .catch((err) => {
        notify.show('Ha ocurrido un error', 'error');
      });
  };

  // Modal manager

  modalHandleClose = () => {
    this.setState((i) => ({ modalShow: !i.modalShow }));
  };

  modalShowAndPopulateData = (
    modalTitle,
    modalBody,
    modalVariant,
    modalAction
  ) => {
    this.setState({
      modalShow: true,
      modalTitle,
      modalBody,
      modalVariant,
      modalAction,
    });
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingScreen />;
    }
    return (
      <Container>
        {/* Modal */}

        <BSModal
          show={this.state.modalShow}
          handleClose={this.modalHandleClose}
          title={this.state.modalTitle}
          body={this.state.modalBody}
          variant={this.state.modalVariant}
          action={this.state.modalAction}
        />

        <EditBill
          show={this.state.showEditForm}
          onHide={() =>
            this.setState((i) => ({ showEditForm: !i.showEditForm }))
          }
          id={this.state.billId}
          refresh={this.getBills}
        />

        <Searchbox
          defaultLastDays={DEFAULT_LAST_DAYS}
          refresh={this.getBills}
          setResults={(e) => this.setState({ fullBills: e })}
          setIsDataLoading={(e) => this.setState({ isDataLoading: e })}
        />

        {/* Listado de bills */}
        {this.state.isDataLoading ? (
          <LoadingScreen />
        ) : (
          <>
            <h5>Total Pagos: {this.state.fullBills.length}</h5>
            <Row className='mb-4'>
              <Col>
                <ListGroup>
                  {this.state.listedBills.map((bill) => {
                    return (
                      <ListGroup.Item
                        key={bill.billNumber}
                        variant={`${bill.partial ? 'primary' : 'light'}`}
                      >
                        <Row>
                          <Col md='1'>
                            <p className='m-0 text-dark'>{`${bill.billNumber}`}</p>
                          </Col>

                          <Col>
                            <Row>
                              <Col md='3' className='px-0'>
                                <p className='m-0 text-muted'>
                                  Fecha:{' '}
                                  <strong className='text-dark'>
                                    {moment(bill.date).utc().format('L')}
                                  </strong>
                                </p>
                              </Col>
                              <Col md='3' className='px-0'>
                                <p
                                  style={{ width: '%100' }}
                                  className='m-0 text-muted  text-truncate'
                                >
                                  Comision:{' '}
                                  <abbr title={bill.classroom}>
                                    <strong className='text-dark text-truncate'>
                                      {bill.classroom}
                                    </strong>
                                  </abbr>
                                </p>
                              </Col>
                              <Col md='2' className='px-0'>
                                <p className='m-0 text-muted'>
                                  Cuota:{' '}
                                  <strong className='text-dark'>
                                    {bill.monthlyInstallment}
                                  </strong>
                                </p>
                              </Col>
                              <Col md='4' className='px-0'>
                                <p className='m-0 text-muted text-truncate'>
                                  Metodo de pago:{' '}
                                  <strong className='text-dark'>
                                    {bill.payment}
                                  </strong>
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col className='px-0' md={3}>
                                <p className='m-0 text-muted'>
                                  Fecha de creación:{' '}
                                  <strong className='text-dark'>
                                    {moment(bill.createdAt)
                                      .endOf('day')
                                      .format('L')}
                                  </strong>
                                </p>
                              </Col>
                              <Col className='px-0' md={3}>
                                <p
                                  style={{ width: '%100' }}
                                  className='m-0 text-muted  text-truncate'
                                >
                                  Alumno:{' '}
                                  <abbr title={bill.nameDisplay}>
                                    <strong className='text-dark text-truncate'>
                                      {bill.nameDisplay}
                                    </strong>
                                  </abbr>
                                </p>
                              </Col>
                              <Col className='px-0' md={3}>
                                <p
                                  style={{ width: '%100' }}
                                  className='m-0 text-muted  text-truncate'
                                >
                                  Email:{' '}
                                  <abbr title={bill.email}>
                                    <strong classNsame='text-dark text-truncate'>
                                      {bill.email}
                                    </strong>
                                  </abbr>
                                </p>
                              </Col>
                              <Col className='px-0' md={2}>
                                <p className='m-0 text-muted'>
                                  Monto:{' '}
                                  <strong className='text-dark'>
                                    ${bill.amount}
                                  </strong>
                                </p>
                              </Col>
                              <Col className='px-0'>
                                <p className='m-0 text-muted'>
                                  Usuario:{' '}
                                  <strong className='text-dark'>
                                    {typeof bill.createdBy === 'object'
                                      ? bill.createdBy.name
                                      : bill.createdBy}
                                  </strong>
                                </p>
                              </Col>
                            </Row>
                          </Col>

                          <Col md='1' className='p-0 pr-2 text-center'>
                            {/* Print icon */}
                            <Link to={`/bills/print/${bill._id}`}>
                              <i className='fas fa-print' />
                            </Link>

                            {/* Edit icon */}
                            <i
                              className='ml-2 text-dark far fa-edit'
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                this.setState((a) => ({
                                  showEditForm: !a.showEditForm,
                                  billId: bill._id,
                                }))
                              }
                            />

                            {/* Delete icon */}
                            <i
                              onClick={() =>
                                this.modalShowAndPopulateData(
                                  'Eliminar boleta',
                                  `Si elimina la boleta, tendrá que reflejar los cambios manualmente en la caja chica. Está seguro de continuar eliminando la boleta?`,
                                  'danger',
                                  () => this.deleteBill(bill._id)
                                )
                              }
                              className='ml-2 text-danger far fa-trash-alt'
                              style={{ cursor: 'pointer' }}
                            />
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col className='p-3 mb-3 bg-light rounded shadow d-flex justify-content-center'>
            <Pagin
              items={this.state.fullBills}
              onChangePage={this.onChangePage}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
