import React, { useState } from 'react';
import { Form, Modal, Button, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
// import { notify } from "react-notify-toast";
// import Axios from "axios";

export default function CitiesExcel({
  show,
  onHide,
  dataRaw,
  countriesArray,
  regionsArray,
}) {
  // const [since, setSince] = useState(
  //   moment().startOf("year").format("YYYY-MM-DD")
  // );
  // const [to, setTo] = useState(moment().endOf("day").format("YYYY-MM-DD"));
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [dataExcel, setDataExcel] = useState([]);
  const [filterExcelAplly, setFilterExcelAplly] = useState('all');
  const [regionSelected, setRegionSelected] = useState('');
  const [countrySelected, setCountrySelected] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingExcel(true);
    filterExcel();
    setRegionSelected('');
    setCountrySelected('');
  };

  let preDataExcel = [];

  const filterExcel = () => {
    preDataExcel = [];
    if (filterExcelAplly === 'all') {
      for (var country in dataRaw.studentsOfSpecificCountry) {
        for (var region in dataRaw.studentsOfSpecificCountry[country]) {
          // dataRaw.studentsOfSpecificCountry[country][region].map((stu) => {
          //   preDataExcel.push({
          //     Nombre: stu.name + stu.surname,
          //     País: stu.country,
          //     Localidad: stu.region
          //   })
          // })
          if (country === 'Indefinido') {
            let classroom = '';
            let actives = '';
            let stu = dataRaw.studentsOfSpecificCountry[country][region];
            if (typeof stu.classroom === 'object') {
              stu?.classroom?.forEach((i) => {
                classroom += ' - ' + i + ' - ';
              });
            } else if (typeof stu.classroom === 'string') {
              classroom = stu.classroom;
            }
            if (typeof stu.classroom === 'object') {
              stu.isActive.forEach((i) => {
                actives += ' - ' + i + ' - ';
              });
            } else if (typeof stu.isActive === 'string') {
              actives = stu.isActive;
            }
            preDataExcel.push({
              Nombre: stu.name + ' ' + stu.surname,
              País: stu.country === undefined ? 'País indefinido' : stu.country,
              Localidad:
                stu.region === undefined ? 'Localidad indefinida' : stu.region,
              Comisiones:
                classroom === undefined ? 'Comisiones indefinidas' : classroom,
              ['Fecha de actualización']:
                stu.updatedAt === undefined
                  ? 'Fecha indefinida'
                  : moment(stu.updatedAt).format('YYYY/MM/DD'),
              Estado: actives === undefined ? 'Estado indefinido' : actives,
            });
          } else templateExcelMethod(country, region);
        }
      }
    } else if (filterExcelAplly === 'defined') {
      for (let country in dataRaw.studentsOfSpecificCountry) {
        for (let region in dataRaw.studentsOfSpecificCountry[country]) {
          if (country !== 'Indefinido') templateExcelMethod(country, region);
        }
      }
    } else if (filterExcelAplly === 'country') {
      if (!regionSelected) {
        for (let country in dataRaw.studentsOfSpecificCountry) {
          for (let region in dataRaw.studentsOfSpecificCountry[country])
            if (country !== 'Indefinido') templateExcelMethod(country, region);
        }
      } else {
        for (let country in dataRaw.studentsOfSpecificCountry) {
          if (country === countrySelected) {
            for (let region in dataRaw.studentsOfSpecificCountry[country]) {
              if (country !== 'Indefinido')
                templateExcelMethod(country, region);
            }
          }
        }
      }
    } else if (filterExcelAplly === 'region') {
      if (!regionSelected) {
        for (let country in dataRaw.studentsOfSpecificCountry) {
          for (let region in dataRaw.studentsOfSpecificCountry[country])
            if (country === 'Argentina') templateExcelMethod(country, region);
        }
      } else {
        for (let country in dataRaw.studentsOfSpecificCountry) {
          for (let region in dataRaw.studentsOfSpecificCountry[country])
            if (region === regionSelected && country !== 'Indefinido')
              templateExcelMethod(country, region);
        }
      }
    }
    setDataExcel(preDataExcel);
    setLoadingExcel(false);
    createExcel(preDataExcel);
  };

  const templateExcelMethod = (country, region) => {
    dataRaw.studentsOfSpecificCountry[country][region].forEach((stu) => {
      let classroom = '';
      let actives = '';
      if (Array.isArray(stu?.classroom)) {
        stu.classroom.forEach((i) => {
          classroom += ' - ' + i + ' - ';
        });
      }
      if (Array.isArray(stu?.isActive)) {
        stu.isActive.forEach((i) => {
          actives += ' - ' + i + ' - ';
        });
      }
      preDataExcel.push({
        Nombre: stu.name + ' ' + stu.surname,
        País: stu.country === undefined ? 'País indefinido' : stu.country,
        Localidad:
          stu.region === undefined ? 'Localidad indefinida' : stu.region,
        Comisiones: classroom,
        ['Fecha de actualización']: moment(stu.updatedAt).format('YYYY/MM/DD'),
        Estado: actives,
      });
    });
  };

  const createExcel = (dataExcelProps) => {
    if (dataRaw !== [] && dataRaw.length !== 0) {
      if (loadingExcel === false) {
        const fileType =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(dataExcelProps);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, {
          bookType: 'xlsx',
          type: 'array',
        });
        const output = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(
          output,
          `Estudiantes por localidad - ${fileExtension}`
        );
        onHide();
      }
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Descargar Excel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Seleccione el filtro con el que desea descargar{' '}
            <i className="text-muted">
              (País y Localidad hacen aparecer un select)
            </i>
          </p>
          <Form.Group
            as={Row}
            onChange={(e) => setFilterExcelAplly(e.target.value)}
          >
            <Col sm={12}>
              <Form.Check
                inline
                type="radio"
                label="Todos"
                name="formHorizontalRadios"
                value="all"
                checked={filterExcelAplly === 'all'}
                onChange={(e) => setFilterExcelAplly(e.target.value)}
              />
              <Form.Check
                inline
                type="radio"
                label="Definidos"
                name="formHorizontalRadios"
                value="defined"
                checked={filterExcelAplly === 'defined'}
                onChange={(e) => setFilterExcelAplly(e.target.value)}
              />
              <Form.Check
                inline
                type="radio"
                label="País"
                name="formHorizontalRadios"
                value="country"
                checked={filterExcelAplly === 'country'}
                onChange={(e) => setFilterExcelAplly(e.target.value)}
              />
              <Form.Check
                inline
                type="radio"
                label="Provincias"
                name="formHorizontalRadios"
                value="region"
                checked={filterExcelAplly === 'region'}
                onChange={(e) => setFilterExcelAplly(e.target.value)}
              />
            </Col>
          </Form.Group>
          {filterExcelAplly === 'country' ? (
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Label>País</Form.Label>
              <Form.Control
                as="select"
                custom
                onChange={(e) => {
                  setCountrySelected(e.target.value);
                  setRegionSelected('');
                }}
              >
                <option>Seleccione un país...</option>
                {countriesArray.map((country) => (
                  <option value={country}>{country}</option>
                ))}
              </Form.Control>
            </Form.Group>
          ) : (
            <></>
          )}
          {filterExcelAplly === 'region' ? (
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Label>Provincia</Form.Label>
              <Form.Control
                as="select"
                custom
                onChange={(e) => {
                  setRegionSelected(e.target.value);
                  setCountrySelected('');
                }}
              >
                <option>Seleccione una provincia...</option>
                {regionsArray.map((region) => (
                  <option value={region}>{region}</option>
                ))}
              </Form.Control>
            </Form.Group>
          ) : (
            <></>
          )}
          {/* <Form.Group controlId="formBasicEmail">
            <Form.Label>Desde:</Form.Label>
            <Form.Control
              type="date"
              name="since"
              onChange={(e) => setSince(e.target.value)}
              value={since}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Hasta:</Form.Label>
            <Form.Control
              type="date"
              name="to"
              onChange={(e) => setTo(e.target.value)}
              value={to}
            />
          </Form.Group> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cerrar
          </Button>
          <Button variant="primary" type="submit">
            Descargar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
