import React, { useState, useEffect } from "react";
import { Row, Col, Container, Button, InputGroup, Form, Spinner } from "react-bootstrap";
import Axios from "axios";
import { notify } from "react-notify-toast";
import { useHistory } from "react-router-dom";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import moment from "moment";

// Importando pantallas internas
import LoadingScreen from "../../Layout/LoadingScreen";
import ExcelDownload from "./ExcelDownload";

export default function StudentsCounter() {
  const history = useHistory(),
  [isLoading, setIsLoading] = useState(true),
  [isLoadingGraph, setIsLoadingGraph] = useState(true),
  [isLoadingYears, setIsLoadingYears] = useState(true),
  [data, setData] = useState([]),
  [show, setShow] = useState(false),
  [month, setMonth] = useState(""),
  [year, setYear] = useState(moment().format("YYYY")),
  [years, setYears] = useState([]),
  [since, setSince] = useState(moment().startOf("year").format("YYYY-MM-DD")),
  [to, setTo] = useState(moment().endOf("year").format("YYYY-MM-DD")),
  [display, setDisplay] = useState("anual");

  let counter = 0;
  useEffect(() => {
    if (counter === 0) {
      fetchData();
      ++counter;
      quantityOfYearsF();
    }
    // eslint-disable-next-line
  }, []);

  let dataGlobal = [];
  let months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Sept.",
    "Oct.",
    "Nov.",
    "Dic.",
  ];

  let fetchData = () => {
    Axios.post(`/api/students/studentChangeTracking`, {
      year,
      month,
      display,
      since,
      to
    }, {
      headers: { "auth-token": localStorage.getItem("JWT") || " "},
    })
      .then(({ data }) => {

        if (display === "anual") {
          for (let i = 0; i < 12; i++) {
            let counter = {
              up: 0,
              down: 0,
              commision: 0,
            },
            values = [],
            studentsChanges = [],
            classroom = data.data[i].filter((o) => o.type === "classroom"),
            active = data.data[i].filter((o) => o.type === "isActive");
            values.push(classroom, active);

            for (let indA = 0; indA < values[1].length; indA++) {
              if (Array.isArray(values[1][indA])) {
                values[1][indA].forEach((i) => {
                  if (i.to === "down") {
                    counter.down++;
                  } else if (i.to === "up") {
                    counter.up++;
                  } else if (i.to === "commissionChanged") {
                    counter.commision++;
                    studentsChanges.push(i);
                  }
                });
              } else {
                if (values[1][indA].to === "down") counter.down++;
                else if (values[1][indA].to === "up") counter.up++;
                else if (values[1][indA].to === "commissionChanged") {
                  counter.commision++;
                  studentsChanges.push(values[1][indA]);
                }
              }
            }

            let dataInside = {
              name: months[i],
              Activos: counter.up,
              Bajas: counter.down,
              Cambios: counter.commision,
            };
            dataGlobal.push(dataInside);
          }
        } else if (display === "specific") {
          for (let i = 0; i < 1; i++) {
            let counter = {
              up: 0,
              down: 0,
              commision: 0,
            },
            values = [],
            studentsChanges = [],
            classroom = data.data.filter((o) => o.type === "classroom"),
            active = data.data.filter((o) => o.type === "isActive");

            values.push(classroom, active);

            for (let indA = 0; indA < values[1].length; indA++) {
              if (Array.isArray(values[1][indA])) {
                values[1][indA].forEach((i) => {
                  if (i.to === "down") counter.down++;
                  else if (i.to === "up") counter.up++;
                  else if (i.to === "commissionChanged") {
                    counter.commision++;
                    studentsChanges.push(i);
                  }
                });
              } else {
                if (values[1][indA].to === "down") {
                  counter.down++;
                } else if (values[1][indA].to === "up") {
                  counter.up++;
                } else if (values[1][indA].to === "commissionChanged") {
                  counter.commision++;
                  studentsChanges.push(values[1][indA]);
                }
              }
            }

            let dataInside = {
              name: `${since} - ${to}`,
              Activos: counter.up,
              Bajas: counter.down,
              Cambios: counter.commision,
            };
            dataGlobal.push(dataInside);
          }
        } else if (display === "sixMonths") {
          let months = [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Sept.",
            "Oct.",
            "Nov.",
            "Dic.",
          ];
          for (let i = 0; i < 6; i++) {

            let counter = {
              up: 0,
              down: 0,
              commision: 0,
            };
            let values = [];
            let studentsChanges = [];
            var classroom = data.data[i].result.filter((o) => o.type === "classroom");
            var active = data.data[i].result.filter((o) => o.type === "isActive");

            values.push(classroom, active, data.data);

            for (let indA = 0; indA < values[1].length; indA++) {
              if (Array.isArray(values[1][indA])) {
                values[1][indA].forEach((i) => {
                  if (i.to === "down") counter.down++;
                  else if (i.to === "up") counter.up++;
                  else if (i.to === "commissionChanged") {
                    counter.commision++;
                    studentsChanges.push(i);
                  }
                });
              } else {
                if (values[1][indA].to === "down") {
                  counter.down++;
                } else if (values[1][indA].to === "up") {
                  counter.up++;
                } else if (values[1][indA].to === "commissionChanged") {
                  counter.commision++;
                  studentsChanges.push(values[1][indA]);
                }
              }
            }

            let dataInside = {
              name: months[data.data[i].month],
              Activos: counter.up,
              Bajas: counter.down,
              Cambios: counter.commision,
            };

            dataGlobal.push(dataInside);
          }
        }

        if (dataGlobal.length !== 0) {
          setData(dataGlobal);
          setIsLoading(false);
          setIsLoadingGraph(false)
        } else {
          setIsLoadingGraph(true)
          setIsLoading(true);
        }

      })
      .catch((err) => {
        notify.show(err.message, "error");
        history.push("/");
      });
  };

  const quantityOfYearsF = () => {
    var currentYear = parseInt(moment().format("YYYY"));
    var quantityOfYears = currentYear - 2020;
    let quantityOfYearsSelect = []
    for (let q = 0; q <= quantityOfYears; q++) {
      quantityOfYearsSelect.push(`${q < 10 ? "202" + q : "20" + (q + 20)}`)
    }
    setYears(quantityOfYearsSelect)
    setIsLoadingYears(false)
  }

  /**
  * Al cambiarse el form, trae los datos correspondientes
  */
  useEffect(() => {
    setIsLoadingGraph(true);
    fetchData();
    //eslint-disable-next-line
  }, [year, month, display, since, to]);

  /**
 * Limpia el form de busqueda
 */
  const clearSearch = () => {
    setSince(moment().startOf("year").format("YYYY-MM-DD"));
    setTo(moment().endOf("year").format("YYYY-MM-DD"));
    setYear(moment().format("YYYY"));
    setDisplay("anual");
  };


  if (isLoading) {
    return <LoadingScreen />;
  } else if (isLoading === false) {
    return (
      <Container className="mb-5 pl-0 pr-0">
        <Container className="bg-light rounded shadow p-3 pl-0 mb-5">
          <Form>
            <Row className="mb-2">
              <Col>
                <Form.Group className="my-auto">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Año</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      onChange={(e) => {
                        setYear(e.target.value);
                      }}
                      value={year}
                      name="year"
                      as="select"
                      className="w-75"
                      disabled={isLoadingYears || display === 'specific' || display === 'sixMonths'}
                    >
                      {/* <option value="2020">2020</option> */}
                      {isLoadingYears ? (<option>Cargando...</option>) : (
                        years.map((year) => <option value={year} key={year}>{year}</option>)
                      )
                      }
                    </Form.Control>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="my-auto">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Diseño</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      onChange={(e) => {
                        setDisplay(e.target.value);
                      }}
                      value={display}
                      name="display"
                      as="select"
                      className="w-75"
                    >
                      <option value="anual">Anual</option>
                      <option value="sixMonths">6 meses</option>
                      <option value="specific">Específico</option>
                    </Form.Control>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                <Button className="mr-1 w-100" variant="success" onClick={() => setShow(true)}>
                  <i className="fas fa-dot-circle mr-2" />
                  Descargar excel
                </Button>
              </Col>
            </Row>
            <Row >
              <Col>
                <Form.Group className="my-auto">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Desde:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="date"
                      value={since}
                      onChange={(e) => {
                        setSince(moment(e.target.value).format("YYYY-MM-DD"));
                      }}
                      name="Since"
                      className="w-100"
                      disabled={display === 'anual' || display === 'sixMonths'}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="my-auto">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Hasta:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="date"
                      value={to}
                      onChange={(e) => {
                        setTo(moment(e.target.value).format("YYYY-MM-DD"));
                      }}
                      name="To"
                      className="w-100"
                      disabled={display === 'anual' || display === 'sixMonths'}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="my-auto">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Mes</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      onChange={(e) => {
                        setMonth(e.target.value);
                      }}
                      value={month}
                      name="month"
                      as="select"
                      className="w-75"
                      disabled={display === 'anual' || display === 'sixMonths'}
                    >
                      <option value="">Todos</option>
                      {months.map((monthMap) =>
                        <option value={monthMap} key={monthMap}>{monthMap}</option>
                      )}
                    </Form.Control>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                <Button className="w-100" onClick={() => clearSearch()}>Limpiar búsqueda</Button>
              </Col>
            </Row>

            <ExcelDownload show={show} onHide={() => setShow(false)} />
          </Form>
        </Container>
        { isLoadingGraph ?
          <Row className="container d-flex bg-light rounded shadow p-3 align-items-center ml-0 mr-0">
            <Col className="d-flex justify-content-center align-items-center">
              <Spinner
                animation="border"
                role="status"
                size="md"
                className="ml-3 mr-3 my-auto"
                variant="info"
              />
              <p className="m-0 p-0">Cargando...</p>
            </Col>
          </Row>
          :
          <Row className="container d-flex bg-light rounded shadow p-3 align-items-center ml-0 mr-0">
            <Col>
              <BarChart
                width={1000}
                height={500}
                data={data}
                style={{ overflow: "visible" }}
                className="mx-auto"
                barSize={50}
              >
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend className="mt-2" />
                <Bar dataKey="Activos" fill="#78cb7d" />
                <Bar dataKey="Bajas" fill="#E50000" />
                <Bar dataKey="Cambios" fill="#0000FF" />
              </BarChart>
            </Col>
          </Row>
        }
      </Container>
    );
  } else {
    return <div />;
  }
}
