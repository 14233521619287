import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";

export default function CopyMails({ show, onHide, commission, students }) {
  const [emailContent, setEmailContent] = useState("");
  const [radioFilter, setRadioFilter] = useState(3);

  useEffect(() => {
    if (students) {
      if (students.length > 0) {
        let emailDump = [];

        if (radioFilter === 1) {
          const filteredStudents = students.filter((singleStudent) => {
            return typeof singleStudent.isActive === "string"
              ? singleStudent.isActive === "up"
              : singleStudent.isActive[
                  singleStudent.classroom.indexOf(commission)
                ] === "up";
          });

          for (let student of filteredStudents) {
            emailDump.push(student.email);
          }

          setEmailContent(emailDump);
        } else if (radioFilter === 2) {
          const filteredStudents = students.filter((singleStudent) => {
            return typeof singleStudent.isActive === "string"
              ? singleStudent.isActive === "down"
              : singleStudent.isActive[
                  singleStudent.classroom.indexOf(commission)
                ] === "down";
          });

          for (let student of filteredStudents) {
            emailDump.push(student.email);
          }

          setEmailContent(emailDump);
        } else {
          for (let student of students) {
            emailDump.push(student.email);
          }

          setEmailContent(emailDump);
        }
      }
    }
    //eslint-disable-next-line
  }, [commission, radioFilter]);

  const copyText = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(emailContent);
    onHide();
  };

  return (
    <Modal centered size="xl" show={show} onHide={onHide}>
      <Form onSubmit={(e) => copyText(e)}>
        <Modal.Body>
          <Form.Check
            custom
            inline
            checked={radioFilter === 1}
            type="radio"
            label="Activos"
            name="formHorizontalRadios"
            id="formHorizontalRadios1"
            onClick={() => setRadioFilter(1)}
          />
          <Form.Check
            custom
            inline
            checked={radioFilter === 2}
            type="radio"
            label="Bajas"
            name="formHorizontalRadios"
            id="formHorizontalRadios2"
            onClick={() => setRadioFilter(2)}
          />
          <Form.Check
            custom
            inline
            checked={radioFilter === 3}
            type="radio"
            label="Todos"
            name="formHorizontalRadios"
            id="formHorizontalRadios3"
            onClick={() => setRadioFilter(3)}
          />
          <Form.Row className="pt-3">
            <Form.Label>Correos de la comisión disponibles:</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              name="comissionName"
              value={emailContent}
              rows="8"
            />
          </Form.Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cerrar
          </Button>
          <Button disabled={onHide} variant="info" type="submit">
            Copiar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
