import React, { useEffect, useState } from 'react';
import clientAxios from '../../../clients/clientAxios';
import { Button, Dropdown, Table } from 'react-bootstrap';
import { notify } from 'react-notify-toast';
import LoadingScreen from '../../../Layout/LoadingScreen';
import moment from 'moment';
import 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { TIMEZONE } from '../../../helpers/argenitianTimeZone';
import { userNormalizationForCampus } from '../../../helpers/studentNormalization';

const Subscriptions = () => {
  const [studentsData, setStudentsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionType, setSubscriptionType] = useState('Subscripciones Banco / Efectivo');
  const history = useHistory();

  const optionsDate = [
    { value: 2, label: '2 días' },
    { value: 3, label: '3 días' },
    { value: 7, label: '1 semana' },
    { value: 14, label: '2 semanas' },
  ];
  const [endDate, setEndDate] = useState(
    optionsDate.find((option) => option.value === 2)
  );

  const handleSelectSubscriptionType = (e) => {
    setSubscriptionType(e);
  };

  const handleSelectEndDate = (e) => {
    const selectedOption = optionsDate.find(
      (option) => option.value === parseInt(e)
    );
    setEndDate(selectedOption);
  };

  const calculateDate = (value) => {
    const currentDate = moment();
    const currentCalculated = currentDate.subtract(value.value, 'days');
    return currentCalculated.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
  };

  const getSubscriptions = async () => {
    let subs;
    try {
      setIsLoading(true);
      const calculatedDate = calculateDate(endDate);
      const queryParams = new URLSearchParams({
        endDate: calculatedDate
      });
      if (subscriptionType === 'Subscripciones Banco / Efectivo') {
        queryParams.append('mode', 'precheck');
        subs = await clientAxios.get(`/students/subscriptions?${queryParams}`);
      }
      else{
        subs = await clientAxios.get(`/students/subscriptions?${queryParams}`);
      }
      setStudentsData(subs?.data);
      setIsLoading(false);
    } catch {
      notify.show(
        'Ha ocurrido un error al intentar traer las subscripciónes.',
        'error'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const createFullStackUser = (student) => {
    student.firstname = student.name;
    const campusUser = userNormalizationForCampus(student);
    try {
      clientAxios.post(`/campus/user/`, campusUser)
    } catch (error) {
      notify.show('Ha ocurrido un error al intentar crear el usuario.', 'error');
    }
  };

  const handleSubscription = async (payload) => {
    setIsLoading(true);
    payload.subscriptionPending = false;
    payload.subscriptionModified = true;
    createFullStackUser(payload);
    try {
      await clientAxios.post('/students/', payload);
      history.push(`/students/details/${payload._id}`)
    } catch (error) {
      notify.show(
        'Ha ocurrido un error al intentar inscribir al estudiante.',
        'error'
      );
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
      getSubscriptions();
  }, [subscriptionType, endDate]);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="min-vh-100">
          <div className="ml-3 d-flex">
            <Dropdown onSelect={handleSelectSubscriptionType}>
              <Dropdown.Toggle variant="dark" id="dropdown-select-subscription">
                {subscriptionType}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="Subscripciones Banco / Efectivo">
                  Subscripciones Banco / Efectivo
                </Dropdown.Item>
                <Dropdown.Item eventKey="Subscripcíones">
                  Subscripciónes
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="ml-3" onSelect={handleSelectEndDate} >
              <Dropdown.Toggle
                variant="dark"
                id="dropdown-elect-days"
              >
                {endDate.label}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {optionsDate.map((option) => (
                  <Dropdown.Item
                    key={option.value}
                    eventKey={option.value.toString()}
                  >
                    {option.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="d-flex justify-content-center my-5 table-responsive">
            <Table striped bordered hover variant="dark" className="w-75">
              <thead>
                <tr>
                  <th className="text-center">Fecha</th>
                  <th className="text-center">Estado</th>
                  <th className="text-center">Nombre y Apellido</th>
                  <th className="text-center">Comisión</th>
                  <th className="text-center">Email</th>
                  <th className="text-center">Telefono</th>
                  {subscriptionType === 'Subscripciones Banco / Efectivo' && (
                    <th className="text-center">Acciones</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {studentsData &&
                  studentsData.map((student, index) => (
                    <tr key={index}>
                      <td className='text-center'>{moment(student?.updatedAt).tz(TIMEZONE).format('DD/MM/YYYY HH:MM')}</td>
                      <td className="text-center">
                        {student?.subscriptionPending ? 'Pendiente' : 'Ok'}
                      </td>
                      <td className="text-center">
                        {student?.name} {student?.surname}
                      </td>
                      <td className="text-center">{student?.classroom}</td>
                      <td className="text-center">{student?.email}</td>
                      <td className="text-center">{student?.phone}</td>
                      {subscriptionType === 'Subscripciones Banco / Efectivo' && (
                        <td className="text-center">
                          <Button
                            variant="primary"
                            onClick={() => handleSubscription(student)}
                          >
                            Inscribir
                          </Button>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </>
  );
};

export default Subscriptions;
