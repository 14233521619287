import React, { useState, useEffect } from 'react';
import { notify } from 'react-notify-toast';
import { useHistory } from 'react-router-dom';
import {
  Spinner,
  Row,
  Col,
  Container,
  Button,
  InputGroup,
  Form,
} from 'react-bootstrap';
import Axios from 'axios';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from 'recharts';
import moment from 'moment';
import {
  calculateAveragePerMonth,
  calculateDollarSalesPerMonth,
  convertToArray,
  convertToDollars,
} from '../../clients/helpers/conversionToDollars';

// Importando pantallas internas
import LoadingScreen from '../../Layout/LoadingScreen';

export default function Incomes() {
  const history = useHistory();
  let momentStartYear = moment().startOf('year').format('YYYY-MM-DD');
  let momentEndYear = moment().endOf('year').format('YYYY-MM-DD');

  const [since, setSince] = useState(momentStartYear);
  const [to, setTo] = useState(momentEndYear);
  const [payment, setPayment] = useState('');
  const [year, setYear] = useState(moment().format('YYYY'));
  const [years, setYears] = useState([]);
  const [display, setDisplay] = useState('anual');
  const [scolarship, setScolarship] = useState(false);
  const [graphicData, setGraphicData] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const [isLoading, setIsLoading] = useState({ fetch: true, form: true });
  const [isLoadingYears, setIsLoadingYears] = useState(true);
  const [ingresos, setIngresos] = useState({ yearly: [], monthly: 0 });
  const [isDollar, setisDollar] = useState(false);
  const [prevData, setPrevData] = useState(null);
  const [formatCurrency, setformatCurrency] = useState(
    new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
    })
  );

  let ingresosMensuales = [];
  let ingresosSixMonths = [];
  let ingresoMensual = 0;
  let counter = 0;

  /**
   * Arranque inicial para los valores por defecto
   */
  useEffect(() => {
    if (counter === 0) {
      fetchData();
      ++counter;
      quantityOfYearsF();
    }
    //eslint-disable-next-line
  }, []);

  /**
   * Al cambiarse el form, trae los datos correspondientes
   */
  useEffect(() => {
    setIsLoading({ ...isLoading, form: true });
    fetchData();
    //eslint-disable-next-line
  }, [since, to, payment, year, display, scolarship]);

  /**
   * Al terminar de recibir los valores del fetch, pasa a procesarlos en yearDates()
   */
  useEffect(() => {
    if (
      ingresos.yearly.length !== 0 ||
      ingresos.monthly.length !== 0 ||
      ingresos.sixm.length !== 0
    ) {
      yearDates();
    }
    //eslint-disable-next-line
  }, [ingresos]);

  /**
   * Realiza un fetch a la ruta Bills y trae los datos para gráfico
   */
  const fetchData = () => {
    Axios.post(
      `/api/bills/graphic`,
      {
        since,
        to,
        payment,
        year,
        display,
      },
      { headers: { 'auth-token': localStorage.getItem('JWT') } }
    )
      .then(({ data }) => {
        ingresosMensuales = [];
        ingresosSixMonths = [];
        ingresoMensual = 0;
        data.data.reduce((acc, element) => acc + element.length, 0) === 0
          ? setFetchedData([])
          : setFetchedData(data.data);
        if (data.data.length !== 0) {
          if (data.year && display === 'anual') {
            for (let i = 0; i < 12; i++) {
              if (data.data[i].length !== 0) {
                ingresosMensuales.push(data.data[i][0].IngresosTotales);
              } else {
                ingresosMensuales.push([]);
              }
            }
          } else if (display === 'specific') {
            ingresoMensual = data.data[0].IngresosTotales;
            setisDollar(false);
          } else if (display === 'sixMonths') {
            if (data.data.length !== 0) {
              setisDollar(false);
              ingresosSixMonths = data.data;
            }
          }
        }

        setIsLoading({ ...isLoading, fetch: false });
        setIngresos({
          monthly: ingresoMensual,
          yearly: ingresosMensuales,
          sixm: ingresosSixMonths,
        });
      })
      .catch((err) => {
        notify.show(err.response?.data?.message, 'error');
        history.push('/');
      });
  };

  /**
   * Limpia el form de busqueda
   */
  const clearSearch = () => {
    setSince(momentStartYear);
    setTo(momentEndYear);
    setPayment('');
    setYear(moment().format('YYYY'));
    setDisplay('anual');
    setisDollar(false);
  };

  /**
   * Setea los valores del fetch para ser leídos correctamente por Graphic
   */
  const yearDates = () => {
    let dataLet = [];
    if (display === 'anual') {
      let months = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Sept.',
        'Oct.',
        'Nov.',
        'Dic.',
      ];

      for (let i = 0; i < 12; i++) {
        let plusAmounthForScolarship = 0;
        if (
          year === '2022' &&
          scolarship &&
          window.location.href.includes('admin.rollingcodeschool.com') &&
          (months[i] === 'Nov.' || months[i] === 'Dic.')
        ) {
          plusAmounthForScolarship = 8675458;
        }
        if (
          year === '2023' &&
          scolarship &&
          window.location.href.includes('admin.rollingcodeschool.com') &&
          months[i] === 'Junio'
        ) {
          plusAmounthForScolarship = 2090196 + 1110800;
        }

        if (
          year === '2023' &&
          scolarship &&
          window.location.href.includes('admin.rollingcodeschool.com') &&
          months[i] === 'Oct.'
        ) {
          plusAmounthForScolarship = 19342915;
        }
        let dataInside = {
          name: months[i],
          Total: ingresos.yearly[i] + plusAmounthForScolarship,
        };

        dataLet.push(dataInside);
      }
      if (payment !== '') {
        let abc = graphicData.filter((i) => i.Total.length !== 0);

        if (abc.length === 0) {
          dataLet = [];
        }
      }
    } else if (display === 'specific') {
      let dataInside = {
        name: `${since} - ${to}`,
        Total: ingresos.monthly,
      };
      dataLet.push(dataInside);
    } else if (display === 'sixMonths') {
      let months = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Sept.',
        'Oct.',
        'Nov.',
        'Dic.',
      ];
      for (let i = 0; i < 6; i++) {
        let dataInside = {
          name: months[ingresos.sixm[i].month],
          Total: ingresos.sixm[i].result.IngresosTotales,
        };

        dataLet.push(dataInside);
      }
    }
    setGraphicData(dataLet);
    setIsLoading({ ...isLoading, form: false });
  };

  const getDollarPrice = async () => {
    setIsLoading({ ...isLoading, form: true });
    try {
      if (display === 'sixMonths') {
        const sixMonthsAgo = moment().subtract(6, 'months');
        momentStartYear = sixMonthsAgo.startOf('month').format('DD-MM-YYYY');
        momentEndYear = moment().format('DD-MM-YYYY');
      } else {
        momentStartYear = moment(`${year}-01-01`)
          .startOf('year')
          .format('DD-MM-YYYY');
        momentEndYear = moment(`${year}-12-31`)
          .endOf('year')
          .format('DD-MM-YYYY');
      }
      const { data } = await Axios(
        `https://mercados.ambito.com//dolar/informal/historico-general/${momentStartYear}/${momentEndYear}`
      );
      const totalDollarsPerMonth = calculateDollarSalesPerMonth(data);
      const average = calculateAveragePerMonth(totalDollarsPerMonth);
      const equalArray = convertToArray(average);
      const copyData = [...graphicData];
      setPrevData(copyData);
      const resultConversion = convertToDollars(equalArray, copyData);
      if (display === 'sixMonths') {
        const sortedGraphicData = copyData.map((item) => {
          const convertedItem = resultConversion.find(
            (data) => data.name === item.name
          );
          return convertedItem ? convertedItem : item;
        });
        setGraphicData(sortedGraphicData);
      } else {
        setGraphicData(resultConversion);
      }
    } catch {
      notify.show(
        'No se pudo acceder a los datos para realizar la conversión, intente de nuevo más tarde',
        'error'
      );
    } finally {
      setIsLoading({ ...isLoading, form: false });
    }
  };

  useEffect(() => {
    if (isDollar) {
      getDollarPrice();
      setformatCurrency(
        new Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'USD',
        })
      );
    } else {
      setGraphicData(prevData);
      setformatCurrency(
        new Intl.NumberFormat('es-AR', {
          style: 'currency',
          currency: 'ARS',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDollar]);

  /**
   * Se encarga de armar el select de años
   */
  const quantityOfYearsF = () => {
    var currentYear = parseInt(moment().format('YYYY'));
    var quantityOfYears = currentYear - 2020;
    let quantityOfYearsSelect = [];
    for (let q = 0; q <= quantityOfYears; q++) {
      quantityOfYearsSelect.push(`${q < 10 ? '202' + q : '20' + (q + 20)}`);
    }
    setYears(quantityOfYearsSelect);
    setIsLoadingYears(false);
    setYear(moment().format('YYYY'));
  };

  if (isLoading.fetch) {
    return <LoadingScreen />;
  } else if (isLoading.fetch === false) {
    return (
      <Container className='mb-5 pl-0 pr-0'>
        <Container className='bg-light rounded shadow p-3 pl-0 mb-5'>
          <Form>
            <Row className='mb-2'>
              <Col>
                <Form.Group className='my-auto'>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Año</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      onChange={(e) => {
                        setYear(e.target.value);
                        setisDollar(false);
                      }}
                      value={year}
                      name='year'
                      as='select'
                      className='w-75'
                      disabled={
                        isLoadingYears ||
                        display === 'specific' ||
                        display === 'sixMonths'
                      }
                    >
                      {/* <option value="2020">2020</option> */}
                      {isLoadingYears ? (
                        <option>Cargando...</option>
                      ) : (
                        years.map((year) => (
                          <option value={year} key={year}>
                            {year}
                          </option>
                        ))
                      )}
                    </Form.Control>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col className='col col-lg-4'>
                <Form.Group className='my-auto'>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Diseño</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      onChange={(e) => {
                        setDisplay(e.target.value);
                      }}
                      value={display}
                      name='display'
                      as='select'
                      className='w-75'
                    >
                      <option value='anual'>Anual</option>
                      <option value='sixMonths'>6 meses</option>
                      <option value='specific'>Específico</option>
                    </Form.Control>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col style={{ width: '40px' }}>
                <Form.Group className='my-auto'>
                  <InputGroup style={{ width: '70%' }}>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Becas:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type='checkbox'
                      value={scolarship}
                      checked={scolarship}
                      onChange={(e) => {
                        setScolarship(!scolarship);
                        setisDollar(false);
                      }}
                      name='scolarship'
                      className='w-75'
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='my-auto'>
                  <InputGroup style={{ width: '100%' }}>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Dólar histórico:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type='checkbox'
                      checked={isDollar}
                      disabled={display === 'specific'}
                      onChange={(e) => setisDollar(e.target.checked)}
                      name='dollarHistorical'
                      className='w-75'
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className='my-auto'>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Desde:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type='date'
                      value={since}
                      onChange={(e) => {
                        setSince(moment(e.target.value).format('YYYY-MM-DD'));
                      }}
                      name='Since'
                      className='w-100'
                      disabled={display === 'anual' || display === 'sixMonths'}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='my-auto'>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Hasta:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type='date'
                      value={to}
                      onChange={(e) => {
                        setTo(moment(e.target.value).format('YYYY-MM-DD'));
                      }}
                      name='To'
                      className='w-100'
                      disabled={display === 'anual' || display === 'sixMonths'}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='my-auto'>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Método de pago:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      onChange={(e) => {
                        setPayment(e.target.value);
                        setisDollar(false);
                      }}
                      value={payment}
                      name='Payment'
                      as='select'
                      className='w-75'
                    >
                      <option value=''>Todos</option>
                      <option value='Efectivo'>Efectivo</option>
                      <option value='Banco'>Banco</option>
                      <option value='Tarjetas'>Tarjetas</option>
                      <option value='MercadoPago'>MercadoPago</option>
                      <option value='Payway'>Payway</option>
                      <option value='Otro'>Otro</option>
                    </Form.Control>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                <Button onClick={() => clearSearch()}>Limpiar búsqueda</Button>
              </Col>
            </Row>
          </Form>
        </Container>
        {isLoading.form ? (
          <Row className='container d-flex bg-light rounded shadow p-3 align-items-center ml-0 mr-0'>
            <Col className='d-flex justify-content-center align-items-center'>
              <Spinner
                animation='border'
                role='status'
                size='md'
                className='ml-3 mr-3 my-auto'
                variant='info'
              />
              <p className='m-0 p-0'>Cargando...</p>
            </Col>
          </Row>
        ) : fetchedData.length !== 0 ? (
          <Row className='container d-flex bg-light rounded shadow p-3 align-items-center ml-0 mr-0'>
            <Col style={{ width: '100vw' }}>
              <BarChart
                width={1000}
                height={500}
                data={graphicData}
                style={{ overflow: 'visible' }}
                className='mx-auto'
                barSize={50}
              >
                <CartesianGrid strokeDasharray='1 1' />
                <XAxis dataKey='name' />
                <YAxis width={80} />
                <Tooltip
                  formatter={(value, name, props) => {
                    const averageValue = props.payload?.averageMonth || '';
                    return [
                      <>
                        <div>{`Total: ${formatCurrency.format(value)}`}</div>
                        {isDollar && (
                          <div>
                            {
                              <span style={{ fontSize: '12px' }}>
                                Cambio Promedio: ${averageValue} ARS
                              </span>
                            }
                          </div>
                        )}
                      </>,
                    ];
                  }}
                />
                <Legend />
                <Bar dataKey='Total' fill='#78aa7d' />
              </BarChart>
            </Col>
          </Row>
        ) : (
          <Row className='container d-flex bg-light rounded shadow p-3 align-items-center ml-0 mr-0'>
            <Col className='d-flex justify-content-center text-danger'>
              No existen pagos ingresados para este rango de fechas o este
              método de pago
            </Col>
          </Row>
        )}
      </Container>
    );
  } else {
    return <div />;
  }
}
