import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const QuillEditor = ({ onChange, value }) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["code-block"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "indent",
    "link",
    "code-block",
    "clean",
  ];
  return (
    <ReactQuill
      onChange={onChange}
      value={value}
      modules={modules}
      formats={formats}
      theme="snow"
      className="bg-white"
      placeholder="Descripción de la comisión..."
      as="text"
    />
  );
};
export default QuillEditor;
