import Axios from "axios";
import React from "react";
import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { notify } from "react-notify-toast";
import { ToastNotificationType } from "../../Types/ToastNotifications";

export default function SendNotification({ className }: { className: string }) {
  const [state, setState] = useState<Partial<ToastNotificationType>>({
    title: "",
    body: "",
    role: [],
  });

  const handleChange = (e: any) => {
    let { name, value } = e.target;

    setState({ ...state, [name]: value });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (state.role?.length === 0) {
      notify.show(
        "Se necesita indicar un rol para enviar la notificación",
        "error"
      );
    } else {
      Axios.post(`/api/notifications/`, state, {
        headers: { "auth-token": localStorage.getItem("JWT") || " "},
      })
        .then(({ data }) => {
          if (data.success) {
            notify.show("Notificación enviada", "success");
            setState({ ...state, title: "", body: "", role: [] });
          }
        })
        .catch((err) => {
          notify.show(`Error: ${err.response.data.message}`, "error");
        });
    }
  };

  return (
    <div className={`${className} bg-light rounded shadow p-3`}>
      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>Título</Form.Label>
          <Form.Control
            required
            type="text"
            onChange={handleChange}
            name="title"
            value={state.title}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Mensaje</Form.Label>
          <Form.Control
            required
            as="textarea"
            rows={3}
            onChange={handleChange}
            name="body"
            value={state.body}
          />
        </Form.Group>

        <Form.Group>
          <Form.Check
            inline
            type="checkbox"
            label="Mentores"
            onClick={() => {
              let { role } = state;

              if (role?.includes(3)) {
                role.splice(role.indexOf(3));
                setState({ ...state, role });
              } else {
                role?.push(3);
                setState({ ...state, role });
              }
            }}
            checked={state.role?.includes(3)}
          />
          <Form.Check
            inline
            type="checkbox"
            label="Comunicaciones"
            onClick={() => {
              let { role } = state;

              if (role?.includes(2)) {
                role.splice(role.indexOf(2));
                setState({ ...state, role });
              } else {
                role?.push(2);
                setState({ ...state, role });
              }
            }}
            checked={state.role?.includes(2)}
          />
          <Form.Check
            inline
            type="checkbox"
            label="Administradores"
            onClick={() => {
              let { role } = state;

              if (role?.includes(1)) {
                role.splice(role.indexOf(1));
                setState({ ...state, role });
              } else {
                role?.push(1);
                setState({ ...state, role });
              }
            }}
            checked={state.role?.includes(1)}
          />
        </Form.Group>
        <Button variant="success" type="submit">
          <i className="fas fa-paper-plane" /> Enviar
        </Button>
      </Form>
    </div>
  );
}

interface messageType {
  mentorList: { _id: string; name: string }[];
  title: string;
  body: string;
  assignatedMentor: string;
  viewed: string[];
  showMentorSelector: boolean;
}
