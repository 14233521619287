import React, { useContext } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";
import { ALLOWED_PATHS_STATISTICS, USER_LEVEL_STATISTICS, USER_LEVEL_MENTOR_STATISTICS } from "../../constants";

const PrivateRoute = ({ auth, render: Render, path, ...rest }: any) => {
  const { isLoggedIn, has2FA, hasVerified2FA, level } = useContext(UserContext);

  const history = useHistory();

  return (
    <Route
      {...rest}
      render={
        (props) => {
          if (
            (isLoggedIn && has2FA && hasVerified2FA) ||
            (isLoggedIn && !has2FA)
          ) {
            if (level === USER_LEVEL_STATISTICS || level === USER_LEVEL_MENTOR_STATISTICS) {
              if (ALLOWED_PATHS_STATISTICS.filter((i: any) => i === path).length !== 0) {
                return <Render {...props} />;
              } else {
                return <Redirect to={ALLOWED_PATHS_STATISTICS[0]} />;
              }
            } else {
              return <Render {...props} />;
            }
          } else if (!isLoggedIn) {
            if (history.location.pathname === "/users/login" || history.location.pathname === "/users/2fa/verify") {
              return <Redirect to="/" />;
            }
          } else if (isLoggedIn && has2FA && !hasVerified2FA) {
            return <Redirect to="/users/2fa/verify" />;
          }
        }
        //   if (isLoggedIn && localStorage.getItem("JWT")) {
        //   } else {
      }
    />
  );
};

export default PrivateRoute;
