const incomes = [
  "Rollingkiosc",
  "Merchandising",
  "Curso Full-stack",
  "Cursos MKT",
  "Otros",
  "RollingWork",
  "Seña",
  "Aporte capital socios",
  "Caja intermedia",
  "Ingreso curso completo",
  "Ajustes",
  "Cuota",
];

const outcome = [
  "Ajustes",
  "Otros",
  "Retiro socios",
  "Mercadería kiosco",
  "Gastos limpieza site",
  "Servicios",
  "Impuestos",
  "Honorarios Admin",
  "Honorarios mentores",
  "Otros honorarios",
  "Mantenimiento site",
  "Librería e impresora",
  "Cumpleaños y eventos site",
  "Insumos de café",
  "Publicidad",
  "Materiales y equipamientos",
  "Retiro caja intermedia",
];

module.exports.incomes = incomes;
module.exports.outcome = outcome;
