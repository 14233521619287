import React, { useEffect, useState } from 'react';
import {
  faTimesCircle,
  faPrint,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import { Modal, ListGroup, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditBillStudent from '../../../payments/EditBillStudent';
import { notify } from 'react-notify-toast';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Axios from 'axios';
import ConfirmBillDelete from './ConfirmBillDelate';

export default function PaymentHistorial({ show, onHide, studentId }) {
  /* Setting state */
  const [paymentList, setPaymentList] = useState([]),
    [selectedBill, setSelectedBill] = useState(null),
    getBills = () => {
      Axios.get(`/api/bills/get/studentid/${studentId}`, {
        headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
      })
        .then(({ data }) => setPaymentList(data))
        .catch((err) => {
          if (err.response.data.message)
            notify.show(err.response.data.message, 'error');
          else notify.show(err.message, 'error');
        });
    };
 
  /* Getting data from database */
  useEffect(() => {
    if (studentId) getBills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, studentId]);

  return (
    <Modal
      scrollable
      size="xl"
      dialogClassName="modal-90w"
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Historial de pagos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {paymentList.length === 0 ? (
          <>No hay pagos registrados</>
        ) : (
          <ListGroup>
            {paymentList.map((currentBill) => (
              <ListGroup.Item
                key={currentBill._id}
                variant={currentBill.partial ? 'primary' : 'light'}
              >
                <Row>
                  <Col md={1}>
                    <p className="m-0 text-dark">{`${currentBill.billNumber}`}</p>
                  </Col>
                  <Col>
                    <Row>
                      <Col md={3} className="px-0">
                        <p className="m-0 text-muted">
                          Fecha:
                          <strong className="text-dark">
                            {moment(currentBill.date).utc().format('L')}
                          </strong>
                        </p>
                      </Col>
                      <Col md={3} className="px-0">
                        <p
                          style={{ width: '%100' }}
                          className="m-0 text-muted  text-truncate"
                        >
                          Comision:
                          <abbr title={currentBill.classroom}>
                            <strong className="text-dark text-truncate">
                              {currentBill.classroom}
                            </strong>
                          </abbr>
                        </p>
                      </Col>
                      <Col md={6} className="px-0 mxwidht">
                        <p className="m-0 text-muted">
                          Cuota:
                          <strong className="text-dark">
                            {currentBill.monthlyInstallment}
                          </strong>
                        </p>
                      </Col>
                      <Col className="px-0" md={5}>
                        <p className="m-0 text-muted">
                          Fecha de creación:
                          <strong className="text-dark">
                            {moment(currentBill.createdAt).utc().format('L')}
                          </strong>
                        </p>
                      </Col>
                      <Col md={4} className="px-0">
                        <p className="m-0 text-muted text-truncate">
                          Metodo de pago:
                          <strong className="text-dark">
                            {currentBill.payment}
                          </strong>
                        </p>
                      </Col>
                      <Col className="px-0" md={3}>
                        <p className="m-0 text-muted">
                          Monto:
                          <strong className="text-dark">
                            ${currentBill.amount}
                          </strong>
                        </p>
                      </Col>
                      <Col
                        md={12}
                        className="px-0 d-flex align-items-center justify-content-between"
                      >
                        <p className="m-0 text-muted">
                          Usuario:
                          <strong className="text-dark">
                            {typeof currentBill.createdBy === 'object'
                              ? currentBill.createdBy.name
                              : currentBill.createdBy}
                          </strong>
                        </p>
                      </Col>
                    </Row>
                  </Col>

                  <Col md="auto" className="p-0 px-2 text-md-center">
                    <Link to={`/bills/print/${currentBill._id}`}>
                      <FontAwesomeIcon
                        icon={faPrint}
                        className="text-primary px-2"
                      />
                    </Link>
                    <FontAwesomeIcon
                      icon={faPen}
                      className="text-primary px-2"
                      onClick={() =>
                        setSelectedBill((prev) =>
                          prev !== currentBill._id ? currentBill._id : null
                        )
                      }
                    />
                    <ConfirmBillDelete
                      className="px-2"
                      billId={currentBill?._id}
                      getBills={getBills}
                    />
                  </Col>
                </Row>
                <EditBillStudent
                  studentId={studentId}
                  show={selectedBill === currentBill._id}
                  onHide={() => setSelectedBill(null)}
                  id={currentBill._id}
                  bill={currentBill}
                  refresh={getBills}
                />
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>
          <FontAwesomeIcon icon={faTimesCircle} /> Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
