import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Axios from "axios";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export default class SBDwnload extends Component {
  componentDidMount() {
    Axios.get(
      `api/smallbox/getDay/${this.props.match.params.init}/${this.props.match.params.end}`,
      { headers: { "auth-token": localStorage.getItem("JWT") } }
    ).then(({ data }) => {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(data.data);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const output = new Blob([excelBuffer], { type: fileType });

      FileSaver.saveAs(
        output,
        "Cierre de caja - " +
        moment(this.props.match.params.init).format("L") +
        fileExtension
      );
    });
  }

  render() {
    return (
      <Container>
        <Row>
          <Col />
          <Col md={8}>
            <h1 className="display-4 text-center">
              Se está descargando tu boleta.
            </h1>
          </Col>
          <Col />
        </Row>
      </Container>
    );
  }
}
