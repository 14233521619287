import React from "react";
import { NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function CommunicationsNav() {
  return (
    <>
      <NavDropdown title='Alumnos' id='students'>
        <NavDropdown.Item>
          <Link
            className='text-dark text-decoration-none'
            to='/students/viewAll'
          >
            <i className='mr-3 fas fa-users' />
            Ver todos los alumnos
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link
            className='text-dark text-decoration-none'
            to='/students/create'
          >
            <i className='mr-3 fas fa-user-plus' />
            Agregar un alumno nuevo
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link
            className="text-dark text-decoration-none"
            to="/students/subscriptions"
          >
            <i className="mr-3 fas fa-user-plus" />
            Subscripciones
          </Link>
        </NavDropdown.Item>
      </NavDropdown>
      <NavDropdown title='Pagos' id='payments'>
        <NavDropdown.Item>
          <Link className='text-dark text-decoration-none' to='/bills/viewAll'>
            <i className='mr-3 fas fa-file-invoice' />
            Ver todos los pagos
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link
            className='text-dark text-decoration-none'
            to='/bills/payment-plan'
          >
            <i className='mr-3 fas fa-file-invoice' />
            Planes
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link className="text-dark text-decoration-none" to="/bills/paymentsMp">
            <i className="mr-3 fas fa-file-invoice" />
            Pagos en MercadoPago
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link className="text-dark text-decoration-none" to="/bills/createLinkPayment">
            <i className="mr-3 fas fa-file-invoice" />
            Links de MercadoPago
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link className="text-dark text-decoration-none" to="/bills/coupons">
            <i className="mr-3 fas fa-solid fa-gift" />
            Cupones
          </Link>
        </NavDropdown.Item>
      </NavDropdown>
      <NavDropdown title='Comisiones' id='payments'>
        <NavDropdown.Item>
          <Link className='text-dark text-decoration-none' to='/commission/'>
            <i className='mr-3 fas fa-laptop' />
            Comisiones
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link className='text-dark text-decoration-none' to='/teachers'>
            <i className='mr-3 fas fa-chalkboard-teacher' />
            Mentores
          </Link>
        </NavDropdown.Item>
      </NavDropdown>
      <Link className='nav-link' to='/smallbox/'>
        Caja chica
      </Link>
      <NavDropdown title='Cobranzas' id='payments'>
        <NavDropdown.Item>
          <Link className='text-dark text-decoration-none' to='/tracking/'>
            <i className='mr-3 fas fa-laptop' />
            Dashboard
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link
            className='text-dark text-decoration-none'
            to='/debt-collection-traking'
          >
            <i className='mr-3 fas fa-chalkboard-teacher' />
            Listado 2.0
          </Link>
        </NavDropdown.Item>
      </NavDropdown>
      <Link className='nav-link' to='/communications/dashboard'>
        Comunicaciones
      </Link>
      <NavDropdown.Divider />
      <NavDropdown title='RollingKiosk' id='students'>
        <NavDropdown.Item>
          <Link
            className='text-dark text-decoration-none'
            to='/rollingKiosk/addProduct'
          >
            <i className='mr-2 fas fa-plus' />
            Agregar productos
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link
            className='text-dark text-decoration-none'
            to='/rollingKiosk/viewProducts'
          >
            <i className='mr-2 fas fa-eye' />
            Controlar stock
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link
            className='text-dark text-decoration-none'
            to='/rollingKiosk/registerPhone'
          >
            <i className='mr-2 fas fa-user-plus' />
            Registrar alumno
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link
            className='text-dark text-decoration-none'
            to='/rollingKiosk/ChargeRcCoins'
          >
            <i className='mr-2 fas fa-dollar-sign' />
            Cargar rCoins
          </Link>
        </NavDropdown.Item>
      </NavDropdown>
    </>
  );
}
