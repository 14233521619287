import React, { useState, useEffect } from 'react';
import { notify } from 'react-notify-toast';
import { useHistory } from 'react-router-dom';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from 'recharts';
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from 'react-bootstrap';
import LoadingScreen from '../../Layout/LoadingScreen';
import clientAxios from '../../clients/clientAxios';

const ClassroomStatistics = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingGraph, setIsLoadingGraph] = useState(true);
  const [data, setData] = useState([]);
  const IS_ACTIVE='up';
  const LASTADDED_CLASSROOMS=10;
  const [display, setDisplay] = useState({ isActive: IS_ACTIVE, lastAdded: LASTADDED_CLASSROOMS });

  const fetchData = async () => {
    try {
      const { data } = await clientAxios(`/graph/studentsInClassroomCount`, {
        params: { isActive: display.isActive, lastAdded: display.lastAdded },
        headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
      });
      setData(data.data);
      setIsLoading(false);
      setIsLoadingGraph(false);
    } catch (err) {
      notify.show(err.message, 'error');
      history.push('/');
    }
  };

  useEffect(() => {
    setIsLoadingGraph(true);
    fetchData();
    //eslint-disable-next-line
  }, [display]);

  const clearSearch = () => {
    setDisplay({ isActive: IS_ACTIVE, lastAdded: LASTADDED_CLASSROOMS });
  };

  if (isLoading) {
    return <LoadingScreen />;
  } else if (isLoading === false) {
    return (
      <Container className='mb-5 pl-0 pr-0'>
        <Container className='bg-light rounded shadow p-3 pl-0 mb-5'>
          <Form>
            <Row className='mb-2'>
              <Col>
                <Form.Group className='my-auto'>
                  <div>
                    <Button
                      variant={
                        display.isActive === IS_ACTIVE ? 'dark' : 'outline-dark'
                      }
                      onClick={() => setDisplay({ ...display, isActive: IS_ACTIVE })}
                    >
                      Activos
                    </Button>
                    <Button
                      variant={display.isActive === 'down' ? 'dark' : 'outline-dark'}
                      onClick={() =>
                        setDisplay({ ...display, isActive: 'down' })
                      }
                      disabled
                    >
                      Bajas
                    </Button>
                  </div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='my-auto'>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Comisiones</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      onChange={(e) => {
                        setDisplay({
                          ...display,
                          lastAdded: Number(e.target.value),
                        });
                      }}
                      value={display.lastAdded}
                      name='lastAdded'
                      as='select'
                      className='w-75'
                    >
                      <option value='10'>últimas 10</option>
                      <option value='20'>últimas 20</option>
                    </Form.Control>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                <Button className='w-100' onClick={() => clearSearch()}>
                  Limpiar búsqueda
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
        {isLoadingGraph ? (
          <div className='container d-flex bg-light rounded shadow p-3 align-items-center ml-0 mr-0'>
            <div className='d-flex justify-content-center align-items-center'>
              <Spinner
                animation='border'
                role='status'
                size='md'
                className='ml-3 mr-3 my-auto'
                variant='info'
              />
              <p className='m-0 p-0'>Cargando...</p>
            </div>
          </div>
        ) : (
          <div className='container d-flex bg-light rounded shadow p-3 align-items-center ml-0 mr-0'>
            <div>
              <BarChart
                width={1000}
                height={500}
                data={data}
                style={{ overflow: 'visible' }}
                className='mx-auto'
                barSize={50}
              >
                <CartesianGrid strokeDasharray='1 1' />
                <XAxis dataKey='classroom' />
                <YAxis />
                <Tooltip />
                <Legend className='mt-2' />
                <Bar
                  dataKey='activeStudentsCount'
                  fill='#78cb7d'
                  name='Alumnos activos'
                />
              </BarChart>
            </div>
          </div>
        )}
      </Container>
    );
  } else {
    return <div />;
  }
};

export default ClassroomStatistics;
