import Axios from "axios";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { notify } from "react-notify-toast";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { UserContext } from "../../../Context/UserContext";
import authToken from "../../../clients/authToken";

export default function Verify2FA() {
  const [inputCode, setInputCode] = useState("");
  const { verify2FA } = useContext(UserContext);
  const history = useHistory();

  const verifyLengthToken = (tokenUser: any) => {
    const numberLength = 6;
    if (tokenUser.length === numberLength) {
      verify2fa(tokenUser);
    }
  }
  const handleOnChange = (event: any) => {
    setInputCode(event.target.value);
    const tokenUser = event.target.value;
    verifyLengthToken(tokenUser);
  }

  const handleSubmitPaste = (event: any) => {
    setInputCode(event.target.value);
    const tokenUser = event.target.value;
    verifyLengthToken(tokenUser);
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
  }

  const verify2fa = (tokenUser: any) => {
    Axios.post(
      "/api/user/2fa/verify",
      { token: tokenUser },
      {
        headers: { "auth-token": localStorage.getItem("JWT") || " "},
      }
    )
    .then((response) => {
      if (response.data.success) {
        verify2FA(true);
        authToken(localStorage.getItem("JWT"));
        history.push("/");
        } else {
          notify.show(response.data.message || "No se ha podido validar el token", "error");
        }
      })
      .catch(({ response }) => {
        notify.show(response.data.message || "No se ha podido validar el token", "error");
      });
    };

  return (
    <Container>
      <Row className="d-flex align-items-center   text-center">
        <Col className="w-100 bg-white shadow rounded p-3 mb-5 mx-2">
          <h1 className="display-4">Verificá tu identidad</h1>
          <p className="lead">
            Ingresá desde tu celular a "Google Authenticator" e ingresá el
            codigo bajo el ítem que dice "RollingCode Student Manager"
          </p>

          <Row>
            <Col />
            <Col>
              <Form className="mt-5" onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>Token</Form.Label>
                  <Form.Control
                    value={inputCode}
                    onPaste={handleSubmitPaste}
                    onChange={handleOnChange}
                    type="number"
                    placeholder="Token de Google Authenticator"
                  />
                </Form.Group>
              </Form>
            </Col>
            <Col />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
