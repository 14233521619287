import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Container, Col, Row, Form, InputGroup } from 'react-bootstrap';
import Axios from 'axios';
import LoadingScreen from './../../Layout/LoadingScreen';
import AgeGroupChart from './AgeGroupChart';
import moment from 'moment';
import { notify } from 'react-notify-toast';

const AverageAges = () => {
  const [ages, setAges] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPeriodChecked, setIsPeriodChecked] = useState(false);
  const [dataToShow, setDataToShow] = useState([]);
  const [selectedDates, setSelectedDates] = useState({
    initialDate: moment().startOf('month').format('YYYY-MM-DD'),
    finalDate: moment().endOf('month').format('YYYY-MM-DD'),
  });

  const getAges = async () => {
    try {
      setIsLoading(true);
      const { data } = await Axios('/api/graph/average-ages/');
      setAges(data);
    } catch (error) {
      notify.show('Ha ocurrido un error al recuperar la información.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const getAgesByGroup = async () => {
    try {
      setIsLoading(true);
      const { data } = await Axios(
        `api/graph/ages-groups?from=${selectedDates?.initialDate}=&to=${selectedDates?.finalDate}`
      );
      setDataToShow(data);
    } catch (error) {
      notify.show('Ha ocurrido un error al recuperar la información.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAges();
  }, []);

  useEffect(() => {
    getAgesByGroup();
  }, [selectedDates]);

  return (
    <Container className="container bg-light rounded shadow p-3 mb-3">
      <Row>
        <Col>
          <Form>
            <Row>
              <Col>
                <Form.Check
                  type="checkbox"
                  label="Altas por segmentos de edades"
                  value="segments"
                  checked={isPeriodChecked}
                  onChange={(e) => {
                    setIsPeriodChecked(!isPeriodChecked);
                  }}
                />
              </Col>
              <Col>
                <Form.Row>
                  <Col>
                    <Form.Group className="my-auto">
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>Desde:</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="date"
                          name="initialDate"
                          className="w-100"
                          disabled={!isPeriodChecked}
                          defaultValue={selectedDates?.initialDate}
                          onChange={(e) => {
                            setSelectedDates((prevState) => ({
                              ...prevState,
                              initialDate: e?.target?.value,
                            }));
                          }}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="my-auto">
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>Hasta:</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="date"
                          name="finalDate"
                          className="w-100"
                          disabled={!isPeriodChecked}
                          defaultValue={selectedDates?.finalDate}
                          onChange={(e) => {
                            setSelectedDates((prevState) => ({
                              ...prevState,
                              finalDate: e?.target?.value,
                            }));
                          }}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Col>
            </Row>
          </Form>

          {isLoading ? (
            <LoadingScreen />
          ) : !isPeriodChecked ? (
            <>
              <h4>Promedio de edades</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Promedio</th>
                    <th>Máximo</th>
                    <th>Mínimo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{Math.round(ages?.average)}</td>
                    <td>{ages?.maxAge}</td>
                    <td>{ages?.minAge}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          ) : (
            isPeriodChecked && (
              <>
                <h4>Altas por segmentos de edad</h4>
                <AgeGroupChart dataToShow={dataToShow} />
              </>
            )
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default AverageAges;
