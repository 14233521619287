import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import ZoomModal from "./ZoomModal.js";
import { notify } from "react-notify-toast";

export default function Zoom() {
  const [loged, setLoged] = useState(false);
  const [authToken, setAuthToken] = useState({});
  const [showZoomModal, setShowZoomModal] = useState(false);
  const [usersAnswer, setUsersAnswer] = useState([]);
  const [meetingsAnswer, setMeetingsAnswer] = useState([]);

  let webSiteCallback = "https%3A%2F%2Fadmin.rollingcodeschool.com%2Fteacher";

  const { search } = useLocation();

  let counter = 0;


  useEffect(() => {
    verifyToken();
  });

  useEffect(() => {
    let code = "";

    if (!loged) {
      if (counter === 0) {
        if (search.slice(0, 6) === "?code=") {
          setLoged(true);
          code = search.slice(6);

          if (code !== "") {
            axios
              .post(
                `api/zoom/getAuth`,
                { code, webSiteCallback },
                { headers: { "auth-token": localStorage.getItem("JWT") || " "} }
              )
              .then((res) => {
                authTokenExpiration(res.data);
              });
          }
        }
        ++counter;
      }
    }
    //eslint-disable-next-line
  }, [counter]);

  const verifyToken = () => {
    if (localStorage.getItem("authTokenZoom")) {
      if (
        new Date().getTime() >
        JSON.parse(localStorage.getItem("authTokenZoom")).expiry
      ) {
        let renovationToken = JSON.parse(
          localStorage.getItem("authTokenZoomRenovation")
        );
        axios
          .post(
            `api/zoom/getAuth`,
            {
              renovationToken,
            },
            {
             
            }
          )
          .then((res) => {
            authTokenExpiration(res.data);
          })
          .catch((err) => {
            notify.show(
              "Su token no pudo ser renovado, por favor, vuelva a logearse",
              "error"
            );
            localStorage.removeItem("authTokenZoom");
            localStorage.removeItem("authTokenZoomRenovation");
          });
      } else {
        setAuthToken(JSON.parse(localStorage.getItem("authTokenZoom")).value);
        setLoged(true);
      }
    }
  };

  const authTokenExpiration = (data) => {
    const token = {
      value: data.resAuth.access_token,
      expiry: new Date().getTime() + data.resAuth.expires_in * 1000,
    };
    const tokenRenovation = { value: data.resAuth.refresh_token };
    setAuthToken(token.value);
    localStorage.setItem("authTokenZoom", JSON.stringify(token));
    localStorage.setItem(
      "authTokenZoomRenovation",
      JSON.stringify(tokenRenovation)
    );
  };

  const buttonActions = () => {
    if (loged) {
      getUsers();
      getMeetings();
      setShowZoomModal(!showZoomModal);
    } else {
      notify.show(
        "Su token no pudo ser renovado, por favor, vuelva a logearse",
        "error"
      );
    }
  };

  const getUsers = async () => {
    await verifyToken();
    await axios
      .post(`api/zoom/getUsers`, { authToken }, {})
      .then(async (res) => {
        await setUsersAnswer([res.data.users]);
      });
  };

  const getMeetings = async () => {
    await verifyToken();
    await axios
      .post(`api/zoom/getMeetings`, { authToken }, {})
      .then(async (res) => {
        await setMeetingsAnswer(res.data.meetings);
      });
  };

  return (
    <>
      <ZoomModal
        show={showZoomModal}
        onHide={() => setShowZoomModal(!showZoomModal)}
        meetings={() => getMeetings()}
        users={() => getUsers()}
        meetingsAnswer={meetingsAnswer}
        usersAnswer={usersAnswer}
      />
      <Button
        href={
          loged
            ? ""
            : `https://zoom.us/oauth/authorize?response_type=code&client_id=jtzeLm2GShe43G22I9DRNw&redirect_uri=${webSiteCallback}`
        }
        onClick={() => buttonActions()}
      >
        {loged ? "Acciones de Zoom" : "Logueate en Zoom"}
      </Button>
    </>
  );
}
