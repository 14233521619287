import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import JsonFormatter from 'react-json-formatter';
import './modalDetail.css'

const ModalDetail = ({ isModalDetailOpen, allData, setIsModalDetailOpen }) => {
  const jsonStyle = {
    propertyStyle: { color: 'red' },
    stringStyle: { color: 'green' },
    numberStyle: { color: 'darkorange' },
    booleanStyle: { color: 'purple'}
  }

  return (
    <Modal show={isModalDetailOpen} onHide={() => setIsModalDetailOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Detalle</Modal.Title>
      </Modal.Header>
      <Modal.Body className='modalBody'>
      <JsonFormatter json={allData} tabWith={2} jsonStyle={jsonStyle} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setIsModalDetailOpen(false)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDetail;
