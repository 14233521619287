export const filterAndformatDataForChart = (
  data,
  selectedInitialDate,
  selectedFinalDate
) => {
  const startDate = new Date(selectedInitialDate);
  const endDate = new Date(selectedFinalDate);

  const filteredData = {};
  for (const region in data) {
    filteredData[region] = data[region].filter((item) => {
      const createdAt = new Date(item.createdAt);
      return createdAt >= startDate && createdAt <= endDate;
    });
  }

  const dataFormatted = [];
  for (const city in filteredData) {
    dataFormatted.push({
      name: city,
      value: filteredData[city].length,
    });
  }

  return dataFormatted;
};
