import React, { useEffect, useState } from 'react';
import { Table, Dropdown, Col } from 'react-bootstrap';
import { notify } from 'react-notify-toast';
import LoadingScreen from '../../Layout/LoadingScreen';
import clientAxios from '../../clients/clientAxios';
import moment from 'moment';

const CertificatesViewer = () => {
  const [certificates, setCertificates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [optionSearch, setOptionSearch] = useState('FullStack');

  const getCertificates = async () => {
    try {
      setIsLoading(true);
      const { data } = await clientAxios.get(
        `/certificate/courseByType/?type=${optionSearch}`
      );
      setCertificates(data);
    } catch {
      notify.show(
        'Ha ocurrido un error al intentar traer los certificados.',
        'error'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleOptionsDroptdown = (option) => {
    setOptionSearch(option);
  };

  useEffect(() => {
    getCertificates();
  }, [optionSearch]);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className='d-flex justify-content-center align-items-center flex-column mt-5 table-responsive'>
          <Col className='mb-5 p-1 d-flex justify-content-between'>
            <Dropdown
              data-bs-theme='dark'
              menuVariant='dark'
              className='mx-3 d-inline'
            >
              <Dropdown.Toggle variant='dark' id='dropdown-basic'>
                {optionSearch}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => handleOptionsDroptdown('FullStack')}
                >
                  FullStack
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleOptionsDroptdown(
                      'Creación y Diseño de páginas web - Carrera Fullstack (3 meses)'
                    )
                  }
                >
                  Creación y Diseño de páginas web - Carrera Fullstack (3 meses)
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleOptionsDroptdown('React')}>
                  React
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <h3 className='d-inline font-weight-bold mx-5'>
              Certificados: {certificates.length}
            </h3>
          </Col>
          <Table striped bordered hover variant='dark' className='w-75'>
            <thead>
              <tr>
                <th className='text-center'>Nombre</th>
                <th className='text-center'>Apellido</th>
                <th className='text-center'>Email</th>
                <th className='text-center'>Certificados</th>
                <th className='text-center'>Fecha de emisión</th>
                <th className='text-center'>Actions WIP</th>
              </tr>
            </thead>
            <tbody>
              {certificates &&
                certificates.map((certificate, index) => (
                  <tr key={index}>
                    <td className='text-center'>{certificate?.userName}</td>
                    <td className='text-center'>{certificate?.userLastName}</td>
                    <td className='text-center'>{certificate?.email}</td>
                    <td className='text-center'>{certificate?.courseName}</td>
                    <td className='text-center'>
                      {moment(certificate.dateTo).format('DD/MM/YYYY')}
                    </td>
                    <td className='text-center'>Actions</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};

export default CertificatesViewer;
