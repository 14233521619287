import React from "react";
import { ListGroup, ListGroupItem, Row, Col } from "react-bootstrap";

const Students = ({ students, isValidComission }) => {
  return (
    <Col className="pt-3 pb-3 pl-3 pr-3 mt-3 ml-3 mr-3 bg-light rounded shadow">
      <Row>
        <Col>
          <p className="m-0 p-0 lead">Alumnos</p>
        </Col>
      </Row>
      <Row>
        <Col className="pt-3">
          <ListGroup as="ul" style={{ height: 250, overflowY: "scroll" }}>
            {isValidComission ? (
              students.length === 0 ? (
                <ListGroupItem>Esta comisión no tiene alumnos</ListGroupItem>
              ) : (
                students.map((student, index) => {
                  return (
                    <ListGroupItem key={index}>
                      <Row>
                        <Col xs={10} xl={10}>
                          {`${student.name} ${student.surname}`}
                        </Col>
                      </Row>
                    </ListGroupItem>
                  );
                })
              )
            ) : (
              <ListGroupItem>Seleccione una comisión válida</ListGroupItem>
            )}
          </ListGroup>
        </Col>
      </Row>
    </Col>
  );
};

export default Students;
