import React, { useContext, useState, useEffect } from "react";
import { Modal, Row, Col, Button, Form, InputGroup, Container } from "react-bootstrap";
import { notify } from "react-notify-toast";
import axios from "axios";
import { USER_LEVEL_MENTOR } from "../../constants";

export default function AddTeacher({
  show,
  onHide,
  setAddTeacher,
  setValidated
}: {
  show: boolean;
  onHide: () => void;
  setAddTeacher: boolean;
  setValidated: (data: boolean) => void;
}) {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isActive, setIsActive] = useState("true");

  const addMentorTemporal = () => {
    if (name && email && password && isActive) {
      axios.post(`api/user/register`, {
        level: USER_LEVEL_MENTOR,
        name,
        email,
        password,
        firstLogin: true,
        isActive: isActive === "true" ? true : false
      }, { headers: { "auth-token": localStorage.getItem("JWT") || " "}, })
        .then(({ data }) => {
          setValidated(true);
          onHide()
        })
        .catch((err) => {
          console.error(err.message)
          notify.show("No se pudo crear el mentor", "error");
        });
    } else {
      notify.show("Todos los campos son obligatorios", "error");
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar mentor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <Form noValidate validated={validated} onSubmit={(event : any) => addMentor(event, saveTypeLet)}> */}
        <Container>
          <Row>
            <InputGroup className="m-0 p-0 pb-1">
              <InputGroup.Prepend>
                <InputGroup.Text>Nombre</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                name="name"
                value={name}
                onChange={(e) => { setName(e.target.value) }}
                required
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup className="m-0 p-0 pb-1">
              <InputGroup.Prepend>
                <InputGroup.Text>Email</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                name="email"
                value={email}
                onChange={(e) => { setEmail(e.target.value) }}
                required
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup className="m-0 p-0 pb-1">
              <InputGroup.Prepend>
                <InputGroup.Text>Contraseña inicial</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                name="password"
                value={password}
                onChange={(e) => { setPassword(e.target.value) }}
                required
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup className="m-0 p-0 pb-1">
              <InputGroup.Prepend>
                <InputGroup.Text>Activo/Baja</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                name="isActive"
                value={isActive}
                onChange={(e) => { setIsActive(e.target.value) }}
                required
              >
                <option value="true">Activo</option>
                <option value="false">Baja</option>
              </Form.Control>
            </InputGroup>
          </Row>
        </Container>
        {/* </Form> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="success" onClick={addMentorTemporal}>
          Agregar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
