import React, { useState } from "react";
import { Row, Col, ListGroup } from "react-bootstrap";
import { debtorBillsType } from "../../Types/Debtors";
import PaginationBar from "../Pagin";
import { propTypes } from "./Debtors";
import Filter from "./subcomponents/Filter";
import ItemOfTheDebtorList from "./subcomponents/ItemOfTheDebtorList";

export default function InProgress({
  refresh,
  hasToRefresh,
  debtorsBills,
  data,
  className,
  toggleViewCommentsModal,
  filter,
  isLoading,
}: propTypes) {
  const [list, setList] = useState<Partial<debtorBillsType[]>>([]);

  const onChangePage = (pagedData: debtorBillsType[]) => {
    setList(pagedData);
  };

  return (
    <div className={className}>
      <Row>
        <Filter isLoading={isLoading} filterSubmit={(query) => filter(query)} origin={"inProgress"} />
      </Row>
      <Row className="bg-light rounded shadow d-flex justify-content-center py-3 mb-3">
        <Col>
          <ListGroup as="ul" className="mt-3">
            <ItemOfTheDebtorList refresh={() => null} header inProgress />
            {list.length === 0 ? (
              <ItemOfTheDebtorList refresh={() => null} noEntry />
            ) : (
              list.map((invoice, index) => (
                <ItemOfTheDebtorList
                  from="inProgress"
                  inProgress
                  refresh={(
                    i: string,
                    where: string,
                    from: string,
                    id: string,
                    comment: string
                  ) => {
                    refresh(i, where, "inProgress", id, comment);
                  }}
                  key={index}
                  data={invoice}
                />
              ))
            )}
          </ListGroup>
        </Col>
      </Row>
      <Row>
        <Col className="bg-light rounded shadow d-flex justify-content-center py-3 mb-3">
          <PaginationBar
            items={data}
            onChangePage={onChangePage}
            pageSize={5}
            hasToRefresh={hasToRefresh}
          />
        </Col>
      </Row>
    </div>
  );
}
