import Axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { notify } from "react-notify-toast";

export default function WriteMessage({ className }: { className: string }) {
  const [state, setState] = useState<messageType>({
    mentorList: [],
    title: "",
    body: "",
    assignatedMentor: "",
    viewed: [],
    showMentorSelector: false,
  });

  useEffect(() => {
    Axios.get(`api/user/getMentorList`, {
      headers: { "auth-token": localStorage.getItem("JWT") || " "},
    })
      .then(({ data }) => {
        setState((state) => ({ ...state, mentorList: data.data }));
      })
      .catch((err) => {
        notify.show(`Error: ${err.response.data.message}`, "error");
      });
  }, []);

  const handleChange = (e: any) => {
    let { name, value } = e.target;

    setState({ ...state, [name]: value });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    Axios.post(`api/com/create`, state, {
      headers: { "auth-token": localStorage.getItem("JWT") || " "},
    })
      .then(({ data }) => {
        if (data.success) {
          notify.show("Mensaje guardado", "success");
          setState({ ...state, title: "", body: "", assignatedMentor: "" });
        }
      })
      .catch((err) => {
        notify.show(`Error: ${err.response.data.message}`, "error");
      });
  };

  return (
    <div className={`${className} bg-light rounded shadow p-3`}>
      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>Título</Form.Label>
          <Form.Control
            type="text"
            onChange={handleChange}
            name="title"
            value={state.title}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Mensaje</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            onChange={handleChange}
            name="body"
            value={state.body}
          />
        </Form.Group>

        <Form.Group>
          <Form.Check
            type="checkbox"
            label="Enviar mensaje a todos los mentores"
            onClick={() => {
              setState({
                ...state,
                showMentorSelector: !state.showMentorSelector,
                assignatedMentor: "",
              });
            }}
            checked={state.showMentorSelector}
          />
        </Form.Group>
        <div>
          <Form.Group
            className={state.showMentorSelector ? "d-none" : "d-block"}
          >
            <Form.Label>Seleccione mentor</Form.Label>
            <Form.Control
              onChange={handleChange}
              value={state.assignatedMentor}
              name="assignatedMentor"
              as="select"
            >
              <option value="">Seleccione una opción...</option>
              {state.mentorList.map((mentor) => (
                <option value={mentor._id}>{mentor.name}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
        <Button variant="success" type="submit">
          <i className="fas fa-paper-plane" /> Enviar
        </Button>
      </Form>
    </div>
  );
}

interface messageType {
  mentorList: { _id: string; name: string }[];
  title: string;
  body: string;
  assignatedMentor: string;
  viewed: string[];
  showMentorSelector: boolean;
}
