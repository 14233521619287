import React, { useContext, useState, useEffect } from 'react'
import { Container, Row, Col, Form, InputGroup, FormControl, Button, ListGroup } from "react-bootstrap";
import { UserContext } from "../../Context/UserContext";
import LoadingScreen from "../../Layout/LoadingScreen";
import { notify } from "react-notify-toast";
import DeleteTeacher from "./DeleteTeacher.tsx";
import TeachersList from "./TeachersList";
import EditTeacher from "./EditTeacher";
import AddTeacher from "./AddTeacher";
import EditPasswordTeacher from "./EditPasswordTeacher";
import axios from "axios";

export default function Teachers(props) {

  const [loading, setLoading] = useState(true);
  const [mentorList, setMentorList] = useState([]);
  const [modalState, setModalState] = useState({
    id: "",
    delete: false,
    edit: false,
    editPass: false,
    name: "",
    email: "",
    isActive: false
  });
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showEditPass, setShowEditPass] = useState(false);
  const [addTeacher, setAddTeacher] = useState(false);
  const user = useContext(UserContext);


  useEffect(() => {
    getMentors()
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (modalState.edit) {
      setShowEdit(true)
    } else if (modalState.delete) {
      setShowDelete(true)
    } else if (modalState.editPass) {
      setShowEditPass(true)
    }
    //eslint-disable-next-line
  }, [modalState]);

  const getMentors = () => {
    axios.get(`api/user/getMentorList`, { headers: { "auth-token": localStorage.getItem("JWT") } })
      .then(({ data }) => {
        setMentorList(data.data);
        setLoading(false);
      })
      .catch((err) => {
        notify.show("No se pudo obtener los mentores", "error");
      });
  }

  if (loading) {
    return <LoadingScreen />
  } else {
    return (
      <Container className="mb-5 mt-5 pl-0 pr-0">
        <AddTeacher
          show={showAdd}
          onHide={() => setShowAdd(false)}
          setAddTeacher={setAddTeacher}
          setValidated={(data) => {
            if (data) {
              setLoading(true);
              getMentors();
            }
          }}
        />
        <EditTeacher
          show={showEdit}
          onHide={() => setShowEdit(false)}
          setValidated={(data) => {
            if (data) {
              setLoading(true);
              getMentors();
            }
          }}
          modalState={modalState}
        />
        <DeleteTeacher
          show={showDelete}
          onHide={() => setShowDelete(false)}
          setValidated={(data) => {
            if (data) {
              setLoading(true);
              getMentors();
            }
          }}
          modalState={modalState}
          setModalState={(data) => setModalState(data)}
        />
        <EditPasswordTeacher
        show={showEditPass}
        onHide={() => setShowEditPass(false)}
        modalState={modalState}
        />
        <Row>
          <Col>
            <h1>Lista de mentores</h1>
          </Col>
          <Col className="d-flex justify-content-end">
            <div className="bg-light rounded shadow p-3 pl-0 mb-5">
              <Button onClick={() => setShowAdd(true)}>Agregar mentor</Button>
            </div>
          </Col>
        </Row>
        <TeachersList
          mentorList={mentorList}
          setModalState={(data) => setModalState(data)}
          modalState={modalState}
        />
      </Container>
    )
  }
}
