import React, { useState } from 'react';
import { Col, DropdownButton, Dropdown } from 'react-bootstrap';
import { notify } from 'react-notify-toast';
import clientAxios from '../../../../clients/clientAxios';

const SelectStatusStudentCommission = ({
  student,
  classroomId,
  isLoading,
  setIsLoading,
  setDebtorsList,
  debtorList,
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const SUSPENDED_STATUS = 'suspended';
  const SUSPENDED_STATUS_BOOLEAN = true;
  const ACTIVE_STATUS = 'active';
  const ACTIVE_STATUS_BOOLEAN = false;
  const notSuspended = debtorList?.notSuspended;

  const options = [
    { id: 1, label: 'Habilitado en campus y universe' },
    { id: 2, label: 'Habilitado en campus' },
    { id: 3, label: 'Habilitado en universe' },
    { id: 4, label: 'Deshabilitado en campus' },
    { id: 5, label: 'Deshabilitado en universe' },
    { id: 6, label: 'Deshabilitado de campus y universe' },
  ];

  const deleteUserOfListOftudent = (student, list) => {
    const index = list.findIndex((item) => item.email === student.email);
    if (index !== -1) {
      notSuspended.splice(index, 1);
    }
    setDebtorsList({ ...debtorList, notSuspended });
  };

  const addedUserOfListOfStudent = (student) => {
    const addingStudent = {
      email: student.email,
      name: student.name,
      lastname: student.surname,
    };
    notSuspended.push(addingStudent);
    setDebtorsList({ ...debtorList, notSuspended });
  };

  const changeStatusUniverse = async (student, classroomId, status) => {
    try {
      const { data } = await clientAxios.patch('/students', {
        studentId: student._id,
        classroomId,
        status,
        email: student?.email,
      });
      if (data.success) {
        notify.show(
          'Se ha actualizado el estado del comision en Rolling Universe',
          'success'
        );
        setIsLoading((prevState) => !prevState);
      } else {
        notify.show(
          'Ha ocurrido un error al intentar actualizar el estado.',
          'error'
        );
      }
      notify.show('El estado se ha actulizado correctamente.', 'success');
    } catch (error) {
      notify.show(error.message, 'error');
    } finally {
      setIsLoading((prevState) => !prevState);
    }
  };

  const changeStatusCampus = async (student, status) => {
    try {
      const successfulResponseCode = 200;
      const campusUserId = student?.campusUserId;
      const resp = await clientAxios.patch('/campus/suspend', {
        campusUserId: campusUserId,
        status,
        email: student?.email,
      });
      setIsLoading((prevState) => !prevState);
      if (resp?.status === successfulResponseCode) {
        notify.show('El estado se ha actulizado correctamente.', 'success');
      } else {
        notify.show(
          'ha ocurrido un error al intentar actualizar el estado.',
          'error'
        );
      }
    } catch (error) {
      notify.show(
        'ha ocurrido un error al intentar actualizar el estado.',
        'error'
      );
    } finally {
      setIsLoading((prevState) => !prevState);
    }
  };

  const actions = {
    1: () => {
      changeStatusUniverse(student, classroomId, ACTIVE_STATUS);
      changeStatusCampus(student, ACTIVE_STATUS_BOOLEAN);
      addedUserOfListOfStudent(student);
    },
    2: () => {
      changeStatusCampus(student, ACTIVE_STATUS_BOOLEAN);
      addedUserOfListOfStudent(student);
    },
    3: () => {
      changeStatusUniverse(student, classroomId, ACTIVE_STATUS);
      addedUserOfListOfStudent(student);
    },
    4: () => {
      changeStatusCampus(student, SUSPENDED_STATUS_BOOLEAN);
      deleteUserOfListOftudent(student, notSuspended);
    },
    5: () => {
      changeStatusUniverse(student, classroomId, SUSPENDED_STATUS);
      deleteUserOfListOftudent(student, notSuspended);
    },
    6: () => {
      changeStatusUniverse(student, classroomId, SUSPENDED_STATUS);
      changeStatusCampus(student, SUSPENDED_STATUS_BOOLEAN);
      deleteUserOfListOftudent(student, notSuspended);
    },
  };

  const handleSelect = (eventKey) => {
    const eventKeyToNumber = parseInt(eventKey);
    const selected = options.find((opcion) => opcion.id === eventKeyToNumber);
    setSelectedOption(selected.label);
    actions[eventKey]();
  };

  return (
    <Col className='text-center'>
      <DropdownButton
        title={selectedOption || 'Seleccionar opción'}
        onSelect={handleSelect}
      >
        {options.map((opcion) => (
          <Dropdown.Item key={opcion.id} eventKey={opcion.id}>
            {opcion.label}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </Col>
  );
};

export default SelectStatusStudentCommission;
