import React, { useEffect, useRef, useState } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import CreateCoupon from './CreateCoupon';
import CouponsAssigned from './CouponsAssigned';
import LoadingScreen from '../../Layout/LoadingScreen';
import clientAxios from '../../clients/clientAxios';

const Coupons = () => {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(false);
  const isComponentMounted = useRef(false);

  const getCoupons = async () => {
    setLoading(true);
    try {
      const response = await clientAxios.get('/couponPromos/');
      if (isComponentMounted?.current) {
        setCoupons(response?.data ?? []);
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (isComponentMounted?.current) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    isComponentMounted.current = true;
    getCoupons();
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Container>
          <Tabs defaultActiveKey='coupon' id='coupon-tab'>
            <Tab eventKey='coupon' title='Asignar coupon'>
              <CreateCoupon getCoupons={getCoupons} />
            </Tab>
            <Tab eventKey='profile' title='Cupones asignados'>
              <CouponsAssigned coupons={coupons}/>
            </Tab>
          </Tabs>
        </Container>
      )}
    </>
  );
};

export default Coupons;