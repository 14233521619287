import React from "react";
import { Modal, Button } from "react-bootstrap";
import Axios from "axios";
import { notify } from "react-notify-toast";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

export default function ConfirmStudentDelete({ show, onHide, studentId }) {
  const history = useHistory();
  const confirmStudentDelete = () => {
    Axios.delete(`/api/students/${studentId}`, {
      headers: { "auth-token": localStorage.getItem("JWT") || " "},
    })
      .then((res) => {
        notify.show("Alumno eliminado", "success");
        history.push("/students/viewAll");
      })
      .catch((err) => {
        notify.show(`Ocurrió un error. ${err}`, "error");
      });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>¡Advertencia!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        ¿Seguro que desea eliminar al alumno? Esta acción no se puede deshacer
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          <FontAwesomeIcon icon={faTimesCircle} /> Cancelar
        </Button>
        <Button variant="danger" onClick={confirmStudentDelete}>
          <FontAwesomeIcon icon={faCheckCircle} /> Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
