import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import Axios from "axios";
import { notify } from "react-notify-toast";
import { RKioskItem } from "../../Types/RKioskItem";

export default function AddProduct() {
  const history = useHistory();

  // State management
  const [state, setState] = useState<RKioskItem>({
    name: "",
    barcode: "",
    price: 0,
    stock: 0,
    image: "",
  });
  const [validated, setValidated] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const handleChange = (e: any) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  // /State management

  const handleSubmit = (e: {
    preventDefault: () => void;
    currentTarget: { checkValidity: () => boolean };
  }) => {
    e.preventDefault();
    if (e.currentTarget.checkValidity() === false) {
      notify.show("Verifique los datos ingresados", "error");
    } else {
      saveToDatabase();
      history.push("/students/viewAll");
    }

    setValidated(true);
  };

  /**
   * Guarda y recarga el formulario.
   */
  const saveAndReload = () => {
    saveToDatabase();
  };

  /**
   * Guarda el form a la base de datos
   */
  const saveToDatabase = () => {
    setIsSaving(true);
    Axios.post(`/api/rollingkiosk/products/create`, state, {
      headers: { "auth-token": localStorage.getItem("JWT") || " "}
    })
      .then((i) => {
        setIsSaving(false);
        setState({
          name: "",
          barcode: "",
          price: 0,
          stock: 0,
          image: "",
        });
        notify.show(i.data.message, "success");
      })
      .catch((err) => {
        let { message } = err.response.data;
        setIsSaving(false);
        notify.show(message, "error");
      });
  };

  let required = <strong className="text-danger">*</strong>;

  return (
    <Container>
      <Row>
        <Col>
          <Form
            noValidate
            validated={validated}
            className="bg-light shadow border rounded p-4 px-5 mb-5"
            onSubmit={handleSubmit}
          >
            <h1 className="display-5 mb-5">Agregar un producto: </h1>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Nombre del producto{required}</Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="name"
                      onChange={(e) => handleChange(e)}
                      value={state.name}
                      maxLength={100}
                      type="text"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Ingrese un nombre
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group>
                  <Form.Label>Precio{required}</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>$</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      min={1}
                      type="number"
                      name="price"
                      onChange={(e) => handleChange(e)}
                      value={state.price}
                      onKeyDown={(evt: {
                        key: string;
                        preventDefault: () => any;
                      }) => evt.key === "e" && evt.preventDefault()}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Ingrese un precio inicial
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Código de barras{required}</Form.Label>
                  <InputGroup>
                    <Form.Control
                      min={0}
                      type="number"
                      name="barcode"
                      onChange={(e) => handleChange(e)}
                      value={state.barcode}
                      onKeyDown={(evt: {
                        key: string;
                        preventDefault: () => any;
                      }) => evt.key === "e" && evt.preventDefault()}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Ingrese el código de barras
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group>
                  <Form.Label>Cantidad</Form.Label>
                  <InputGroup>
                    <Form.Control
                      min={0}
                      type="number"
                      name="stock"
                      onChange={(e) => handleChange(e)}
                      onKeyDown={(evt: {
                        key: string;
                        preventDefault: () => any;
                      }) => evt.key === "e" && evt.preventDefault()}
                      value={state.stock}
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group>
              <Form.Label>Imagen (URL)</Form.Label>
              <InputGroup>
                <Form.Control
                  name="image"
                  onChange={(e) => handleChange(e)}
                  value={state.image}
                  type="text"
                />
              </InputGroup>
            </Form.Group>
            <div className="text-right w-100">
              <Button
                className="mr-3"
                variant="info"
                disabled={isSaving}
                type="submit"
              >
                <i className="fas fa-save mr-2" />
                Guardar producto
              </Button>
              <Button
                variant="info"
                disabled={isSaving}
                onClick={saveAndReload}
              >
                <i className="fas fa-sync mr-2" />
                Guardar y cargar un nuevo prod.
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
