import React from "react";
import Axios from "axios";
import { notify } from "react-notify-toast";
import { Modal, Button } from "react-bootstrap";

export default function ConfirmDelete({
  show,
  onHide,
  idToDelete,
  updateCommission,
}: {
  show: Boolean;
  onHide: () => void;
  idToDelete: String;
  updateCommission: () => void;
}) {
  const handleDelete = () => {
    Axios.delete("api/classroom/delete/" + idToDelete, {
      headers: { "auth-token": localStorage.getItem("JWT") || " "},
    })
      .then(({ data }) => {
        if (data.success) {
          updateCommission();
          notify.show(data.message, "success");
          onHide();
        } else {
          notify.show(data.message, "error");
        }
      })
      .catch((err) => notify.show(err.message, "error"));
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Borrar comisión</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Estás a punto de borrar todos los datos de esta comisión. Esta acción no
        se puede deshacer.      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cerrar
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Borrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
