import React, { useContext, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClassroomList } from '../../Context/ClassroomListContext';
import clientAxios from '../../clients/clientAxios';
import '../../styles/debtCollections.css';
import PaymentHistorial from '../students/details/modals/PaymentHistorial';
import StudentCommissions from '../students/details/modals/StudentCommissions';

const DebtCollection = () => {
  const classroomList = useContext(ClassroomList);
  const [debtorsList, setDebtorsList] = useState(null);
  const [sortedClassroomList, setSortedClassroomList] = useState(null);
  const [debtorsByClassroom, setDebtorsByClassroom] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClassroom, setSelectedClassroom] = useState('');
  const [showPaymentHistorialModal, setShowPaymentHistorialModal] =
    useState(false);
  const [idStudent, setIdStudent] = useState('');
  const [statusCommisionChangeFlag, setStatusCommisionChangeFlag] =
    useState(false);
  const getDebtorsList = async () => {
    try {
      setIsLoading(true);
      const { data } = await clientAxios(
        `/bills/debtors-interest-rate?classroom=${selectedClassroom}`
      );
      setDebtorsList(data);
      setDebtorsByClassroom(data.details);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    setSelectedClassroom(e.target.value);
  };

  const handleSearch = () => {
    getDebtorsList();
  };

  useEffect(() => {
    if (classroomList) {
      const sortedClassrooms = classroomList?.crList.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
      setSortedClassroomList(sortedClassrooms);
    }
  }, []);

  const openModalPaymentHistory = (id) => {
    setIdStudent(id);
    setShowPaymentHistorialModal(!showPaymentHistorialModal);
  };

  return (
    <Container>
      <PaymentHistorial
        show={showPaymentHistorialModal}
        onHide={() => setShowPaymentHistorialModal(!showPaymentHistorialModal)}
        studentId={idStudent}
      />
      <Row>
        <Col className='bg-light rounded shadow d-flex justify-content-center py-3 mb-3'>
          <Col md='6'>
            <Form.Group>
              <Form.Label>Comisión:</Form.Label>
              <Form.Control
                onChange={handleChange}
                value={selectedClassroom}
                name='classroom'
                as='select'
              >
                {classroomList?.crList.length === 0 ? (
                  <option value=''>Cargando lista...</option>
                ) : (
                  <>
                    <option value=''>Ver Todas</option>
                    {classroomList?.crList.map((classroom, index) => (
                      <option key={index}>{classroom.classroom}</option>
                    ))}
                  </>
                )}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col className='my-auto'>
            <Button
              className={`w-100 mt-3 disabledCursor`}
              variant='info'
              type='submit'
              onClick={handleSearch}
              disabled={isLoading}
            >
              <FontAwesomeIcon icon={faSearch} /> Filtrar
            </Button>
          </Col>
        </Col>
      </Row>
      <Row className='text-center'>
        {debtorsList && !isLoading && (
          <>
            <Col>
              <h5>{`Total: ${debtorsList?.totalDebtors}`}</h5>
            </Col>
            <Col>
              {debtorsList && !isLoading && (
                <span className='mt-2'>
                  Porcentaje de deudores:
                  {debtorsList?.debtorsPercent.toFixed(2)}%
                </span>
              )}
            </Col>
          </>
        )}
      </Row>
      <div className='bg-light rounded p-3 mb-3'>
        {isLoading ? (
          <Col className=' col-12 text-center'>
            <Spinner animation='grow' />
          </Col>
        ) : debtorsByClassroom?.length === 0 ? (
          <div className='text-center'>
            <Alert key='selectClassroom' variant='warning'>
              No hay deudores para mostrar en esta comisión
            </Alert>
          </div>
        ) : !isLoading && !debtorsByClassroom ? (
          <div className='text-center'>
            <Alert key='selectClassroom' variant='primary'>
              No hay alumnos para mostrar
            </Alert>
          </div>
        ) : (
          debtorsByClassroom && (
            <Table striped bordered hover size='sm'>
              <thead>
                <tr>
                  <th>Alumno</th>
                  <th>Cuotas</th>
                  <th>Estado de la deuda</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {debtorsByClassroom?.map((debtor, debtorIndex) => {
                  return (
                    <>
                      <tr key={`debtorName-${debtorIndex}`} className='mb-3'>
                        <td>
                          <span>{debtor.name}</span>{' '}
                          <span>{debtor.lastname}</span>
                          <small>
                            {debtorsList.notSuspended.some(
                              (item) => item.email === debtor.email
                            ) ? null : (
                              <p className='text-danger'>Suspendido</p>
                            )}
                          </small>
                        </td>
                        <td>
                          {debtorsByClassroom?.map((detail, detailIndex) => {
                            if (debtorIndex === detailIndex) {
                              return (
                                <span>
                                  {
                                    detail.debtStatus.installments[0]
                                      .monthlyInstallment[0]
                                  }{' '}
                                  / {detail.debtStatus.plan.priceSchema.length}
                                </span>
                              );
                            }
                          })}
                        </td>
                        <td>
                          <span>
                            Total pagado actualmente:
                            <span>
                              ${debtor.debtStatus.totalAmountPaidSoFar}
                            </span>
                          </span>
                          <br />
                          <span>
                            Total esperado:
                            <span className='text-danger'>
                              ${debtor.debtStatus.expectedPaymentToCurrentDate}
                            </span>
                          </span>
                          <span>
                            <br />
                            Deuda acumulada:
                            <span className='text-danger'>
                              $
                              {debtor.debtStatus.expectedPaymentToCurrentDate -
                                debtor.debtStatus.totalAmountPaidSoFar >
                              0
                                ? (debtor.debtStatus
                                    .expectedPaymentToCurrentDate -
                                    debtor.debtStatus.totalAmountPaidSoFar) *
                                    0.07 +
                                  debtor.debtStatus
                                    .expectedPaymentToCurrentDate -
                                  debtor.debtStatus.totalAmountPaidSoFar
                                : 0}
                            </span>
                          </span>
                        </td>
                        <td>
                          <div className='d-flex flex-column align-items-center justify-content-center mh-100'>
                            <OverlayTrigger
                              placement='left'
                              overlay={
                                <Tooltip id='tosoltip'>
                                  <strong>Histrial de pagos</strong>
                                </Tooltip>
                              }
                            >
                              <Button
                                size='sm'
                                className='mb-2'
                                onClick={() => {
                                  openModalPaymentHistory(debtor?._id);
                                }}
                              >
                                <FontAwesomeIcon icon={faSearch} />
                              </Button>
                            </OverlayTrigger>
                            <StudentCommissions
                              studentId={debtor._id}
                              isActive={null}
                              debtorsList={debtorsList}
                              setDebtorsList={setDebtorsList}
                              setStatusCommisionChangeFlag={
                                setStatusCommisionChangeFlag
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          )
        )}
      </div>
    </Container>
  );
};

export default DebtCollection;
