import React, { useState, useContext } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import { notify } from 'react-notify-toast';
import { ClassroomList } from '../../Context/ClassroomListContext';

export default function AddComission({ show, onHide, updateCommission }) {
  const [comissionName, setComissionName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const { updateClassRoomList } = useContext(ClassroomList);
  const onSubmit = (e) => {
    e.preventDefault();
    setIsSaving(!isSaving);

    let emptyComission = {
      classroom: comissionName,
      mentor: null,
      students: [],
      course: '',
      timeFrom: '',
      timeTo: '',
      isActive: 'active',
      moduleCheckout: true,
    };

    axios
      .post(`/api/classroom/post`, emptyComission, {
        headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
      })
      .then(({ data }) => {
        if (data.success) {
          notify.show('La comisión se agregó correctamente', 'success');
          setIsSaving(!isSaving);
          setComissionName('');
          updateClassRoomList();
          onHide();
          updateCommission();
        } else {
          notify.show(data.message, 'error');
          setIsSaving(!isSaving);
        }
      })
      .catch((err) => {
        notify.show('Error al agregar la comisión', 'error');
      });
  };

  const handleChange = (e) => {
    let { value } = e.target;
    setComissionName(value.trim());
  };

  return (
    <Modal centered show={show} onHide={onHide}>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Row className='pt-3'>
            <Form.Label>Ingrese el nombre de la comisión</Form.Label>
            <Form.Control
              type='text'
              name='comissionName'
              onChange={handleChange}
            />
          </Form.Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={onHide}>
            Cerrar
          </Button>
          <Button disabled={isSaving} variant='info' type='submit'>
            Guardar cambios
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
