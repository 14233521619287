import React, { useState, useContext } from 'react';
import { Button, Spinner, Modal } from 'react-bootstrap';
import { notify } from 'react-notify-toast';
import clientAxios from '../../clients/clientAxios';

export default function CampusEnrollment({ show, onHide, updateCommission }) {
  const [failedEnrollStudents, setFailedEnrollStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const CAMPUS_USER_ROLL_STUDENT_ID = 5;
  const bulkEnrollment = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setFailedEnrollStudents([]);
      const commissionData = {
        roleid: CAMPUS_USER_ROLL_STUDENT_ID,
        courseId: updateCommission._id,
        classroom: updateCommission.classroom,
      };

      const { data } = await clientAxios.post(
        `/campus/enrollment`,
        commissionData
      );
      setIsLoading(false);
      if (data.success) {
        notify.show('Se dieron de alta alumnos en la comision', 'success');
        setFailedEnrollStudents(data.failedToEnroll);
        if (!data?.failedToEnroll || data?.failedToEnroll?.length === 0) {
          onHide();
        }
      } else {
        notify.show(
          'No se pudo completar la matriculacion de estudiantes',
          'error'
        );
      }
    } catch {
      notify.show(
        'No se encontró la comisión en el campus virtual, por favor verifique el nombre declarado en el mismo',
        'error'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Body>
        <Modal.Header closeButton>
          <Modal.Title>
            Dar alta alumnos en comision {updateCommission.classroom}
          </Modal.Title>
        </Modal.Header>
        <div class='col text-center'>
          <Button
            variant='primary'
            className='text-center'
            disabled={isLoading}
            onClick={bulkEnrollment}
          >
            Alta Masiva
          </Button>
        </div>
        <small>
          {isLoading && (
            <Spinner animation='border' size='sm' variant='dark' role='status'>
              <span className='sr-only'>Cargando...</span>
            </Spinner>
          )}
          {failedEnrollStudents?.length > 0 && (
            <small style={{ marginTop: '5px', marginBottom: '10px' }}>
              No fue posible inscribir los siguientes alumnos, puede intentar de
              nuevo en unos minutos o proceder a realizar una inscripción
              manual:
            </small>
          )}
          {failedEnrollStudents?.map((studentEmail) => {
            return <p className='text-danger'>{studentEmail}</p>;
          })}
        </small>
      </Modal.Body>

      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
