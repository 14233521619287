import React, { useContext, useState, useEffect } from "react";
import { Modal, Row, Col, Button, Form, InputGroup, Container } from "react-bootstrap";
import { notify } from "react-notify-toast";
import axios from "axios";

export default function EditTeacher({
  show,
  onHide,
  setValidated,
  modalState
}: {
  show: boolean;
  onHide: () => void;
  setValidated: (data: boolean) => void;
  modalState: {
    id: string,
    delete: boolean,
    edit: boolean,
    editPass: boolean,
    name: string,
    email: string,
    isActive: boolean
  };
}) {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isActive, setIsActive] = useState("true");

  useEffect(() => {
    setName(modalState.name);
    setEmail(modalState.email);
    setIsActive(modalState.isActive === true ? "true" : "false");
    //eslint-disable-next-line
  }, [modalState])

  const updateMentor = () => {
    if (name && email && isActive) {
      axios.put(`api/user/updateMentor/${modalState.id}`, {
        name,
        email,
        isActive: isActive === "true" ? true : false
      }, { headers: { "auth-token": localStorage.getItem("JWT") || " "} })
        .then(({ data }) => {
          notify.show(data.message, "success");
          setValidated(true);
          onHide()
        })
        .catch((err) => {
          console.error(err.message)
          notify.show("No se pudo editar el mentor", "error");
        });
    } else {
      notify.show("Todos los campos son obligatorios", "error");
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Editar mentor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <InputGroup className="m-0 p-0 pb-1">
              <InputGroup.Prepend>
                <InputGroup.Text>Nombre</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                name="name"
                value={name}
                onChange={(e) => { setName(e.target.value) }}
                required
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup className="m-0 p-0 pb-1">
              <InputGroup.Prepend>
                <InputGroup.Text>Email</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                name="email"
                value={email}
                onChange={(e) => { setEmail(e.target.value) }}
                required
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup className="m-0 p-0 pb-1">
              <InputGroup.Prepend>
                <InputGroup.Text>Activo/Baja</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                name="isActive"
                value={isActive}
                onChange={(e) => { setIsActive(e.target.value) }}
                required
              >
                <option value="true">Activo</option>
                <option value="false">Baja</option>
              </Form.Control>
            </InputGroup>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="success" onClick={updateMentor}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
