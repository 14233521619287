import React, { useEffect, useState } from 'react';
import {
  Col,
  Container,
  Form,
  ListGroup,
  Row,
  Button,
  Modal,
  Spinner,
  Tabs,
  Tab,
} from 'react-bootstrap';
import clientAxios from '../../clients/clientAxios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { notify } from 'react-notify-toast';
import ViewMPLinks from './ViewMPLinks';

const CreateLinkPayment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [suggestionsVisible, setSuggestionsVisible] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [preferenceLink, setPreferenceLink] = useState('');
  const [dataToGenerateLink, setDataToGenerateLink] = useState({
    description: '',
    email: '',
    quantity: 1,
    externalRef: 'RCPLATFORM',
    amountToPay: '',
    studentId: '',
  });
  const TABS = {
    CREATE_LINK:"createLink",
    VIEW_LINKS:"viewLinks",
  }
  const [activeTab, setActiveTab] = useState(TABS.CREATE_LINK);
 
  const handleCreate = async (event) => {
    event.preventDefault();
    if (!dataToGenerateLink.email) {
      notify.show('Por favor, completa el campo de Buscar alumno', 'error');
      return;
    }
    if (!dataToGenerateLink.amountToPay) {
      notify.show('Por favor, completa el monto', 'error');
      return;
    }
    setShowModal(true);
    setIsLoading(true);
    try {
      const { data } = await clientAxios.post(
        'bills/mp-link',
        dataToGenerateLink
      );
      setIsLoading(false);
      setPreferenceLink(data.response.init_point);
    } catch (error) {
      notify.show(error?.response?.data, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    setSearchValue(event?.target?.value);
    if (event?.target?.value.length >= 2) {
      setSuggestionsVisible(true);
    } else {
      setSuggestionsVisible(false);
      setSuggestions([]);
    }
  };

  const searchStudent = async () => {
    try {
      setIsLoading(true);
      const { data } = await clientAxios(`/students/search/${searchValue}`);
      setSuggestions(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchValue.length >= 2) {
      searchStudent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const handleSelectOption = (selectedOption) => {
    setSearchValue(`${selectedOption.name} ${selectedOption.surname}`);
    setDataToGenerateLink((prev) => {
      return {
        ...prev,
        email: selectedOption.email,
        studentId: selectedOption._id
      };
    });
    setSuggestionsVisible(false);
  };

  const handleSelectTypeLink = (e) => {
    const externalRef = e.target.value;
    setDataToGenerateLink((prev) => {
      return {
        ...prev,
        externalRef,
      };
    });
  };

  const handleChangeAmount = (e) => {
    const amountToPay = e.target.value;
    const ONLY_POSITIVE_NUMBERS=/^\d*\.?\d*$/
    if (ONLY_POSITIVE_NUMBERS.test(amountToPay)) {
      setDataToGenerateLink((prev) => {
        return {
          ...prev,
          amountToPay,
        };
      });
    } else {
     notify.show('Por favor ingrese un monto válido', 'error');
    }
  };

  const handleDescriptionChange = (e) => {
    const description = e.target.value;
    setDataToGenerateLink((prev) => {
      return {
        ...prev,
        description,
      };
    });
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(preferenceLink);
      notify.show('Se ha copiado el link al porta papeles', 'success');
    } catch (error) {
      notify.show(error, 'error');
    }
  };

  return (
    <Container>
      <Tabs defaultActiveKey="createLink" id="createAndShowLinks" onSelect={setActiveTab}>
        <Tab eventKey="createLink" title="Crear Link">
          <Form className='mt-4'>
            <Form.Group controlId='selectLinkType'>
              <Form.Label>Seleccione el tipo de Link</Form.Label>
              <Form.Control
                as='select'
                placeholder='Seleccione un tipo de link...'
                value={dataToGenerateLink?.externalRef}
                onChange={handleSelectTypeLink}
              >
                <option value='RCPLATFORM'>Cuota</option>
                <option value='RCPLATFORM_ON_DEMAND'>Cursos on demand</option>
                <option value='RCPLATFORM_NEW'>Suscripción a cursos en vivo</option>
                <option value='RCPLATFORM_MERCH'>Merchandising</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId='searchStudent'>
              <Form.Label>Buscar alumno</Form.Label>
              <Form.Control
                type='text'
                placeholder='Buscar alumno...'
                value={searchValue}
                onChange={handleChange}
              />
              {suggestionsVisible && (
                <ListGroup>
                  {suggestions.map((option, index) => (
                    <ListGroup.Item
                      key={index}
                      onClick={() => handleSelectOption(option)}
                    >
                      {`${option?.name} ${option?.surname}`}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label controlId='description'>
              Monto
              </Form.Label>
              <Form.Control
                type='text'
                placeholder='Ingrese el monto'
                onChange={handleChangeAmount}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label controlId='description'>
                Descripción (opcional)
              </Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                onChange={handleDescriptionChange}
              />
            </Form.Group>
            <Row className='mt-4 mb-4'>
              <Col lg={2}></Col>
              <Col lg={8}>
                <Button
                  variant='primary'
                  type='submit'
                  onClick={handleCreate}
                  block
                >
                  Crear
                </Button>
              </Col>
              <Col lg={2}></Col>
            </Row>
          </Form>
        </Tab>
        <Tab eventKey="viewLinks" title="Ver links anteriores">
          <ViewMPLinks active={activeTab ===TABS.VIEW_LINKS}/>
      </Tab>
    </Tabs>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Link de Pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div className='text-center'>
              <Spinner className='text-center' animation='border' />
            </div>
          ) : (
            <Form.Group>
              <Form.Label>Link de Pago</Form.Label>
              <div className='d-flex'>
                <Form.Control
                  type='text'
                  readOnly
                  value={preferenceLink}
                />
                <Button
                  size='sm'
                  className={`btn btn-success mx-3 px-3`}
                  type='button'
                  onClick={copyToClipboard}
                >
                  <FontAwesomeIcon icon={faClipboard} />
                </Button>
              </div>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default CreateLinkPayment;
