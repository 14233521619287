// Importando librerías externas
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { notify } from 'react-notify-toast';
import { useHistory } from 'react-router-dom';

// Importando info
import AddComission from './AddComission';
import ConfirmDelete from './ConfirmDelete';
import Selector from './Selector';
import List from './List';
import Info from './Info';
import CopyMails from './CopyMails';

export default function Commission() {
  const history = useHistory();

  const [showAddCommission, setShowAddCommission] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showCopyMails, setShowCopyMails] = useState(false);
  const [activeDownFilter, setActiveDownFilter] = useState('active');
  const [isLoading, setIsLoading] = useState({
    component: true,
    singleCommission: false,
  });
  const [mentorList, setMentorList] = useState([]);
  const [selectedCommission, setSelectedCommission] = useState('');
  const [commission, setCommission] = useState({});
  const [crList, setCrList] = useState([]);
  const [crListLoading, setCrListLoading] = useState(true);

  const headerConfig = {
    headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
  };

  let counter = 0;

  useEffect(() => {
    if (counter === 0) {
      ++counter;
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    bringData();
    //eslint-disable-next-line
  }, [activeDownFilter]);

  useEffect(() => {
    setCrListLoading(false);
  }, [crList]);

  const getAllClassrooms = () => {
    let classroom = axios.get(
      'api/classroom/search/',
      { params: { isActive: activeDownFilter },
        headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
      },
    );

    axios
      .all([classroom])
      .then(
        axios.spread((...response) => {
          if (response.length > 0 && response[0]?.data) {
            setIsLoading({ ...isLoading, component: false });
            setCrList(
              response[0].data.filter((i) => i.classroom !== undefined)
            );
          } else {
            notify?.show(response.message, 'error');
            history.push('/');
          }
        })
      )
      .catch((err) => {
        notify.show(err?.response?.data?.message, 'error');
        history.push('/');
      });
  };

  const bringData = () => {
    let mentors = axios.get(`api/user/getMentorList`, headerConfig);

    axios
      .all([mentors])
      .then(
        axios.spread((...response) => {
          if (response.length > 0 && response[0]?.data?.success) {
            setMentorList(response[0].data?.data);
            setIsLoading({ ...isLoading, component: false });
          } else {
            notify?.show(response.message, 'error');
            history.push('/');
          }
        })
      )
      .catch((err) => {
        notify.show(err?.response?.data?.message, 'error');
        history.push('/');
      });
  };

  // Manejo del cambio de comisión (recibe de Selector)
  const handleCommissionChange = (obj) => {
    if (!obj) {
      getStudentsInClassroom(selectedCommission);
    } else {
      let { value } = obj.target;
      setSelectedCommission(value);
      setIsLoading({ ...isLoading, singleCommission: true });

      getStudentsInClassroom(value);
    }
  };

  //Setea todos los valores de la comisión seleccionada
  const getStudentsInClassroom = (commissionId) => {
    axios
      .get(`api/classroom/getStudentsInClassroom/${commissionId}`, headerConfig)
      .then(({ data }) => {
        setIsLoading({ ...isLoading, singleCommission: false });
        setCommission({
          ...data.data[0],
          mentor: data.data[0].mentor,
          helper: data.data[0].helper,
          timeFrom: data.data[0].timeFrom,
          timeTo: data.data[0].timeTo,
          course: data.data[0].course,
          schedule: data.data[0].schedule,
          isActive: data.data[0].isActive ? data.data[0].isActive : '',
          students: data.data[0].students,
          courseSummary: data.data[0].courseSummary,
          slackChannel: data.data[0].slackChannel
            ? data.data[0].slackChannel
            : '',
          published: data.data[0]?.published,
          initialDate: data.data[0]?.initialDate || '',
        });
      })
      .catch((err) => {
        notify.show(err.response.data.message, 'error');
        history.push('/');
      });
  };
  return (
    <React.Fragment>
      <Container>
        <CopyMails
          show={showCopyMails}
          onHide={() => setShowCopyMails(!showCopyMails)}
          commission={commission.classroom}
          students={commission.students}
        />
        <AddComission
          show={showAddCommission}
          onHide={() => setShowAddCommission(!showAddCommission)}
          updateCommission={bringData}
        />
        <ConfirmDelete
          show={showConfirmDelete}
          onHide={() => setShowConfirmDelete(!showConfirmDelete)}
          idToDelete={commission._id}
          updateCommission={bringData}
        />
        <Selector
          commissionSelected={(obj) => handleCommissionChange(obj)}
          commission={(commission && commission?.length > 0) ? commission : { students: false }}
          confirmDelete={() => setShowConfirmDelete(!showConfirmDelete)}
          addComission={() => setShowAddCommission(!showAddCommission)}
          isLoading={isLoading}
          crList={crList}
          copyMails={() => setShowCopyMails(!showCopyMails)}
          activeDownFilter={activeDownFilter}
          setActiveDownFilter={(value) => setActiveDownFilter(value)}
          crListLoading={crListLoading}
          setCrListLoading={(value) => setCrListLoading(value)}
          setCommission={(value) => setCommission(value)}
          getAllClassrooms={getAllClassrooms}
        />
        <Row>
          <List commission={commission} />
          <Col />
          <Info
            mentorList={mentorList}
            commission={commission}
            setCommission={setCommission}
          />
        </Row>
      </Container>
    </React.Fragment>
  );
}
