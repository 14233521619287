import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function Page404() {
  return (
    <Container className="bg-light rounded shadow p-3 align-items-center">
      <Row className="p-3 justify-content-center mb-5">
        <Col>
          <Row>
            <Col />
            <Col>
              <i className="fas fa-ban d-flex justify-content-center" style={{ color: 'red', fontSize: '5rem' }}></i>
              <h1 className="d-flex justify-content-center">Error 404</h1>
              <p className="d-flex justify-content-center">La página que estás buscando no existe.</p>
            </Col>
            <Col />
          </Row>
        </Col>
      </Row>
    </Container>
  );
} 