import React, { Component } from 'react';
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Popover,
  OverlayTrigger,
  Spinner,
} from 'react-bootstrap';
import { ClassroomList } from '../../Context/ClassroomListContext';
import { withRouter } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import moment from 'moment';
import 'moment/locale/es';
import Axios from 'axios';
moment.locale('es');

export default withRouter(
  class EditBillStudent extends Component {
    static contextType = ClassroomList;

    constructor(props) {
      super(props);

      this.state = {
        fullStudentsList: [],
        selectedStudent: [],
        loading: true,
        studentId: '',
        nameDisplay: '', // New formula de nido
        student: '', // Old formula de sancor bebe 3
        date: '',
        payment: '',
        additionalNotes: '',
        prepay: false,
        monthlyInstallment: '',
        partial: false,
        paymentValidForNextMonth: false,
        installmentQuantity: '',
        createdBy: '',
        course: '',
        classroom: '',
        amountNextPayment: '',
        currency: '',

        selectedOption: '',
        classroomCopy: '',
        installmentsCopy: '',

        // Manejo de precio

        amount: 0,
        ogAmount: 0, // Monto original, ingresado en el input de precio a pagar, fuente de toda verdad.
        customAmount: 0, // Monto ingresado manualmente por el usuario, reemplaza a ogAmount en caso de ser desigual a 0
        percent: '', // Porcentaje Agregado
        prePercentAmount: 0, // Copia pre porcentaje.

        // Múltiples cuotas

        multiplePayments: false,
        multiPay: [
          { cuota: 1, checked: false },
          { cuota: 2, checked: false },
          { cuota: 3, checked: false },
          { cuota: 4, checked: false },
          { cuota: 5, checked: false },
          { cuota: 6, checked: false },
          { cuota: 7, checked: false },
          { cuota: 8, checked: false },
          { cuota: 9, checked: false },
          { cuota: 10, checked: false },
          { cuota: 11, checked: false },
          { cuota: 12, checked: false },
        ],
      };
    }
    // Cargar un alumno al montar el componente
    componentDidMount() {
      Axios.get(`/api/students/${this.props.studentId}`, {
        headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
      })
        .then(({ data: student }) =>
          this.setState({
            fullStudentsList: [student.response],
            selectedStudent: student._id,
          })
        )
        .catch((error) => {
          notify.show(error.message, 'error');
          this.props.history.push('/students/viewAll');
        });
    }

    // Verifica y carga los datos de las boletas elegidas
    componentDidUpdate(prevProps) {
      if (prevProps !== this.props) {
        if (this.props.id && this.props.show) {
          Axios.get(`/api/bills/get/${this.props.id}`, {
            headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
          })
            .then(({ data }) =>
              this.setState({
                ...data,
                multiplePayments: data.monthlyInstallment.length >= 3,
                monthlyInstallment:
                  data.monthlyInstallment.length >= 3
                    ? data.monthlyInstallment.split(',')
                    : data.monthlyInstallment,
                prePercentAmount: data.ogAmount,
                customAmount: data.ogAmount,
                selectedStudent: this.state.fullStudentsList.filter(
                  data.studentId
                    ? (i) => i._id === data.studentId
                    : (i) =>
                        i.name.toLowerCase() + ' ' + i.surname.toLowerCase() ===
                        data.student.toLowerCase()
                ),
                loading: false,
                fullPaid: data?.fullPaid,
              })
            )
            .then(() => {
              let checkedBoxes = this.state.multiPay;

              if (typeof this.state.monthlyInstallment === 'object') {
                this.state.monthlyInstallment.map(
                  (a) => (checkedBoxes[a - 1].checked = true)
                );
              }

              this.setState((i) => ({
                multiPay: checkedBoxes,
                nameDisplay:
                  i.selectedStudent.length > 0
                  ? i.selectedStudent[0].name + ' ' + i.selectedStudent[0].surname
                  : '',
              }));
            });
        }
      }
    }

    // Guardar datos, verificando id y almacenando en esa misma id todos los datos.

    onSubmit = (e) => {
      e.preventDefault();

      Axios.put(
        `/api/bills/put/${this.props.id}`,
        {
          student: null,
          studentId: this.state.selectedStudent[0]._id,
          nameDisplay: this.state.nameDisplay,
          email: this.state.selectedStudent[0].email,
          amount: this.state.amount,
          ogAmount: this.state.ogAmount,
          payment: this.state.payment,
          additionalNotes: this.state.additionalNotes,
          percent: this.state.percent,
          prepay: this.state.prepay,
          monthlyInstallment: this.state.multiplePayments
            ? this.state.multiPay
                .filter((i) => i.checked === true)
                .map((i) => i.cuota)
                .toString()
            : this.state.monthlyInstallment,
          partial: this.state.partial,
          paymentValidForNextMonth: this.state.paymentValidForNextMonth,
          installmentQuantity: this.state.installmentQuantity,
          course: this.state.course,
          classroom: this.state.classroom,
          currency: this.state.currency,
          amountNextPayment: this.state.amountNextPayment,
          date: this.state.date,
          fullPaid: this.state?.fullPaid,
        },
        {
          headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
        }
      ).then(() => {
        this.toggleMultiplePayments();
        this.props.onHide();
        this.props.refresh();
      });
    };
    // Se encarga de la modificación de todos los
    // datos excepto de algunos específicos

    handleChange = (i) => {
      let { value, name } = i.target;

      if (name === 'nameDisplay') {
        this.setState({
          nameDisplay: value,
          selectedStudent: this.state.fullStudentsList.filter(
            value ? (i) => i.name + ' ' + i.surname === value : (i) => i
          ),
        });
      }

      this.setState({ [name]: value });
    };

    // Se encarga de la selección de cursos

    handleMultipleCourseBill = (obj) => {
      let { value } = obj.target,
        index = obj.target.selectedIndex;

      this.setState((i) => ({
        course: value,
        classroom: i.selectedStudent[0].classroom[index],
        amount: i.selectedStudent[0].price[index],
        ogAmount: i.selectedStudent[0].price[index],
        prePercentAmount: i.selectedStudent[0].price[index],
        installmentQuantity: i.selectedStudent[0].monthlyInstallments[index],
      }));
    };

    // Manejo de montos y porcentajes

    handlePrice = (obj) => {
      let { value } = obj.target;

      this.setState({
        amount: parseFloat(value),
        prePercentAmount: parseFloat(value),
        customAmount: parseFloat(value),
        percent: 0,
      });
    };

    handlePercent = (obj) => {
      let { prePercentAmount } = this.state,
        { value } = obj.target;

      if (value === 0) {
        this.setState((i) => ({
          amount: i.prePercentAmount,
          percent: 0,
        }));
      } else {
        let percent = parseFloat((prePercentAmount * value) / 100),
          result = parseFloat(percent) + parseFloat(prePercentAmount),
          roundedResult = Math.ceil(result / 5) * 5;

        this.setState({
          amount: roundedResult,
          percent: value,
        });
      }
    };

    // Manejo de las cuotas múltiples

    handleMultipleInstallments = async (e) => {
      let { id } = e.target,
        array = this.state.multiPay;

      array[id - 1].checked = !array[id - 1].checked;

      let bill = array.filter((i) => i.checked === true),
        multipliedPrice =
          bill.length >= 1
            ? this.state.customAmount * bill.length
            : this.state.customAmount;

      this.setState(() => ({
        multiplePayments: true,
        multiPay: array,
        additionalNotes: `Se abonan las boletas: ${bill.map((i) => i.cuota)}`,
        amount: multipliedPrice,
        prePercentAmount: multipliedPrice,
      }));
    };

    // Switch entre cuotas simples y múltiples

    toggleMultiplePayments = (fadeOut) => {
      this.setState((prevState) => ({
        additionalNotes: '',
        percent: 0,
        amount: prevState.ogAmount,
        customAmount: prevState.amount,
        multiplePayments:
          fadeOut === 'off' ? false : !prevState.multiplePayments,
        multiPay: [
          {
            cuota: 1,
            checked: false,
          },
          {
            cuota: 2,
            checked: false,
          },
          {
            cuota: 3,
            checked: false,
          },
          {
            cuota: 4,
            checked: false,
          },
          {
            cuota: 5,
            checked: false,
          },
          {
            cuota: 6,
            checked: false,
          },
          {
            cuota: 7,
            checked: false,
          },
          {
            cuota: 8,
            checked: false,
          },
          {
            cuota: 9,
            checked: false,
          },
          {
            cuota: 10,
            checked: false,
          },
          {
            cuota: 11,
            checked: false,
          },
          {
            cuota: 12,
            checked: false,
          },
        ],
      }));
    };

    render() {
      // Conteo de cuotas según el curso realizado.

      let installments = [];

      for (let i = 1; i <= this.state.installmentQuantity; i++)
        installments.push(`Cuota ${i}`);

      // Chequea qué usuario está seleccionado e imprimir
      // los cursos que tiene disponible ese usuario

      let coursesForSelect = this.state.nameDisplay && this.state.selectedStudent.length > 0
      ? this.state.selectedStudent[0].course
      : [];

      // Traer la lista de comisiones
      const crList = this.context.crList;

      // Interfaz de múltiples cuotas
      const handleFullPaid = ({ target: { checked } }) => {
        this.setState({
          fullPaid: checked,
        });
      };

      const popover = (
        <Popover id='multiple-installments'>
          <Popover.Title>
            <Form.Check
              onChange={this.toggleMultiplePayments}
              type='checkbox'
              checked={this.state.multiplePayments}
              label='Habilitar múltiples cuotas'
            />
          </Popover.Title>
          <Popover.Content>
            <Form.Group>
              {this.state.multiPay.map((d, i) => (
                <Form.Check
                  key={i}
                  onChange={this.handleMultipleInstallments}
                  type='checkbox'
                  id={d.cuota}
                  checked={d.checked}
                  label={`Cuota ${i + 1}`}
                />
              ))}
            </Form.Group>
          </Popover.Content>
        </Popover>
      );

      var b = !this.props.show ? 'none' : 'block';

      // Renderizar todo
      return (
        <Container className='border-info my-5' style={{ display: b }}>
          {this.state.loading ? (
            <Row className='container d-flex bg-light rounded shadow p-3 align-items-center ml-0 mr-0'>
              <Col className='d-flex justify-content-center align-items-center'>
                <Spinner
                  animation='border'
                  role='status'
                  size='md'
                  className='ml-3 mr-3 my-auto'
                  variant='info'
                />
                <p className='m-0 p-0'>Cargando...</p>
              </Col>
            </Row>
          ) : (
            <Form onSubmit={this.onSubmit}>
              <Row>
                <h2>Editar boleta</h2>
                <OverlayTrigger
                  trigger='click'
                  placement='bottom'
                  overlay={popover}
                >
                  <Button size='sm' className='ml-auto' variant='info'>
                    Múltiples cuotas
                  </Button>
                </OverlayTrigger>
              </Row>
              <Row className='px-4'>
                <Form.Group as={Col}>
                  <Form.Label>Alumno</Form.Label>
                  <Form.Control
                    onChange={this.handleChange}
                    value={this.state.nameDisplay}
                    name='nameDisplay'
                    as='select'
                  >
                    {this.state.fullStudentsList.map((i) => (
                      <option
                        key={i._id}
                        value={i.name + ' ' + i.surname}
                      >{`${i.name} ${i.surname}`}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Comisión</Form.Label>
                  <Form.Control
                    onChange={this.handleChange}
                    value={this.state.classroom}
                    name='classroom'
                    as='select'
                  >
                    <option value=''>Seleccione una opcion</option>
                    {crList.map((i) => (
                      <option key={i.classroom}>{i.classroom}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Curso</Form.Label>
                  <Form.Control
                    onChange={this.handleMultipleCourseBill}
                    value={this.state.course}
                    name='course'
                    as='select'
                  >
                    {typeof coursesForSelect === 'object' ? (
                      coursesForSelect.map((a, i) => (
                        <option key={i} data-index={i} value={a}>
                          {a}
                        </option>
                      ))
                    ) : (
                      <option>{coursesForSelect}</option>
                    )}
                  </Form.Control>
                </Form.Group>
              </Row>
              <Row className='px-2'>
                <Form.Group as={Col}>
                  <Form.Group as={Col}>
                    <Form.Label>Fecha</Form.Label>
                    <Form.Control
                      type='date'
                      onChange={this.handleChange}
                      name='date'
                      value={moment(this.state.date).format('YYYY-MM-DD')}
                    />
                  </Form.Group>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Cuota</Form.Label>
                  <Form.Control
                    disabled={this.state.multiplePayments}
                    onChange={this.handleChange}
                    value={this.state.monthlyInstallment}
                    name='monthlyInstallment'
                    as='select'
                    className='w-75'
                  >
                    {installments.map((a, i) => (
                      <option key={i} value={i + 1}>
                        {a}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Row>
                  <Row className='px-4'>
                    <Form.Group as={Col} className='px-4'>
                      <Form.Label>
                        Monto
                        {this.state.ogAmount !== this.state.amount && (
                          <small
                            className='text-danger'
                            style={{ cursor: 'pointer', fontStyle: 'italic' }}
                            onClick={() => {
                              this.setState((prevState) => ({
                                additionalNotes: '',
                                multiPay: [
                                  {
                                    cuota: 1,
                                    checked: false,
                                  },
                                  {
                                    cuota: 2,
                                    checked: false,
                                  },
                                  {
                                    cuota: 3,
                                    checked: false,
                                  },
                                  {
                                    cuota: 4,
                                    checked: false,
                                  },
                                  {
                                    cuota: 5,
                                    checked: false,
                                  },
                                  {
                                    cuota: 6,
                                    checked: false,
                                  },
                                  {
                                    cuota: 7,
                                    checked: false,
                                  },
                                  {
                                    cuota: 8,
                                    checked: false,
                                  },
                                  {
                                    cuota: 9,
                                    checked: false,
                                  },
                                  {
                                    cuota: 10,
                                    checked: false,
                                  },
                                  {
                                    cuota: 11,
                                    checked: false,
                                  },
                                  {
                                    cuota: 12,
                                    checked: false,
                                  },
                                ],
                                customAmount: prevState.ogAmount,
                                percent: 0,
                                prePercentAmount: prevState.ogAmount,
                                amount: prevState.ogAmount,
                              }));
                            }}
                          >
                            (Reset)
                          </small>
                        )}
                      </Form.Label>
                      <Form.Control
                        type='number'
                        name='amount'
                        value={this.state.amount}
                        onChange={this.handlePrice}
                      />
                    </Form.Group>
                  </Row>
                </Row>
                <Row className='px-4'>
                  <Form.Group as={Col}>
                    <Form.Label>Tipo de moneda</Form.Label>

                    <Form.Control
                      as='select'
                      name='currency'
                      onChange={this.handleChange}
                      value={this.state.currency}
                    >
                      <option value='' hidden>
                        Seleccione una moneda...
                      </option>
                      <option data-index={'ARS'} key={'ARS'} value={'ARS'}>
                        ARS
                      </option>
                      <option data-index={'USD'} key={'USD'} value={'USD'}>
                        USD
                      </option>
                      <option data-index={'EUR'} key={'EUR'} value={'EUR'}>
                        EUR
                      </option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Método de pago</Form.Label>
                    <Form.Control
                      onChange={this.handleChange}
                      value={this.state.payment}
                      name='payment'
                      as='select'
                    >
                      <option value=''>Seleccione una opcion</option>
                      <option>Efectivo</option>
                      <option>Banco</option>
                      <option>Tarjetas</option>
                      <option>MercadoPago</option>
                      <option>Payway</option>
                      <option>Otro</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Porcentaje</Form.Label>
                    <Form.Control
                      onChange={this.handlePercent}
                      value={this.state.percent}
                      name='percent'
                      as='select'
                    >
                      <option value='0'>0</option>
                      <option value='7'>+7%</option>
                      <option value='12'>+12%</option>
                      <option value='21'>+21%</option>
                      <option value='-7'>-7%</option>
                      <option value='-12'>-12%</option>
                      <option value='-21'>-21%</option>
                    </Form.Control>
                  </Form.Group>
                </Row>
                <Row className='px-4'>
                  <Form.Group as={Col}>
                    <Form.Label>Observaciones</Form.Label>
                    <Form.Control
                      onChange={this.handleChange}
                      value={this.state.additionalNotes}
                      name='additionalNotes'
                      type='text'
                    />
                  </Form.Group>
                </Row>
                <Row className='px-4'>
                  <Form.Group as={Col}>
                    <Form.Label>Monto proximo pago</Form.Label>
                    <Form.Control
                      onChange={this.handleChange}
                      value={this.state.amountNextPayment}
                      name='amountNextPayment'
                      type='number'
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    {this.state.monthlyInstallment === '1' && (
                      <Form.Check
                        className='my-auto'
                        checked={this.state.paymentValidForNextMonth}
                        name='paymentValidForNextMonth'
                        onChange={() =>
                          this.setState((i) => ({
                            paymentValidForNextMonth:
                              !i.paymentValidForNextMonth,
                          }))
                        }
                        type='checkbox'
                        label={`Imputar pago como valido para el proximo mes`}
                      />
                    )}
                    <Form.Check
                      className='my-auto'
                      checked={this.state.fullPaid}
                      name='fullPaid'
                      onChange={handleFullPaid}
                      type='checkbox'
                      label={`Marcar como pago total`}
                    />
                  </Form.Group>
                </Row>
              </Row>
              <Row>
                <Button
                  variant='secondary'
                  onClick={this.props.onHide}
                  className='ml-4'
                >
                  Cancelar
                </Button>
                <Button variant='primary' type='submit' className='ml-4'>
                  Guardar cambios
                </Button>
              </Row>
            </Form>
          )}
        </Container>
      );
    }
  }
);
