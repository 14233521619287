import Axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ToastNotificationType } from "../../Types/ToastNotifications";
import ToastNotification from "./ToastNotification";

export default function InternalNotifications() {
  const [data, setData] = useState<ToastNotificationType[]>([]);

  useEffect(() => {
    Axios.get("/api/notifications/", {
      headers: { "auth-token": localStorage.getItem("JWT") || " "},
    }).then(({ data }) => {
      setData(data);
    });
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        zIndex: 999999,
        top: 100,
        right: 20,
      }}
    >
      {data.map((notification, index) => (
        <ToastNotification key={index} {...notification} />
      ))}
    </div>
  );
}
