import React from "react";
import { Link } from "react-router-dom";

export default function StatisticsNav() {
  return (
    <Link className="nav-link" to="/graphic">
      Estadísticas
    </Link>
  );
}
