import React, { useState, useEffect, useRef } from 'react';
import {
  Col,
  Row,
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Badge,
  Button,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  faEnvelope,
  faPhone,
  faArrowUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notify } from 'react-notify-toast';

export default function ListDisplay({
  studentsList,
  studentCount,
  refreshList,
  lastUpdate,
}) {
  const [copyValue, setCopyValue] = useState('');
  const defaultPhonePrefix = '+54';
  const defaultWspGreetings = 'Buenos dias ';

  useEffect(() => {
    if (copyValue !== '') {
      notify.show('Copiado al portapapeles', 'info');
      copyToClipboard();
    }
    //eslint-disable-next-line
  }, [copyValue]);

  const inputRef = useRef();

  /**
   * Función para copiar valor a clipboard
   */
  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(copyValue);
    } else {
      inputRef.current.select();
      document.execCommand('copy');
    }
  };

  const showStatus = (student) => {
    const isArray = Array.isArray(student?.isActive);
    if (!isArray) {
      return <p className=" d-inline font-weight-bold">Otro ⚫</p>;
    }
    const stedentWithCommision =
      student?.isActive[student?.isActive.length - 1];
    switch (stedentWithCommision) {
      case 'up':
        return <p className=" d-inline font-weight-bold">Activo 🟢</p>;
      case 'down':
        return <p className=" d-inline font-weight-bold">Baja 🔴</p>;
      case 'commissionChanged':
        return (
          <p className=" d-inline font-weight-bold">Cambio de comisión 🟡</p>
        );
      default:
        return <p className=" d-inline font-weight-bold">Otro ⚫</p>;
    }
  };

  return (
    <Col className="p-3 my-3 bg-light rounded shadow">
      <Row className="mb-3">
        <Col>
          <small className="text-muted border rounded border-muted p-2">
            Cantidad de alumnos: {studentCount}
          </small>
        </Col>
        <Col className="d-flex justify-content-end">
          <small className="mr-3 text-muted">{lastUpdate}</small>
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip className="ml-2" id={`tooltip-top`}>
                <small className="m-0 p-0">
                  Click para ver todos los alumnos
                </small>
              </Tooltip>
            }
          >
            <Button onClick={refreshList}>
              <i className="fa fa-list-alt" aria-hidden="true" />
            </Button>
          </OverlayTrigger>
        </Col>
      </Row>
      <Row className="px-3 mb-3">
        <Col>
          <p className="m-0 p-0">Estudiante</p>
        </Col>
        <Col md={3}>
          <p className="m-0 p-0 text-right">Teléfono</p>
        </Col>
        <Col md={4}>
          <p className="m-0 p-0 text-right">E-mail</p>
        </Col>
      </Row>
      <ListGroup>
        {Array.isArray(studentsList) && studentsList.length === 0 && (
          <div className="m-auto text-muted my-5 py-4">
            <small className="m-0 small-0 strong text-center">
              (0) Resultados encontrados para el filtro de busqueda actual.
              Utiliza el filtro para encontrar mas resultados{' '}
              <FontAwesomeIcon icon={faArrowUp} />
            </small>
          </div>
        )}

        {studentsList.map((student, index) => (
          <ListGroup.Item key={index}>
            <Row>
              <Col>
                <Link
                  to={`/students/details/${student._id}`}
                  className="text-decoration-none text-dark"
                >
                  <p className="m-0 p-0 position-relative">
                    {student.name} {student.surname}
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary mx-2">
                      {Array.isArray(student.classroom) &&
                      student.classroom &&
                      student.classroom.length > 1
                        ? student.classroom.length
                        : ''}
                    </span>
                  </p>
                  <div>
                    <p className="m-0 p-0">
                      Ultima comisión :
                      {Array.isArray(student.classroom) &&
                      student.classroom.length === 0 ? (
                        <p className=" d-inline font-weight-bold">
                          {' '}
                          Sin comisión ⚠
                        </p>
                      ) : Array.isArray(student.classroom) ? (
                        student.classroom[student.classroom.length - 1]
                      ) : (
                        'Sin comisión ⚠'
                      )}
                    </p>
                    <p className="m-0 p-0">Estado : {showStatus(student)}</p>
                  </div>
                </Link>
              </Col>
              <Col md={3}>
                <a
                  className="text-decoration-none text-light"
                  target="_blank"
                  href={`https://wa.me/${defaultPhonePrefix}${student.phone}?text=${defaultWspGreetings}${student.name}`}
                  rel="noreferrer"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip className="ml-2" id={`tooltip-top`}>
                        <small className="m-0 p-0">Llamar al teléfono</small>
                      </Tooltip>
                    }
                  >
                    <p className="m-0 p-0 text-right text-dark">
                      {student.phone}{' '}
                      <Badge
                        style={{ cursor: 'pointer' }}
                        className="ml-3"
                        variant="info"
                      >
                        <FontAwesomeIcon icon={faPhone} />
                      </Badge>
                    </p>
                  </OverlayTrigger>
                </a>
              </Col>
              <Col md={4} className="text-right d-flex">
                <textarea
                  rows="1"
                  value={student.email}
                  disabled
                  className=" text-right"
                  style={{
                    width: '100%',
                    border: 'none',
                    background: 'none',
                    color: 'black',
                    resize: 'none',
                  }}
                />
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip className="ml-2 m-0 p-0" id={'tooltip-right'}>
                      <small className="m-0 p-0">
                        Copiar mail al portapapeles
                      </small>
                    </Tooltip>
                  }
                >
                  <div className="align-items-center">
                    <Badge
                      style={{ cursor: 'pointer' }}
                      className="m-0 ml-3 "
                      variant="info"
                      onClick={() => setCopyValue(student.email)}
                    >
                      <FontAwesomeIcon icon={faEnvelope} />
                    </Badge>
                  </div>
                </OverlayTrigger>
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Col>
  );
}
