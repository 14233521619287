import React from "react";
import { Modal, Button } from "react-bootstrap";

interface props {
  show: Boolean;
  onHide: () => void;
  returnHome: () => void;
  print: () => void;
}

export default function AskToPrint({ show, onHide, returnHome, print }: props) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Imprimir</Modal.Title>
      </Modal.Header>
      <Modal.Body>¿Desea imprimir el comprobante?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={returnHome}>
          Cancelar
        </Button>
        <Button variant="success" onClick={print}>
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
