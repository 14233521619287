import React, { useContext, useState, useEffect } from "react";
import { Modal, Row, Col, Button, Form, InputGroup, Container } from "react-bootstrap";
import { notify } from "react-notify-toast";
import axios from "axios";

export default function DeleteTeacher({
  show,
  onHide,
  setValidated,
  modalState,
  setModalState
}: {
  show: boolean;
  onHide: () => void;
  setValidated: (data: boolean) => void;
  modalState: {
    id: string,
    delete: boolean,
    edit: boolean,
    name: string,
    email: string,
    isActive: boolean
  };
  setModalState: (data: object) => void;
}) {

  const deleteMentor = () => {
    axios.delete(`api/user/deleteMentor/${modalState.id}`, { headers: { "auth-token": localStorage.getItem("JWT") || " "} })
      .then(({ data }) => {
        notify.show(data.message, "success");
        setValidated(true);
        onHide()
      })
      .catch((err) => {
        console.error(err.message)
        notify.show("No se pudo eliminar el mentor", "error");
      });
  }

  const cancelRequest = () => {
    setModalState({
      delete: false,
      edit: false
    });
    onHide();
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar mentor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            Estas seguro de que quieres eliminar este mentor?<b>{modalState.name}</b>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={cancelRequest}>
          Cancelar
        </Button>
        <Button variant="success" onClick={deleteMentor}>
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
