import React, { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { notify } from "react-notify-toast";
import Axios from "axios";

export default function ExcelDownload({ show, onHide }) {
  const [since, setSince] = useState(
    moment().startOf("year").format("YYYY-MM-DD")
  );
  const [to, setTo] = useState(moment().endOf("day").format("YYYY-MM-DD"));
  const [loadingExcel, setLoadingExcel] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingExcel(true);
    Axios.get(`/api/graph/excel/${since}/${to}`, {
      headers: { "auth-token": localStorage.getItem("JWT") || " "},
    })
      .then((dataStudents) => {
        if (dataStudents.data.data.length !== 0) {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const ws = XLSX.utils.json_to_sheet(dataStudents.data.data);
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const output = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(
            output,
            `Registro de bajas - ${since
              ? moment(since).startOf("day").format("YYYY-MM-DD") + to
                ? moment(to).startOf("day").format("YYYY-MM-DD")
                : ""
              : moment().startOf("day").format("YYYY-MM-DD")
            }${fileExtension}`
          );
          onHide();
        } else {
          notify.show("No hay entradas para mostrar", "error");
        }
        setLoadingExcel(false);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Descargar Excel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Seleccione el rango de tiempo que desea descargar{" "}
            <i className="text-muted">(deja en blanco para descargar todo)</i>
          </p>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Desde:</Form.Label>
            <Form.Control
              type="date"
              name="since"
              onChange={(e) => setSince(e.target.value)}
              value={since}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Hasta:</Form.Label>
            <Form.Control
              type="date"
              name="to"
              onChange={(e) => setTo(e.target.value)}
              value={to}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cerrar
          </Button>
          <Button variant="primary" type="submit">
            Descargar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
