import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import LoadingScreen from '../../Layout/LoadingScreen';
import { Container, Tabs, Tab } from 'react-bootstrap';
import Incomes from './Incomes';
import StudentsCounter from './StudentsCounter';
import Cities from './Cities';
import Gender from './Gender';
import {
  USER_LEVEL_START,
  USER_LEVEL_STATISTICS,
  USER_LEVEL_MENTOR_STATISTICS,
} from '../../constants';
import AverageAges from './AverageAges';
import ClassroomStatistics from './ClassroomStatistics';

export default function Graphics() {
  const [isLoading, setIsLoading] = useState(false);
  const [level, setLevel] = useState(USER_LEVEL_START);
  const [key, setKey] = useState('');

  useEffect(() => {
    if (level !== USER_LEVEL_START) {
      setIsLoading(false);
    }
  }, [key]);

  const handleSelect = (key: React.SetStateAction<string>) => {
    setKey(key);
    setIsLoading(true);
    Axios.get('/api/user/checkUser', {
      headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
    })
      .then(({ data }) => {
        setLevel(data.level);
      })
      .catch((err) => {
        setLevel(USER_LEVEL_START);
      });
    setIsLoading(false);
  };

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    if (
      level === USER_LEVEL_STATISTICS ||
      level === USER_LEVEL_MENTOR_STATISTICS
    ) {
      return (
        <Container className='mb-5'>
          <Tabs id='tabsStatistics'>
            <Tab eventKey='studentsCounter' title='Altas y bajas'>
              <StudentsCounter />
            </Tab>
          </Tabs>
        </Container>
      );
    } else {
      return (
        <>
          <Container className='mb-5'>
            <Tabs id='tabs' activeKey={key} onSelect={handleSelect}>
              <Tab eventKey='incomes' title='Ingresos'>
                {(level < USER_LEVEL_STATISTICS ||
                  level < USER_LEVEL_MENTOR_STATISTICS) &&
                  level !== USER_LEVEL_START &&
                  key === 'incomes' && <Incomes />}
              </Tab>
              <Tab eventKey='studentsCounter' title='Altas y bajas'>
                {((level === USER_LEVEL_STATISTICS ||
                  level === USER_LEVEL_MENTOR_STATISTICS) &&
                  key === 'studentsCounter') || <StudentsCounter />}
              </Tab>
              <Tab eventKey='cities' title='Ciudades'>
                {key === 'cities' && <Cities />}
              </Tab>
              <Tab eventKey='classrooms' title='Comisiones'>
                {key === 'classrooms' && <ClassroomStatistics />}
              </Tab>
              <Tab eventKey='gender' title='Género'>
                {key === 'gender' && <Gender />}
              </Tab>
              <Tab eventKey='ages' title='Edades'>
                {key === 'ages' && <AverageAges />}
              </Tab>
            </Tabs>
          </Container>
          <Container>
            {key === '' && !isLoading && (
              <span style={{ fontSize: 30, color: 'gray' }}>
                Seleccione una estadística a visualizar...
              </span>
            )}
          </Container>
        </>
      );
    }
  }
}
