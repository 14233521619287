import React, { useState, useEffect } from "react";
import LoadingScreen from "../../../Layout/LoadingScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, Row, Col, ListGroup } from "react-bootstrap";
import moment from "moment";
import clientAxios from "../../../clients/clientAxios";

export default function ViewAllComments({
  studentId,
  show = true,
  handleClose,
}) {
  const [commentList, setCommentList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (studentId) {
      setIsLoading(true);
      clientAxios.get(`/trackingComments/single/${studentId}`).then(({ data }) => {
        setCommentList(data.data);
        setIsLoading(false);
      });
    }
  }, [studentId]);

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Comentarios <small className="text-muted">{commentList.length}</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading ? (
          <ListGroup>
            <ListGroup.Item active>
              <Row>
                <Col md={2}>Fecha</Col>
                <Col>Comentario</Col>
                <Col md={3} className="text-right">
                  Alumno
                </Col>
              </Row>
            </ListGroup.Item>
            {commentList.map((comment) => (
              <ListGroup.Item>
                <Row>
                  <Col md={2}>
                    {moment(comment.createdAt).format("L - LTS")}
                  </Col>
                  <Col>
                    {comment.comment}
                    <small className="text-muted"> -{comment.user}</small>
                  </Col>
                  <Col md={3} className="text-right">
                    {comment.student}
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
          <LoadingScreen customText="Buscando comentarios" />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          <FontAwesomeIcon icon={faTimesCircle} /> Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
