import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { notify } from "react-notify-toast";
import { Form, Container, Row, Col, InputGroup } from "react-bootstrap";
import axios from "axios";
import Zoom from './Zoom';

const MenuSelector = ({
  isLoading,
  selectedComission,
  selectedStudentsProp,
}) => {
  const [classrooms, setClassrooms] = useState([]);

  let counter = 0;
  const history = useHistory();

  useEffect(() => {
    if (counter === 0) {
      axios
        .get(`api/classroom/getStudentsInClassroom`, {
          headers: { "auth-token": localStorage.getItem("JWT") || " "},
        })
        .then(({ data }) => {
          let selectedComission = data.data.filter(
            (item) => item.mentor === data.caller || item.helper === data.caller
          );
          setClassrooms(selectedComission);
          isLoading(classrooms);
        })
        .catch((err) => {
          notify.show(err.response.data.message, "error");
          history.push("/");
        });

      ++counter;
    }
    //eslint-disable-next-line
  }, []);

  const handleComissionChange = (obj) => {
    selectedComission(obj.target.value);
    if (obj.target.value === "") {
      selectedStudentsProp([{ students: [] }]);
    } else {
      var selectedStudents = classrooms.filter(
        (i) => i._id === obj.target.value
      );
      selectedStudentsProp(selectedStudents);
    }
  };

  return (
    <Container fluid={true}>
      <Row className="pt-3 pb-3 pl-3 pr-3 mt-3 ml-3 mr-3 bg-light rounded shadow">
        <Col xs={4} xl={4}>
          <InputGroup className="m-0 p-0">
            <InputGroup.Prepend>
              <InputGroup.Text>Comisión:</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control as="select" onChange={handleComissionChange}>
              <option value="">Seleccione una comisión...</option>
              {classrooms.map((i, a) => {
                return (
                  <option key={a} value={i._id}>
                    {i.classroom}
                  </option>
                );
              })}
            </Form.Control>
          </InputGroup>
        </Col>
        <Col><Zoom /></Col>
      </Row>
    </Container>
  );
};

export default MenuSelector;
