import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import Axios from "axios";
import { notify } from "react-notify-toast";
import moment from "moment";
import LoadingScreen from "../../Layout/LoadingScreen";

export default function RegisterPhone() {
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState({
    data: [],
    pagedData: [],
  });
  const [show, setShow] = useState(false);
  const [form, setForm] = useState({
    isActive: true,
    name: "",
    lastname: "",
    rcCoins: "",
    manufacturer: "",
    model: "",
    platform: "",
    uuid: "",
  });

  const headerConfig = {
    headers: { "auth-token": localStorage.getItem("JWT") || " "},
  };

  useEffect(() => {
    Axios.get("/api/rollingkiosk/phoneLog", headerConfig)
      .then(({ data }) => {
        setState(data);
        setIsLoading(false);
      })
      .catch((err) => notify.show(err.response.data.message, "error"));

    // Se deshabilita el error que da en la consola de useEffect debido
    // a que la variable headerConfig necesita ser llamada desde múltiples sectores
    // Y la idea es evitar código duplicado.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = (uuid) => {
    setForm({
      isActive: true,
      name: "",
      lastname: "",
      rcCoins: "",
      uuid,
    });
    setShow(!show);
  };

  const addPhone = () => {
    Axios.post(`/api/rollingkiosk/client/register`, form, headerConfig)
      .then((i) => {
        notify.show(i.data.message, "success");
        handleClose(null);
      })
      .catch((err) => notify.show(err.response.data.message, "error"));
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  return (
    <Container>
      <Modal show={show} onHide={() => handleClose(null)}>
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>
              Registrar teléfono <small>uuid: {form.uuid}</small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type="string"
                    name="name"
                    onChange={handleChange}
                    value={form.name}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Apellido</Form.Label>
                  <Form.Control
                    type="string"
                    name="lastname"
                    onChange={handleChange}
                    value={form.lastname}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>rcCoins</Form.Label>
                  <Form.Control
                    type="number"
                    name="rcCoins"
                    onChange={handleChange}
                    value={form.rcCoins}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleClose(null)}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={addPhone}>
              Registrar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Row>
          <Col className="bg-light shadow rounded p-3 mb-5">
            <p className="lead mb-3">Lista de intentos recientes en la App</p>
            <ListGroup>
              <ListGroup.Item active>
                <Row>
                  <Col md="1">#</Col>
                  <Col>UUID</Col>
                  <Col className="text-right">Horario de acceso</Col>
                  <Col md="2" className="text-right">
                    Agregar
                  </Col>
                </Row>
              </ListGroup.Item>
              {state.data.map((item, index) => (
                <ListGroup.Item>
                  <Row>
                    <Col md="1">{index + 1}</Col>
                    <Col>{item.uuid}</Col>
                    <Col className="text-right">
                      {moment(item.createdAt).format("L LTS")}
                    </Col>
                    <Col md="2" className="text-right">
                      <Button
                        size="sm"
                        variant="info"
                        onClick={() => {
                          handleClose(item.uuid);
                        }}
                      >
                        <i className="fas fa-plus" />
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      )}
    </Container>
  );
}
