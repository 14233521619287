import React, { useState, useContext } from "react";
import {
  Col,
  Form,
  Container,
  Row,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { ClassroomList } from "../../../Context/ClassroomListContext";

export default function StudentsFilter(props: {
  filterStudents: (
    arg0?:
      | {
        scholarship: string;
        phone: string;
        classroom: string;
        email: string;
        isActive: string;
        name: string;
        surname: string;
      }
      | undefined
  ) => void;
}) {
  const classroomList = useContext(ClassroomList);
  const [filterSettings, setFilterSettings] = useState({
    scholarship: "",
    phone: "",
    classroom: "",
    email: "",
    isActive: "",
    name: "",
    surname: "",
  });

  const cleanSearch = () => {
    setFilterSettings({
      scholarship: "",
      phone: "",
      classroom: "",
      email: "",
      isActive: "",
      name: "",
      surname: "",
    });

    props.filterStudents();
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.filterStudents(filterSettings);
  };

  const handleChange = (e: { target: { name: any; value: any } }) => {
    let { name, value } = e.target;
    if(name === 'phone' && value){
      value = value.replace("-","");
      value = value.replace(" ","");
    }
    setFilterSettings({ ...filterSettings, [name]: value });
  };

  return (
    <Col className="p-3 mt-3 bg-light rounded shadow"> 
      <Form
        inline
        className="d-flex justify-content-around"
        onSubmit={handleSubmit}
      >
        <Container fluid>
          <Row className="flex-row justify-content-center m-0 p-0">
          <Col className="px-1" md={8}>
              <InputGroup size="sm" className="ml-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>Tel</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  name="phone"
                  value={filterSettings.phone}
                  onChange={handleChange}
                // disabled={props.loadingFilter}
                />
              </InputGroup>
            </Col>
            <Col className="pr-1" sm={12} md={2}>
              <InputGroup size="sm">
                <InputGroup.Prepend>
                  <InputGroup.Text>Beca</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="select"
                  value={filterSettings.scholarship}
                  name="scholarship"
                  onChange={handleChange}
                // disabled={props.loadingFilter}
                >
                  <option value="">Todas</option>
                  <option value="beca">Beca</option>
                </Form.Control>
              </InputGroup>
            </Col>
            <Col className="px-1" sm={12} md={2}>
              <InputGroup size="sm" className="ml-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>Comisión</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="select"
                  value={filterSettings.classroom}
                  name="classroom"
                  onChange={handleChange}
                // disabled={props.loadingFilter}
                >
                  <option value="">Todas</option>
                  {classroomList.crList.map(
                    (
                      classroomData: { classroom: React.ReactNode },
                      index: string | number | undefined
                    ) => (
                      <option key={index}>{classroomData.classroom}</option>
                    )
                  )}
                </Form.Control>
              </InputGroup>
            </Col>
            </Row>
            <Row className="d-flex justify-content-end m-0 mt-2 p-0">
            <Col className="px-1 sm-12" sm={12} md={8}>
              <InputGroup size="sm" className="ml-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>Email</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  name="email"
                  value={filterSettings.email}
                  onChange={handleChange}
                // disabled={props.loadingFilter}
                />
              </InputGroup>
            </Col>
            <Col className="pl-1 sm-12" md={4}>
              <InputGroup size="sm" className="ml-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>Estado:</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="select"
                  value={filterSettings.isActive}
                  name="isActive"
                  onChange={handleChange}
                // disabled={props.loadingFilter}
                >
                  <option value="">Todas</option>
                  <option value="up">Activo</option>
                  <option value="posibleDown">Posible baja</option>
                  <option value="down">Baja</option>
                  <option value="done">Finalizo cursado</option>
                  <option value="prepay">Seña</option>
                  <option value="commissionChanged">Cambio de comisión</option>
                </Form.Control>
              </InputGroup>
            </Col>
         
          </Row>

          <Row className="d-flex justify-content-end m-0 mt-2 p-0">
            <Col>
              <InputGroup size="sm">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">Nombre</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  name="name"
                  value={filterSettings.name}
                  onChange={handleChange}
                  // disabled={props.loadingFilter}
                  className="w-auto"
                />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup size="sm">
                <InputGroup.Prepend>
                  <InputGroup.Text>Apellido</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  name="surname"
                  value={filterSettings.surname}
                  onChange={handleChange}
                  // disabled={props.loadingFilter}
                  className="w-auto"
                />
              </InputGroup>
            </Col>
            <Col md={4} className="text-right">
              <Row>
                <Col>
                  <Button size="sm" type="submit" className="w-100"
                  // disabled={props.loadingFilter}
                  >
                    <FontAwesomeIcon icon={faSearch} /> Buscar!
                  </Button>
                </Col>
                <Col>
                  <Button
                    size="sm"
                    variant="danger"
                    className="w-100"
                    onClick={cleanSearch}
                  // disabled={props.loadingFilter}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} /> Limpiar Búsqueda
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
    </Col>
  );
}
