// Importando librerías externas
import React, { useEffect, useState } from "react";
import { Row, Col, ListGroup } from "react-bootstrap";

export default function List({ commission }) {
  const countStudents = () => {
    if (!commission.students || commission.students.length < 1) return <></>;
    let counter = { up: 0, down: 0, total: 0, scholarship: 0, commissionChanged: 0 };
    commission.students.map(student => {
      counter = { ...counter, total: ++counter.total };

      if (student.isActive) {
        let query = typeof student.isActive === "object"
          ? student.isActive[student.classroom.indexOf(commission.classroom)]
          : student.isActive;

        if (student.scholarship[student.classroom.indexOf(commission.classroom)]) counter = { ...counter, scholarship: ++counter.scholarship }
        switch (query) {
          case "up":
            counter = { ...counter, up: ++counter.up };
            break;
          case "down":
            counter = { ...counter, down: ++counter.down };
            break;
          case "commissionChanged":
            counter = { ...counter, commissionChanged: ++counter.commissionChanged };
            break;
          default: return null;
        }
      }
      return null;
    });
    return (
      <div>
        <small className="text-muted border rounded border-muted p-2">
          Activos: {counter.up}, Bajas: {counter.down},
          Total: {counter.total}
        </small>
        <small className="d-block text-muted p-2 mt-1">
          Becas: {counter.scholarship}, Cambios: {counter.commissionChanged}
        </small>
      </div>
    );
  };

  return (
    <Col
      xs={12}
      sm={12}
      md={5}
      lg={5}
      className="my-3 py-3 bg-light rounded shadow"
    >
      {/* ========== Listado de alumnos ========== */}
      <Row>
        <Col xs={4}>
          <p className="m-0 p-0 lead">Alumnos</p>
        </Col>
        <Col xs={8} className="text-right">
          {countStudents()}
        </Col>
      </Row>

      <Row>
        <Col className="pt-3">
          <ListGroup as="ul" style={{ height: 250, overflowY: "scroll" }}>
            {commission.students ? (
              commission.students.length === 0 ? (
                <ListGroup.Item action as="li">
                  <i className="text-muted m-0 p-0">
                    Esta comisión no tiene alumnos asignados
                  </i>
                </ListGroup.Item>
              ) : (
                commission.students.map((i, a) => {
                  let isActiveIndex = i.classroom.indexOf(commission.classroom), colorAccordingState;

                  try {
                    if (i.scholarship[isActiveIndex]) {
                      colorAccordingState = "text-light bg-primary";
                    }
                    else {
                      if (i.isActive[isActiveIndex] === "down") {
                        colorAccordingState = "text-light bg-danger";
                      }
                      else if (i.isActive[isActiveIndex] === "done") {
                        colorAccordingState = "bg-success text-light"
                      }
                      else if (i.isActive[isActiveIndex] === "commissionChanged") {
                        colorAccordingState = "bg-warning text-light"
                      }
                      else {
                        colorAccordingState = "bg-light"
                      }
                    }

                  } catch {
                    colorAccordingState = "bg-light";
                  }

                  return (
                    <ListGroup.Item
                      className={colorAccordingState}
                      action
                      as="li"
                      key={a}
                    >
                      <Row>
                        <Col md={10}>
                          <span className="m-0 p-0">{`${i.name} ${i.surname} ${!i.isActive ? "- No se asigno un estado" : ""
                            }`}</span>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  );
                })
              )
            ) : (
              <ListGroup.Item action as="li">
                <i className="text-muted m-0 p-0">
                  Seleccione una comisión para continuar
                </i>
              </ListGroup.Item>
            )}
          </ListGroup>
        </Col>
      </Row>
    </Col>
  );
}
