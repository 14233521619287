import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { notify } from "react-notify-toast";
import clientAxios from "../../../clients/clientAxios";

export default function AddCommentModal({
  show,
  handleClose,
  student = { id: "", name: "none" },
}) {
  const [comment, setComment] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSaving(true);

    if (comment) {
      clientAxios.post(
        `/trackingComments/`,
        { studentId: student.id, comment }
      ).then(({ data }) => {
        setIsSaving(false);
        handleClose();
        notify.show(data.message, "success");
      });
    } else {
      notify.show('El Campo de Comentario esta Vacio', "error");
    }

  };

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar comentario en alumno {student.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <Form.Group>
            <Form.Label>Comentario:</Form.Label>
            <Form.Control
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              type="text"
              maxLength={100}
            />
            <small>Máximo Permitido: 100 Caracteres</small>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cerrar
          </Button>
          <Button variant="primary" disabled={isSaving} type="submit">
            <FontAwesomeIcon icon={faPlusCircle} /> Agregar comentario
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
