import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import LoadingScreen from './Layout/LoadingScreen';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import {
  USER_LEVEL_MENTOR_STATISTICS,
  USER_LEVEL_STATISTICS,
  USER_LEVEL_MENTOR,
  USER_LEVEL_COMMUNICATION,
  USER_LEVEL_ADMIN,
  USER_LEVEL_START,
} from './constants';

export default function Home() {
  // Setting history
  const history = useHistory();
  // Setting state
  const [isLoading, setIsLoading] = useState(true);
  const [level, setLevel] = useState(USER_LEVEL_START);

  // Checks if user is already logged in
  useEffect(() => {
    Axios.get('/api/user/checkUser', {
      headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
    })
      .then(({ data }) => {
        setLevel(data.level);
        localStorage.setItem('userLevel', data?.level);
      })
      .catch((err) => {
        redirectionRoutes();
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (localStorage.getItem('JWT')) {
      redirectionRoutes();
    } else {
      setIsLoading(false);
    }
  }, [level]);

  const redirectionRoutes = () => {
    if (level === USER_LEVEL_COMMUNICATION || level === USER_LEVEL_ADMIN) {
      history.push('/students/viewAll');
    } else if (
      level === USER_LEVEL_MENTOR ||
      level === USER_LEVEL_MENTOR_STATISTICS
    ) {
      history.push('/teacher');
    } else if (level === USER_LEVEL_STATISTICS) {
      history.push('/graphic');
    }
  };

  // Printing UI
  if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <Container>
        <Row>
          <Col className="text-center">
            <img
              className="mb-5"
              src={process.env.PUBLIC_URL + '/rclogo.png'}
              alt="RollingCode School"
              width="500"
            />
            <p className="lead mt-5">
              Necesitas estar logueado para poder acceder
            </p>
            <Button
              variant="success"
              onClick={() => history.push('/users/login')}
            >
              <FontAwesomeIcon icon={faSignInAlt} /> Ingresar
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}
