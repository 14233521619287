import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { notify } from "react-notify-toast";

const ConfirmBillDelete = ({ billId, getBills }) => {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const handleClose = () => setShowConfirmDeleteModal(false);
  const handleShow = () => setShowConfirmDeleteModal(true);

  const confirmDeleteBill = async (billId) => {
    axios
      .delete(`/api/bills/delete/${billId}`, {
        headers: { "auth-token": localStorage.getItem("JWT") || " " },
      })
      .then(() => {
        setShowConfirmDeleteModal(false);
        getBills();
        notify.show("Boleta eliminada", "success");
      })
      .catch((err) => {
        notify.show(`Ocurrió un error. ${err}`, "error");
      });
  };

  return (
    <>
      <button onClick={handleShow} className="border-0 bg-transparent">
        <FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
      </button>
      <Modal show={showConfirmDeleteModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar boleta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Si elimina la boleta, tendrá que reflejar los cambios manualmente en
          la caja chica. ¿ Está seguro de continuar eliminando la boleta?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancelar
          </Button>
          <Button variant="danger" onClick={() => confirmDeleteBill(billId)}>
            <FontAwesomeIcon icon={faCheckCircle} /> Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmBillDelete;
