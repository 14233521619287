import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { ListGroup, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { debtorBillsType } from "../../../Types/Debtors";
import ItemMenu from "./ItemMenu";

export default function ItemOfTheDebtorList({
  header,
  data,
  from,
  refresh,
  noEntry,
  inProgress,
}: {
  inProgress?: boolean;
  noEntry?: boolean;
  header?: boolean;
  data?: debtorBillsType;
  from?: "debtors" | "inProgress" | "archived";
  refresh: (
    i: string,
    where: string,
    from: string,
    id: string,
    comment: string
  ) => void;
}) {
  if (noEntry) {
    return (
      <ListGroup.Item as="li" style={{ cursor: "pointer" }}>
        No hay alumnos para mostrar
        <span role="img" aria-label="waving hand">
          👏
        </span>
      </ListGroup.Item>
    );
  } else if (inProgress) {
    return (
      <ListGroup.Item as="li" active={header}>
        <>
          <Row>
            <Col md="1">
              {header ? null : (
                <Button size="sm" variant="info" href={`tel:${data?.phone}`}>
                  <FontAwesomeIcon icon={faPhone} />
                </Button>
              )}
            </Col>
            <Col className="text-left">
              {header ? (
                <small>Alumno</small>
              ) : (
                <Link
                  className="text-dark"
                  to={`/students/details/${data?.id}`}
                >{`${data?.name} (${data?.classroom})`}</Link>
              )}
            </Col>
            <Col md="2" className="text-center">
              {header ? <small>Tomado por:</small> : data?.atCharge}
            </Col>
            <Col md="3" className="text-center">
              {header ? <small>Última nota:</small> : data?.lastNote}
            </Col>
            <Col md="1">
              {header ? null : (
                <ItemMenu
                  refresh={(
                    i: string,
                    where: string,
                    from: string,
                    id: string,
                    comment: string
                  ) => {
                    refresh(i, where, from, id, comment);
                  }}
                  from={from || null}
                  debtor={data}
                />
              )}
            </Col>
          </Row>
          {!header ? (
            <Row>
              <Col md="1" />
              <Col>
                <small>Comentarios asignados: {data?.comments.quantity}</small>
              </Col>
              <Col>
                <small>
                  {data?.comments.lastModified
                    ? `Ultima modificación: ${moment(
                      data?.comments.lastModified
                    ).format("L")}`
                    : ""}
                </small>
              </Col>
            </Row>
          ) : null}
        </>
      </ListGroup.Item>
    );
  } else {
    return (
      <ListGroup.Item as="li" active={header}>
        <>
          <Row>
            <Col md="1">
              {header ? null : (
                <Button size="sm" variant="info" href={`tel:${data?.phone}`}>
                  <FontAwesomeIcon icon={faPhone} />
                </Button>
              )}
            </Col>
            <Col className="text-left">
              {header ? (
                <small>Alumno</small>
              ) : (
                <Link
                  className="text-dark"
                  to={`/students/details/${data?.id}`}
                >{`${data?.name} (${data?.classroom})`}</Link>
              )}
            </Col>
            <Col md="2" className="text-center">
              {header ? <small>Cuotas:</small> : data?.installments}
            </Col>
            <Col md="3" className="text-center">
              {header ? <small>Deudor desde /</small> : data?.debtorSince}<br />
              {header ? <small>Ultimo Pago:</small> : data?.lastPayment}
            </Col>
            <Col md="1">
              {header ? null : (
                <ItemMenu
                  refresh={(
                    i: string,
                    where: string,
                    from: string,
                    id: string,
                    comment: string
                  ) => {
                    refresh(i, where, from, id, comment);
                  }}
                  from={from || null}
                  debtor={data}
                />
              )}
            </Col>
          </Row>
          {!header ? (
            <Row>
              <Col md="1" />
              <Col>
                <small>Comentarios asignados: {data?.comments.quantity}</small>
              </Col>
              <Col>
                <small>
                  {data?.comments.lastModified
                    ? `Ultima modificación: ${moment(
                      data?.comments.lastModified
                    ).format("L")}`
                    : ""}
                </small>
              </Col>
            </Row>
          ) : null}
        </>
      </ListGroup.Item>
    );
  }
}
