import {
  faArchive,
  faComment,
  faFileInvoiceDollar,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { debtorBillsType } from "../../../Types/Debtors";
import PaymentHistorial from "../../students/details/modals/PaymentHistorial";
import AddCommentModal from "./AddCommentModal";
import MoveStudentModal from "./MoveStudentModal";
import ViewAllComments from "./ViewAllComments";

export default function ItemMenu({
  debtor = {
    name: "string",
    id: "string",
    phone: "number",
    mail: "string",
    comments: {
      quantity: 0,
      lastModified: "string",
    },
    classroom: "string",
    debtorSince: "string",
    lastPayment: "string",
    installments: "string",
  },
  from,
  refresh,
}: {
  debtor?: debtorBillsType;
  from: "debtors" | "inProgress" | "archived" | null;
  refresh: (
    i: string,
    where: string,
    from: string,
    id: string,
    comment: string
  ) => void;
}) {
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showPaymentHistorialModal, setShowPaymentHistorialModal] = useState(false);
  const [viewCommentsModal, setViewCommentsModal] = useState<{
    id: string | null;
    name: string | null;
    show: boolean;
  }>({ id: null, name: null, show: false });
  const [moveModalState, setMoveModalState] = useState<moveModalProps>({
    show: false,
    where: "",
    from: "",
    id: "",
    debtorCommission: "",
  });

  const toggleAddComment = () => {
    setShowCommentModal((i: boolean) => !i);
  };

  const togglePaymentsModal = () => {
    setShowPaymentHistorialModal((i: boolean) => !i);
  };

  const toggleViewComments = ({
    id,
    name,
  }: {
    id: string | null;
    name: string | null;
  }) => {
    setViewCommentsModal(
      (i: { id: string | null; name: string | null; show: boolean }) => ({
        id,
        name,
        show: !i.show,
      })
    );
  };

  const moveTo = (where: "debtors" | "inProgress" | "archived" | null) => {
    if (!where) {
      setMoveModalState({
        show: false,
        where: null,
        from: null,
        id: null,
        debtorCommission: null,
      });
    } else {
      setMoveModalState({
        show: true,
        where,
        from,
        id: debtor.id,
        debtorCommission: debtor.classroom,
      });
    }
  };

  return (
    <>
      <AddCommentModal
        show={showCommentModal}
        handleClose={toggleAddComment}
        student={{ id: debtor.id, name: debtor.name }}
      />
      
      <PaymentHistorial
          show={showPaymentHistorialModal}
          onHide={() =>
            setShowPaymentHistorialModal(!showPaymentHistorialModal)
          }
          studentId={debtor.id}
        />

      <ViewAllComments
        studentId={viewCommentsModal.id}
        show={viewCommentsModal.show}
        handleClose={() => toggleViewComments({ id: null, name: null })}
      />

      <MoveStudentModal
        refresh={(
          i: string,
          where: string,
          from: string,
          id: string,
          comment: string
        ) => {
          refresh(i, where, from, id, comment);
        }}
        show={moveModalState.show}
        handleClose={() => moveTo(null)}
        where={moveModalState.where}
        from={from}
        id={moveModalState.id}
        debtorCommission={moveModalState.debtorCommission}
      />

      <Dropdown>
        <Dropdown.Toggle
          size="sm"
          variant="info"
          id="dropdown-basic"
        ></Dropdown.Toggle>

        <Dropdown.Menu>
        <Dropdown.Item onClick={togglePaymentsModal}>
            <FontAwesomeIcon icon={faFileInvoiceDollar} /> Ver Historial de Pagos
          </Dropdown.Item>
          <Dropdown.Item onClick={toggleAddComment}>
            <FontAwesomeIcon icon={faComment} /> Agregar comentario
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              toggleViewComments({ id: debtor.id, name: debtor.name })
            }
          >
            <FontAwesomeIcon icon={faComment} /> Ver comentarios
          </Dropdown.Item>
          {from !== "debtors" ? (
            <Dropdown.Item onClick={() => moveTo("debtors")}>
              <FontAwesomeIcon icon={faShare} /> Mover a deudores
            </Dropdown.Item>
          ) : null}

          {from !== "inProgress" ? (
            <Dropdown.Item onClick={() => moveTo("inProgress")}>
              <FontAwesomeIcon icon={faShare} /> Mover a En Progreso
            </Dropdown.Item>
          ) : null}

          {from !== "archived" ? (
            <Dropdown.Item onClick={() => moveTo("archived")}>
              <FontAwesomeIcon icon={faArchive} /> Archivar alumno
            </Dropdown.Item>
          ) : null}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

interface moveModalProps {
  show: boolean;
  where: string | null;
  from: string | null;
  id: string | null;
  debtorCommission: string | null;
}
