import Axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { Toast } from "react-bootstrap";
import { notify } from "react-notify-toast";
import { ToastNotificationType } from "../../Types/ToastNotifications";

export default function ToastNotification({
  title,
  createdAt,
  body,
  _id,
}: ToastNotificationType) {
  const [showToast, setShowToast] = useState(true);

  const toggleToast = () => {
    setShowToast(!showToast);
  };

  const markAsRead = () => {
    Axios.put(
      `/api/notifications/${_id}`,
      {},
      {
        headers: { "auth-token": localStorage.getItem("JWT") || " "},
      }
    )
      .then(({ data }) => {
        setShowToast(!showToast);
      })
      .catch((err) => {
        notify.show(err.response.data.message, "error");
      });
  };

  return (
    <Toast style={{ width: 500 }} show={showToast} onClose={toggleToast}>
      <Toast.Header>
        <strong className="mr-auto">{title}</strong>
        <small>{moment(createdAt).fromNow()}</small>
      </Toast.Header>
      <Toast.Body>
        {body}
        <br />
        <small
          className="text-muted"
          style={{ cursor: "pointer" }}
          onClick={markAsRead}
        >
          Marcar como leído
        </small>
      </Toast.Body>
    </Toast>
  );
}
