import React, { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import clientAxios from '../../clients/clientAxios';
import { notify } from 'react-notify-toast';

const ModalForRefund = ({
  isModaRefundOpen,
  setIsModalRefundOpen,
  dataForModal,
  getPaymentsMp,
}) => {
  const [isButtonLoading, setIsbuttonLoading] = useState(false);

  const handleRefund = async () => {
    setIsbuttonLoading(true);
    try {
      await clientAxios.post('/bills/payments/refunds/', {
        numberOperation: dataForModal?.numberOperation,
        email: dataForModal.email,
      });
      notify.show(
        'La operación de reembolso se realizo correctamente',
        'success'
      );
    } catch (error) {
      notify.show(
        error.response.data.message ||
          'No se pudo realizar el reembolso, intente más tarde',
        'error'
      );
    } finally {
      setIsbuttonLoading(false);
      setIsModalRefundOpen(false);
      getPaymentsMp();
    }
  };

  return (
    <Modal show={isModaRefundOpen} onHide={() => setIsModalRefundOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmar Reembolso</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          Está seguro de realizar una devolución a {dataForModal.email} número
          de operación {dataForModal.numberOperation}
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setIsModalRefundOpen(false)}>
          No
        </Button>
        <Button
          variant='primary'
          onClick={handleRefund}
          disabled={isButtonLoading}
          style={{ paddingLeft: '16px', paddingRight: '16px' }}
        >
          {isButtonLoading ? (
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
              <span style={{marginLeft: '4px'}}>Si</span>
            </div>
          ) : (
            <span>Si</span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalForRefund;
