import React, { useState, useEffect } from "react";
import Axios from "axios";
import moment from "moment";

export default function Birthday({ classroom = "no" }) {
  const [show, setShow] = useState(false);
  const [birthdayList, setBirthdayList] = useState<Partial<dbResponse>>({});

  useEffect(() => {
    Axios.get(`/api/students/birthdays/${classroom}`, {
      headers: { "auth-token": localStorage.getItem("JWT") || " "},
    })
      .then(({ data }) => {
        setBirthdayList(data);
      })
      .catch((err) => {
        console.error(
          err.response.data ? err.response.data.message : err.message
        );
      });
  }, [classroom]);

  return (
    <>
      <aside
        className="sidebar p-4 position-fixed"
        style={{
          overflowY: "scroll",
          left: show ? 0 : "-17rem",
          display: show ? "block" : "none",
          opacity: show ? 1 : 0,
        }}
      >
        <h1 className="display-5">
          Cumpleaños!{" "}
          <span role="img" aria-label="ballon">
            🎈
          </span>
        </h1>
        <hr />
        {classroom ? (
          <>
            <h2>Hoy:</h2>
            {birthdayList.birthdayToday?.length !== 0 ? (
              <ul>
                {birthdayList.birthdayToday?.map((student, i) => (
                  <li key={i}>
                    {student.name}{" "}
                    <small className="text-muted">
                      ({moment(student.birthDate).format("L")})
                    </small>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-muted">
                <i>Ningún alumno cumple años hoy </i>
                <span role="img" aria-label="sad face">
                  😢
                </span>
              </p>
            )}

            <h2>Este mes:</h2>
            {birthdayList.birthdayNear?.length !== 0 ? (
              <ul>
                {birthdayList.birthdayNear?.map((student, i) => (
                  <li key={i}>
                    {student.name}{" "}
                    <small className="text-muted">
                      ({moment(student.birthDate).format("L")})
                    </small>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-muted">
                <i>Ningún alumno cumple años hoy </i>
                <span role="img" aria-label="sad face">
                  😢
                </span>
              </p>
            )}
          </>
        ) : (
          <p>Seleccione una comisión para ver los cumpleaños</p>
        )}
      </aside>

      <button
        onClick={() => setShow(!show)}
        className="birthday-button position-fixed"
        style={{ left: show ? "32vw" : 0 }}
      >
        <strong> {show ? "<<" : ">>"} </strong>
      </button>
      {show ? (
        <div
          className="backdrop position-fixed"
          style={{ opacity: show ? 1 : 0 }}
          onClick={() => setShow(!show)}
        ></div>
      ) : null}
    </>
  );
}

interface birthdayType {
  name: string;
  id: string;
  birthDate: string;
}

interface dbResponse {
  birthdayToday: birthdayType[];
  birthdayNear: birthdayType[];
}
