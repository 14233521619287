import React from "react";
import { Link } from "react-router-dom";

export default function MentorNav() {
  return (
    <>
    <Link className="nav-link" to="/commission">
      Comisiones
    </Link>
      <Link className="nav-link" to="/graphic">
      Estadísticas
    </Link>
    </>
  );
}
