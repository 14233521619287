import React, { Component } from 'react';
import { Row, Col, Container, ListGroup, Spinner } from 'react-bootstrap';
import InProgress from './InProgress';
import Debtors from './Debtors';
import Archived from './Archived';
import Axios from 'axios';
import { screens } from './Screens';
import ViewAllComments from './subcomponents/ViewAllComments';

export default class StatisticsMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedScreen: 0,
      hasToRefresh: false,
      isLoading: false,
      debtors: [],
      debtorsBills: [],
      archived: [],
      inProgress: [],
      showCommentsModal: false,
      showAddCommentModal: false,
      showViewCommentsModal: false,
    };

    this.viewCommentsModalStudentId = '';
  }

  fetchDebtors = (filter) => {
    this.setState({ isLoading: true });
    Axios.post(
      '/api/statistics/get/debtors',
      { ...filter },
      {
        headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
      }
    ).then(({ data }) => {
      this.setState({
        debtors: data.result,
        debtorsBills: data.result,
        isLoading: false,
      });
    });
  };

  fetchProgress = (filter) => {
    Axios.post(
      '/api/statistics/inProgress',
      { ...filter },
      {
        headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
      }
    ).then((response) => {
      this.setState({ inProgress: response.data.data });
    });
  };

  fetchArchived = (filter) => {
    Axios.post(
      '/api/statistics/archived',
      { ...filter },
      {
        headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
      }
    ).then((response) => {
      this.setState({ archived: response.data.data });
    });
  };

  refresh = (i, where, from, id, comment) => {
    const pos = this.state[from]
      .map(function (e) {
        if (e.studentId) {
          return e.studentId;
        } else if (e.id) {
          return e.id;
        } else {
          return e._id;
        }
      })
      .indexOf(id);

    let newFrom = this.state[from];
    let newWhere = this.state[where];
    let item = this.state[from][pos];
    item.tracking = { takenBy: { name: this.context.username }, comment };
    newWhere.push(item);
    newFrom.splice(pos, 1);

    this.setState((i) => ({
      [from]: newFrom,
      [where]: newWhere,
      hasToRefresh: !i.hasToRefresh,
    }));
  };

  toggleViewCommentsModal = (id) => {
    this.viewCommentsModalStudentId = id;
    this.setState((i) => ({ showViewCommentsModal: !i.showViewCommentsModal }));
  };

  render() {
    return (
      <Container fluid className="mx-2">
        <ViewAllComments
          studentId={this.viewCommentsModalStudentId}
          show={this.state.showViewCommentsModal}
          handleClose={() => this.toggleViewCommentsModal('')}
        />
        <Row>
          <Col md="3" className="bg-white shadow p-3 mb-3">
            <ListGroup as="ul">
              {screens.map((a, i) => {
                let quantity = 0;

                switch (a.name) {
                  case 'Deudores':
                    quantity = this.state.debtors
                      ? this.state.debtors.length
                      : 0;
                    break;

                  case 'En progreso':
                    quantity = this.state.inProgress.length;
                    break;

                  case 'Archivados':
                    quantity = this.state.archived.length;
                    break;

                  default:
                    quantity = 'Error';
                }

                return (
                  <ListGroup.Item
                    style={{ cursor: 'pointer' }}
                    as="li"
                    disabled={a.disabled}
                    key={i}
                    onClick={() => {
                      this.setState({ selectedScreen: i });
                    }}
                    active={this.state.selectedScreen === i}
                  >
                    {a.name}{' '}
                    <small>
                      {this.state.isLoading ? (
                        <Spinner
                          animation="border"
                          size="sm"
                          variant="dark"
                          role="status"
                        >
                          <span className="sr-only">Cargando...</span>
                        </Spinner>
                      ) : (
                        `(${quantity})`
                      )}
                    </small>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Col>
          <Col md="1" />
          <Col md="7">
            <Debtors
              refresh={(i, where, from, id, comment) =>
                this.refresh(i, where, from, id, comment)
              }
              hasToRefresh={this.state.hasToRefresh}
              debtorsBills={this.state.debtorsBills}
              data={this.state.debtors}
              className={this.state.selectedScreen === 0 ? 'd-block' : 'd-none'}
              toggleViewCommentsModal={(id) => this.toggleViewCommentsModal(id)}
              filter={(query) => this.fetchDebtors(query)}
              isLoading={this.state.isLoading}
            />

            <InProgress
              isLoading={this.state.isLoading}
              refresh={(i, where, from, id, comment) =>
                this.refresh(i, where, from, id, comment)
              }
              hasToRefresh={this.state.hasToRefresh}
              debtorsBills={this.state.debtorsBills}
              data={this.state.inProgress}
              className={this.state.selectedScreen === 1 ? 'd-block' : 'd-none'}
              toggleViewCommentsModal={(id) => this.toggleViewCommentsModal(id)}
              filter={(filter) => this.fetchProgress(filter)}
            />

            <Archived
              isLoading={this.state.isLoading}
              debtorsBills={this.state.debtorsBills}
              refresh={(i, where, from, id, comment) =>
                this.refresh(i, where, from, id, comment)
              }
              hasToRefresh={this.state.hasToRefresh}
              data={this.state.archived}
              className={this.state.selectedScreen === 2 ? 'd-block' : 'd-none'}
              toggleViewCommentsModal={(id) => this.toggleViewCommentsModal(id)}
              filter={(filter) => this.fetchArchived(filter)}
            />
          </Col>
          <Col />
        </Row>
      </Container>
    );
  }
}
