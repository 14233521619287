import React from 'react';
import { Table } from 'react-bootstrap';
import moment from 'moment-timezone';
import { TIMEZONE } from '../../helpers/argenitianTimeZone';

const FORMATDATE = 'DD-MM-YYYY';

const CouponsAssigned = ({ coupons }) => {

  return (
    <div className='mt-5'>
      <Table striped bordered hover variant='dark'>
        <thead className='text-center'>
          <tr>
            <th>Email</th>
            <th>Nombre y Apellido</th>
            <th>Descripción</th>
            <th>% de descuento</th>
            <th>Fecha de creación</th>
            <th>Código de Cupon</th>
            <th>Canjeado</th>
          </tr>
        </thead>
        <tbody>
          {coupons.map((coupon) => (
            <tr key={coupon._id} className='text-center'>
              <td>{coupon?.beneficiaryEmail ? coupon?.beneficiaryEmail : '-'}</td>
              <td>
                {coupon?.beneficiaryId?.name && coupon.beneficiaryId?.surname ? coupon?.beneficiaryId?.name +
                  ' ' +
                  coupon?.beneficiaryId?.surname : '-'}
              </td>
              <td className='text-wrap'>
                {coupon?.comments ? coupon?.comments : '-'}
              </td>
              <td>{coupon?.amountToDiscount}</td>
              <td>
                {moment(coupon?.createdAt).tz(TIMEZONE).format(FORMATDATE)}
              </td>
              <td>{coupon?.couponCode}</td>
              <td>{coupon?.claimByEmail || coupon?.claimById ? 'Si' : 'No'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
export default CouponsAssigned;
