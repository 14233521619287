import React from "react";
import { Col } from "react-bootstrap";
import moment from "moment";

export default function PrincipalData({
  location = "-",
  birthDate,
  dni = 0,
  address = "-",
  country = "-",
  region = "-",
  phone = 0,
  phoneAlt = 0,
  email = "-",
  currentJob = "-",
}) {
  return (
    <Col className="p-3 mb-5 bg-light shadow rounded" md={4}>
      <p className="lead m-0 p-0">Datos principales:</p>
      <br />
      <p>
        <small className="text-muted">Fecha de nacimiento:</small>{" "}
        <span>{`${birthDate ? moment(birthDate).format("ll") : "-"}`}</span>
      </p>
      <p>
        <small className="text-muted">D.n.i.:</small> <span>{dni}</span>
      </p>
      <p>
        <small className="text-muted">Dirección:</small> <span>{address}</span>
      </p>
      <p>
        <small className="text-muted">País:</small> <span>{country}</span>
      </p>
      <p>
        <small className="text-muted">Provincia:</small> <span>{region}</span>
      </p>
      <p>
        <small className="text-muted">Localidad:</small> <span>{location}</span>
      </p>
      <p>
        <small className="text-muted">Telefono:</small>{" "}
        <span>{`${phone} ${phoneAlt ? `/ ${phoneAlt}` : ""}`}</span>
      </p>
      <p>
        <small className="text-muted">E-mail:</small> <span>{email}</span>
      </p>
      <p>
        <small className="text-muted">Ocupación:</small>{" "}
        <span>{currentJob}</span>
      </p>
    </Col>
  );
}
