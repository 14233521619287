export const colors = [
  '#1D2F6F',
  '#8390FA',
  '#6EAF46',
  '#FAC748',
  '#FF582A',
  '#E3170A',
  '#00539C',
  '#2BAE66',
  '#A626AA',
  '#F76F8E',
];
