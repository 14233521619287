// fundamental components

import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  withRouter,
  Switch,
} from 'react-router-dom';
import { UserContext } from './Context/UserContext';

// Import layouts

import Navigation from './Layout/navigationBar/Navigation';

//  Import Alerts

import Notifications from 'react-notify-toast';

// Import student components

import StudentList from './Components/students/list/StudentList';
import StudentCreate from './Components/students/StudentCreate';
import StudentDetail from './Components/students/details/StudentDetail';
import Subscriptions from './Components/students/subscriptions/Subscripions';

// Import Bills components

import CreateBill from './Components/payments/CreateBill';
import ListBill from './Components/payments/ListBill';
import PrintBill from './Components/payments/PrintBill';
import PaymentsPlan from './Components/payments/PaymentsPlan';
import PaymentsMp from './Components/payments/PaymentsMp';
import CreateLinkPayment from './Components/payments/CreateLinkPayment';

// Import Login components

import Home from './Home';
import Login from './Components/userAuth/Login';
import Logout from './Components/userAuth/Logout';
import ChangePassword from './Components/userAuth/ChangePassword';

// 2FA Components

import Generate2FA from './Components/userAuth/2FA/Generate2FA';
import Verify2FA from './Components/userAuth/2FA/Verify2FA';

// Import Smallbox components

import SelectorSmallbox from './Components/smallbox/SelectorSmallbox';
import SBDwnload from './Components/smallbox/SBDwnload';

// Public and private routes

import PrivateRoute from './Components/userAuth/PrivateRoute';

// Import MentorPanel

import MentorPanel from './Components/mentor/MentorPanel';
import Commission from './Components/commission/Commission';
import Tracking from './Components/tracking/Tracking';

// Import communications

import Dashboard from './Components/communication/Dashboard';

// Import RollingKiosk

import AddProduct from './Components/rollingKiosk/AddProduct';
import RegisterPhone from './Components/rollingKiosk/RegisterPhone';
import ViewProducts from './Components/rollingKiosk/ViewProducts';
import ChargeRcCoins from './Components/rollingKiosk/ChargeRcCoins';

//Import Graphic
import Graphics from './Components/statistics/Graphics';

//Import Teachers
import Teachers from './Components/teachers/Teachers';

//Import Page404
import Page404 from './Components/error404/Page404';

// Heroku Advice
import HerokuAdviceSign from './Components/HerokuAdviceSign';
import authToken from './clients/authToken';
import DebtCollection from './Components/tracking/DebtCollection';
import CertificatesViewer from './Components/certificates/CertificatesViewer';
import Coupons from './Components/payments/Coupons';

class App extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      msg: 'no message provided.',
      variant: 'warning',
      showAlert: false,
      inEditStudentPage: false,
      currentId: '',
      userData: '',
      errorPage: false,
      herokuShow: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem('JWT')) {
      authToken(localStorage.getItem('JWT'));
    }
    if (window.location.href.includes('rcsm-staging.herokuapp.com')) {
      this.setState({ herokuShow: true });
    } else {
      return;
    }
  }

  loggedInLogic = (state) => {
    this.setState({ userData: state });
  };

  logOut = (state) => {
    localStorage.clear();
    this.setState({ userData: '' });
    this.props.history.push('/students/viewAll');
  };

  showButtons = (state) => {
    if (this.state.inEditStudentPage !== state) {
      this.setState({ inEditStudentPage: state });
    }
  };

  errorPage = () => {
    if (this.state.errorPage === false) {
      this.setState({ errorPage: true });
    }
  };

  render() {
    return (
      <main>
        <Notifications options={{ zIndex: 9999 }} />
        <HerokuAdviceSign
          show={this.state.herokuShow}
          onHide={() => {
            this.setState((e) => ({
              herokuShow: !e.herokuShow,
            }));
          }}
        />
        <Router>
          <Navigation errorPage={this.state.errorPage} />
          <div className='mt-4'>
            <Switch>
              <Route
                exact
                path='/'
                render={(props) => <Home {...this.props} {...props} />}
              />
              <Route
                path='/users/login'
                render={(props) => <Login {...this.props} {...props} />}
              />
              <Route
                path='/users/logout'
                render={(props) => <Logout {...this.props} {...props} />}
              />
              <Route
                path='/users/pswrdChange/:id'
                render={(props) => (
                  <ChangePassword {...this.props} {...props} />
                )}
              />
              <Route
                path='/users/2fa/generate'
                render={(props) => <Generate2FA {...this.props} {...props} />}
              />
              <Route
                path='/users/2fa/verify'
                render={(props) => <Verify2FA {...this.props} {...props} />}
              />
              <PrivateRoute
                path='/students/viewAll'
                render={(props) => <StudentList />}
              />
              <PrivateRoute
                path='/students/create'
                render={(props) => <StudentCreate {...this.props} {...props} />}
              />
              <PrivateRoute
                path='/students/subscriptions'
                render={(props) => <Subscriptions {...this.props} {...props} />}
              />
              <PrivateRoute
                path='/certificates'
                render={(props) => (
                  <CertificatesViewer {...this.props} {...props} />
                )}
              />
              <PrivateRoute
                path='/students/edit/:id'
                render={(props) => (
                  <StudentCreate
                    showButtons={(state) => {
                      this.showButtons(state);
                    }}
                    currentId={this.state.currentId}
                    {...this.props}
                    {...props}
                  />
                )}
              />
              <PrivateRoute
                path='/students/details/:id'
                render={(props) => (
                  <StudentDetail
                    showButtons={(state) => {
                      this.showButtons(state);
                    }}
                    inEditStudentPage={this.state.inEditStudentPage}
                    {...this.props}
                    {...props}
                  />
                )}
              />
              <PrivateRoute
                path='/bills/viewAll/'
                render={(props) => <ListBill {...this.props} {...props} />}
              />
              <PrivateRoute
                path='/bills/payment-plan'
                render={(props) => <PaymentsPlan {...this.props} {...props} />}
              />
              <PrivateRoute
                path='/bills/paymentsMp'
                render={(props) => <PaymentsMp {...this.props} {...props} />}
              />
              <PrivateRoute
                path='/bills/createLinkPayment'
                render={(props) => <CreateLinkPayment {...this.props} {...props} />}
                />
              <PrivateRoute
                path='/bills/coupons'
                render={(props) => <Coupons {...this.props} {...props} />}
                />
              <PrivateRoute
                path='/bills/edit/:bilid/'
                render={(props) => <CreateBill {...this.props} {...props} />}
              />
              <PrivateRoute
                path='/bills/create/:stuid'
                render={(props) => <CreateBill {...this.props} {...props} />}
              />
              <PrivateRoute
                path='/bills/print/:id'
                render={(props) => <PrintBill {...this.props} {...props} />}
              />
              <PrivateRoute
                path='/smallbox'
                exact
                render={(props) => (
                  <SelectorSmallbox
                    {...this.props}
                    {...props}
                    history={this.props.history}
                  />
                )}
              />
              <PrivateRoute
                path='/smallbox/download/:init/:end'
                render={(props) => <SBDwnload {...this.props} {...props} />}
              />
              <PrivateRoute
                path='/commission'
                render={(props) => <Commission {...this.props} {...props} />}
              />
              <PrivateRoute
                path='/tracking'
                render={(props) => <Tracking {...this.props} {...props} />}
              />
              <PrivateRoute
                path='/debt-collection-traking'
                render={(props) => (
                  <DebtCollection {...this.props} {...props} />
                )}
              />
              <PrivateRoute
                path='/teacher'
                render={(props) => <MentorPanel {...this.props} {...props} />}
              />
              <PrivateRoute
                path='/communications/dashboard'
                exact
                render={(props) => <Dashboard {...this.props} {...props} />}
              />
              <PrivateRoute
                path='/rollingKiosk/addProduct'
                exact
                render={(props) => <AddProduct {...this.props} {...props} />}
              />
              <PrivateRoute
                path='/rollingKiosk/registerPhone'
                exact
                render={(props) => <RegisterPhone {...this.props} {...props} />}
              />
              <PrivateRoute
                path='/rollingKiosk/ViewProducts'
                exact
                render={(props) => <ViewProducts {...this.props} {...props} />}
              />
              <PrivateRoute
                path='/rollingKiosk/ChargeRcCoins'
                exact
                render={(props) => <ChargeRcCoins {...this.props} {...props} />}
              />
              <PrivateRoute
                path='/graphic'
                render={(props) => <Graphics {...this.props} {...props} />}
              />
              <PrivateRoute
                path='/teachers'
                render={(props) => <Teachers {...this.props} {...props} />}
              />
              <Route
                render={(props) => {
                  this.errorPage(props);
                  return <Page404 {...this.props} {...props} />;
                }}
              />
            </Switch>
          </div>
        </Router>
      </main>
    );
  }
}

export default withRouter(App);
