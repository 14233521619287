import React, { useEffect, useState } from 'react';
import {
  faRectangleList,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  Modal,
  ListGroup,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notify } from 'react-notify-toast';
import clientAxios from '../../../../clients/clientAxios';
import SelectStatusStudentCommission from './SelectStatusStudentCommission';

export default function StudentCommissions({
  studentId,
  debtorsList,
  setDebtorsList,
}) {
  const [isLoadingFlagFromSelect, setIsLoadingFlagFromSelect] = useState(false);
  const [statusCampus, setStatusCampus] = useState([]);
  const [isLoadingChanges, setIsLoadingChanges] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [student, setStudent] = useState({
    name: '',
    location: '',
    birthDate: '',
    dni: 0,
    address: '',
    country: '',
    region: '',
    phone: 0,
    phoneAlt: 0,
    email: '',
    currentJob: '',
    itKnowleadge: '',
    englishKnowleadge: '',
    codeKnowleadge: '',
    availableSat: false,
    ownNotebook: false,
    contactOrigin: '',
    availableTimeFrom: '',
    availableTimeTo: '',
    scholarship: '',
    preferredSite: '',
    informativeTalkDate: '',
    course: '',
    classroom: '',
    isActive: '',
    price: '',
    englishClassroom: '',
    createdAt: '',
    createdBy: '',
    modifiedAt: '',
    modifiedBy: '',
    welcomeEmail: '',
    classroomData: [],
    classroomActive: 'te',
  });

  const getCampusUser = async (email) => {
    try {
      setIsLoadingChanges(false);
      const { data } = await clientAxios.get(`/campus/${email}`);
      setStatusCampus(data[0]?.suspended);
    } catch (error) {
      notify.show(
        'Ha ocurrido un error al traer la información del campus.',
        'error'
      );
    } finally {
      setIsLoadingChanges(true);
    }
  };

  const getStudentData = async () => {
    try {
      setIsLoadingChanges(false);
      const { data } = await clientAxios.get(`/students/${studentId}`);
      setStudent({
        ...data.response,
        classroomData: data.response?.classroomData,
      });

      getCampusUser(data?.response?.email);
    } catch (error) {
      notify.show(
        'Ha ocurrido un error al traer la información de Universe.',
        'error'
      );
    } finally {
      setIsLoadingChanges(true);
    }
  };

  const statusMessageShow = (statusUniverse, statusCampus) => {
    if (statusUniverse === undefined || statusCampus === undefined) {
      return 'Cargando...';
    }

    const statusUniverseToBoolean = statusUniverse === 'active';

    if (statusUniverseToBoolean && !statusCampus) {
      return (
        <h4 className='bg-success text-white m-0 py-2'>
          Universe y Campus habilitados
        </h4>
      );
    } else if (!statusUniverseToBoolean && !statusCampus) {
      return (
        <h4 className='bg-warning text-dark m-0 py-2'>
          Solo Campus habilitado
        </h4>
      );
    } else if (statusUniverseToBoolean && statusCampus) {
      return (
        <h4 className='bg-warning text-dark m-0 py-2'>
          Solo Universe habilitado
        </h4>
      );
    } else if (!statusUniverseToBoolean && statusCampus) {
      return (
        <h4 className='bg-danger text-white m-0 py-2'>
          Deshabilitado en Campus e Universe
        </h4>
      );
    }
  };

  const translateIsActive = (currentIsActive) => {
    switch (currentIsActive) {
      case 'up':
        return {
          className: 'text-dark',
          text: 'Activo',
        };

      case 'done':
        return {
          className: 'text-success',
          text: 'Curso finalizado',
        };

      case 'posibleDown':
        return {
          className: 'text-darkYell',
          text: 'Posible baja',
        };

      case 'prepay':
        return {
          className: 'text-darkYell',
          text: 'Curso señado',
        };

      case 'down':
        return {
          className: 'text-danger',
          text: 'Baja',
        };

      case 'commissionChanged':
        return {
          className: 'text-dark',
          text: 'Activo (Cambió comisión)',
        };

      default:
        return {
          className: 'text-muted',
          text: 'No especificado',
        };
    }
  };

  useEffect(() => {
    getStudentData();
  }, [isLoadingFlagFromSelect]);

  return (
    <>
      <Modal
        size='lg'
        dialogClassName='modal-90w'
        show={showModal}
        onHide={setShowModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Historial de comisiones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {student?.classroomData.length === 0 ? (
            <p>
              <strong>El estudiante no posee comisiones</strong>
            </p>
          ) : (
            <ListGroup>
              {student?.classroomData.map((commission, index) => (
                <ListGroup.Item
                  key={commission._id}
                  variant={commission.partial ? 'primary' : 'light'}
                >
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={6}>
                          <p className='m-0 text-muted'>
                            Curso:
                            <strong className='text-dark'>
                              {commission.course}
                            </strong>
                          </p>

                          <div className='d-flex justify-content-between'>
                            <p className='m-0 text-muted d-inline'>
                              Comision:
                              <abbr title={commission.classroom}>
                                <strong className='text-dark'>
                                  {commission.classroom}
                                </strong>
                              </abbr>
                            </p>
                          </div>
                          <div className='d-flex justify-content-between'>
                            <p className='m-0 text-muted d-inline'>
                              Estado:
                              <abbr title={commission.classroom}>
                                <strong className='text-dark '>
                                  {
                                    translateIsActive(student?.isActive[index])
                                      .text
                                  }
                                </strong>
                              </abbr>
                            </p>
                          </div>
                        </Col>
                        <Col md={6}>
                          <Col className='text-center'>
                            <p>
                              <strong className='text-dark'>
                                Habilitar o deshabilitar plataformas
                              </strong>
                            </p>
                          </Col>
                          <SelectStatusStudentCommission
                            student={student}
                            classroomId={commission._id}
                            isLoading={isLoadingFlagFromSelect}
                            setIsLoading={setIsLoadingFlagFromSelect}
                            setDebtorsList={setDebtorsList}
                            debtorList={debtorsList}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className='text-center mt-2'>
                      {isLoadingChanges &&
                      student?.classroomActive?.length > 0 ? (
                        statusMessageShow(
                          student?.classroomActive[index]?.status,
                          statusCampus
                        )
                      ) : (
                        <h4>Cargando...</h4>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => setShowModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <OverlayTrigger
        placement='left'
        overlay={
          <Tooltip id='tosoltip'>
            <strong>Detalle de comisión</strong>
          </Tooltip>
        }
      >
        <Button size='sm' onClick={() => setShowModal(true)}>
          <FontAwesomeIcon icon={faRectangleList} />
        </Button>
      </OverlayTrigger>
    </>
  );
}
