import React, { useState } from "react";
import MenuSelector from "./MenuSelector";
import Messages from "./Messages";
import { Container, Row, Col } from "react-bootstrap";
import BirthdaySidebar from "../students/sidebar/Birthday";
import LoadingScreen from "../../Layout/LoadingScreen";
import Students from "./Students";
import InternalNotifications from "../../Layout/InternalNotifications/InternalNotifications";

const MentorPanel = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [classroom, setClassroom] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedComission, setSelectedComission] = useState("");

  const loadView = (value) => {
    setIsLoading(false);
    setClassroom(value);
  };

  var a = isLoading ? "d-block-artificial" : "d-none-artificial";
  var b = isLoading ? "none" : "block";

  return (
    <React.Fragment>
      <InternalNotifications />
      <LoadingScreen className={a} />
      <Container fluid={true} style={{ display: b }}>
        <BirthdaySidebar classroom={selectedComission} />
        <Row>
          <MenuSelector
            isLoading={(value) => loadView(value)}
            selectedComission={(value) => setSelectedComission(value)}
            selectedStudentsProp={(value) =>
              setSelectedStudents(value[0].students)
            }
          />
        </Row>
        <Row>
          <Col xs={12} sm={4} md={4} lg={4}>
            {classroom !== [] ? (
              <Students
                students={selectedStudents}
                isValidComission={selectedComission !== ""}
              />
            ) : (
              <div />
            )}
          </Col>
          <Col xs={12} sm={8} md={8} lg={8}>
            <Messages />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default MentorPanel;
