import React, { useState } from 'react'
import { Row, Col, ListGroup } from "react-bootstrap";

export default function Teachers({ mentorList, setModalState, modalState }) {
  return (
    <Row>
      <Col className="p-3 my-3 bg-light rounded shadow">
        <Row className="px-4 pb-3">
          <Col md="3">Nombre y Apellido</Col>
          <Col md="3">Email</Col>
          <Col md="2">Estado</Col>
          <Col md="1" />
        </Row>
        <ListGroup as="ul">
          {mentorList.map((i, a) => (
            <ListGroup.Item action as="li" key={a} className={modalState.id === i._id && modalState.delete === true ? "text-light bg-danger" : ""}>
              <Row className="px-1">
                <Col md="3">
                  <span className="m-0 p-0 text-truncate">
                    {i.name}
                  </span>
                </Col>
                <Col
                  md="3"
                  className="text-truncate"
                  style={{ width: "%100" }}
                >
                  <span
                    className="m-0 p-0"
                    style={{ borderBottom: "none" }}
                  >
                    <abbr title={i.email}>
                      {i.email}
                    </abbr>
                  </span>
                </Col>
                <Col md="2">
                  <span className="m-0 p-0">{
                    i.isActive !== undefined ? i.isActive === true ? "Activo" : i.isActive === false ? "Baja" : "No definido" : "No definido"
                  }</span>
                </Col>
                <Col md={2}>
                  <i
                  onClick={()=>{
                    setModalState({
                        id: i._id,
                        edit: false,
                        delete: false,
                        editPass: true,
                        name: i.name,
                        email: i.email,
                        isActive: i.isActive
                    })
                  }}
                  style={{ cursor: "pointer" }}
                  className="fas fa-key p-1"
                  alt="Editar Password"
                  />
                  <i
                    onClick={() => {
                      setModalState({
                        id: i._id,
                        edit: true,
                        editPass: false,
                        delete: false,
                        name: i.name,
                        email: i.email,
                        isActive: i.isActive
                        // add: !a.modalState.add,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    className="fas fa-edit p-1"
                    alt="Editar"
                  />
                  <i
                    onClick={() =>
                      setModalState({
                        id: i._id,
                        delete: true,
                        edit: false,
                        editPass: false,
                        name: i.name,
                        email: i.email,
                        isActive: i.isActive
                      })
                    }
                    style={{ cursor: "pointer" }}
                    className={`fas fa-trash p-1 ${modalState.id === i._id && modalState.delete === true ? "" : "text-danger"}`}
                    alt="Eliminar"
                  />
                </Col>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Col>
    </Row>
  )
}
