import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import clientAxios from '../../clients/clientAxios';
import { notify } from 'react-notify-toast';
import moment from 'moment';
import LoadingScreen from '../../Layout/LoadingScreen';

const ViewMPLinks = ({ active }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [preferences, setPreferences] = useState([]);

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        setIsLoading(true);
        const response = await clientAxios.get('/bills/mp-preference-links');
        const preferences = response.data;
        setIsLoading(false);
        setPreferences(preferences);
      } catch {
        notify.show('Hubo un error al obtener las preferencias', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    if (active) {
      fetchPreferences();
    }
  }, [active]);

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <div className='d-flex justify-content-center align-items-center flex-column my-4 pb-5 ml-1 mr-1'>
      <Row className='table-responsive'>
        <Col lg={12} xs={12}>
          <Table striped bordered hover variant='dark' className='w-100 pt-5'>
            <thead>
              <tr>
                <th className='text-center'>Alumno</th>
                <th className='text-center'>Monto</th>
                <th className='text-center'>Descripción</th>
                <th className='text-center'>Tipo de Link</th>
                <th className='text-center'>Fecha de Creación</th>
                <th className='text-center'>Usuario que creo el link</th>
              </tr>
            </thead>
            <tbody>
              {preferences.map((preference, index) => (
                <tr key={index}>
                  <td className='text-center'>{preference?.email}</td>
                  <td className='text-center'>{preference?.amount}</td>
                  <td className='text-center'>{preference?.description}</td>
                  <td className='text-center'>
                    {preference?.external_reference}
                  </td>
                  <td className='text-center'>
                    {moment(preference.createdAt).format('DD/MM/YYYY HH:mm')}
                  </td>
                  <td className='text-center'>{preference.createdBy.name}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default ViewMPLinks;
