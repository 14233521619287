import React, { useContext } from "react";
import QRCode from "qrcode.react";
import { Row, Col } from "react-bootstrap";
import { invoice } from "../../../Types/Invoice";
import { UserContext } from "../../../Context/UserContext";

export default function Invoice({
  data,
  isDuplicate = false,
}: {
  data: invoice;
  isDuplicate?: boolean;
}) {
  const user = useContext(UserContext);
  const qrUrl = "https://payments.rollingcodeschool.com/live/invoice/?bill_id=";
  const leftBannerStyle = {
    height: 64,
    marginTop: 150,
    width: 109,
    paddingLeft: 0,
  };

  /**
   * Adapta la fecha para poder ser parseada sin necesidad de usar Moment
   *
   * @param input Fecha en formato (YYYY-MM-DD)
   */
  const dateTransformer = (input: string = "2020-01-30") => {
    let dateWithoutTime = input.slice(0, 10);
    let dateArray = dateWithoutTime.split("-");
    let year: number = parseInt(dateArray[0]);
    let month: number = parseInt(dateArray[1], 10) - 1;
    let date: number = parseInt(dateArray[2]);

    return { year, month, date };
  };

  const { year, month, date } = dateTransformer(
    data.date ? data.date : "2020-01-30"
  );
  const formattedDate = new Date(year, month, date);

  return (
    <Row className={`${isDuplicate ? "mt-5" : ""} border border-dark`}>
      <Col md="2"  className="border-right px-2 mr-5">
        <p
          className="verticalText text-muted p-0 mb-0 text-center"
          style={leftBannerStyle}
        >
          {isDuplicate ? "Recibo duplicado" : "Recibo Original"}{" "}
          {data.prepay ? "(Seña)" : ""}
        </p>
        <QRCode
          class="d-flex align-self-end justify-content-center"
          value={
            qrUrl +
            data._id
          }
          size={75}
          style={{ marginTop: "4rem", marginBottom: "1rem", width: "100%", height: "auto" }}
        />
      </Col>
      <Col className="px-5">
        <Row className="border-bottom mt-3 pb-3">
          <Col>
            <img
              src={process.env.PUBLIC_URL + "/rclogo.png"}
              alt="RollingCode School"
              height="50"
            />
          </Col>
          <Col md="2" />
          <Col className="text-center">
            <p className="my-0">
              <strong>Comprobante interno de pago</strong>
            </p>
            <p className="my-0">(No valido como factura)</p>
            <p className="m-0 mt-4">
              <strong>Recibo N°: </strong>
              001-{data.billNumber}
            </p>
            <p className="my-0">
              <strong>Fecha: </strong>
              {Intl.DateTimeFormat(navigator.language, {
                weekday: "long",
                month: "long",
                day: "numeric",
                year: "numeric",
              }).format(formattedDate)}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row className="my-3">
              <Col>
                <p>
                  <strong>Nombre del alumno: </strong>
                  {data.nameDisplay}
                </p>
              </Col>
            </Row>
            <Row className="my-3">
              <Col>
                <p>
                  <strong>Comision Nº: </strong>
                  {data.classroom ? data.classroom : `(Sin asignar)`}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Nº Cuota: </strong>
                  {data.monthlyInstallment}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Importe: </strong> ${data.amount}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Curso: </strong> {data.course}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="my-2 p-3 border rounded border-dark">
          <Col>
            <p className="m-0 p-0">
              <strong>Observaciones: </strong>
              {data.additionalNotes}
            </p>
          </Col>
        </Row>
        <Row className="my-2 p-3">
          <Col md="6" />
          <Col md="6">
            <p className="m-0 p-0">
              <strong>Recibo por: </strong>
              {user.username}
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
