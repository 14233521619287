import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

export default class BSModal extends Component {
  render() {
    return (
      <>
        <Modal centered show={this.props.show} onHide={this.props.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.props.body}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={
                this.props.returnHome
                  ? this.props.returnHome
                  : this.props.handleClose
              }
            >
              Close
            </Button>
            <Button
              variant={this.props.variant}
              onClick={() => this.props.action()}
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
