import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import clientAxios from '../../../../clients/clientAxios';

const Plans = ({ 
  show, 
  studentId, 
  student, 
  setFullPlanInfo, 
  fullPlanInfo,
  classroomIndex,
  getStudentData,
  handlePlansModalClose,
}) => {
  const [plans, setPlans] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const handleChange = (e) => {
    setPlanId(e.target.value)
    setSelectedPlan(e.target.value);
  }

  const getPlans = async () => {
    try {
      const { data } = await clientAxios(`/planes/`);
      setPlans(data);
    } catch (error) {
      console.error(error);
    }
  };

  const savePlan = async () => {
    try{
      setIsLoading(true);
      await clientAxios.patch(`/students/${studentId}/plan`, {
        fullPlanInfo,
        classroomIndex
      })
      getStudentData();
      handlePlansModalClose();
    }catch(error){
    console.log(error)
    }finally{
      setIsLoading(false);
    }
  }

  useEffect(()=>{
    if(show){
      getPlans();
    }
  }, [show])

  useEffect(()=> {
    if(planId){
      setFullPlanInfo(prevPlanInfo => ({
        ...prevPlanInfo,
        planId
      }));
    }
  }, [planId])

  return (
    <>
      <Modal show={show} onHide={handlePlansModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Asignar Plan de Pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <h5>{`${student?.firstname} ${student?.surname}`}</h5>
                <p></p>
                <div className='d-flex align-items-center mb-5'>
                  <InputGroup className='m-0 p-0 pb-1'>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Planes</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      as='select'
                      name='commissionChangedReason'
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={!selectedPlan ? '' : selectedPlan}
                    >
                      <option value=''>Seleccione un plan</option>
                      {plans?.map((plan, index) => {
                        return (
                          <option key={index} value={plan?._id}>
                            {plan?.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </InputGroup>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handlePlansModalClose}>
            Cancelar
          </Button>
          <Button
            variant='primary'
            disabled={isLoading}
            onClick={() => {
              savePlan();
            }}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Plans;
