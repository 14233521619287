import { CAMPUS_COURSE_ONBOARDING_FS_ID, CAMPUS_COURSE_WORKSHOPS_ID, CAMPUS_USER_ROLL_STUDENT_ID, CAMPUS_USER_SEND_EMAIL_FLAG } from "./constants";

export const userNormalizationForCampus = (student) => {
    const filterName = student.firstname
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  const filterSurname = student.surname
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  const campusUser = {
    username: student.email,
    firstname: filterName,
    lastname: filterSurname,
    email: student.email,
    sendEmail: CAMPUS_USER_SEND_EMAIL_FLAG,
    roleid: CAMPUS_USER_ROLL_STUDENT_ID,
    courseid: CAMPUS_COURSE_ONBOARDING_FS_ID,
    courseid2: CAMPUS_COURSE_WORKSHOPS_ID,
  };
  return campusUser
}