import moment from "moment";

export const calculateDollarSalesPerMonth = (data) => {
    return data.slice(1).reduce((acc, item) => {
      const fecha = item[0];
      const month = moment(fecha, 'DD/MM/YYYY').format('MM');
      const sale = parseFloat(item[2]);

      acc[month] = acc[month] || { total: 0, count: 0 };
      acc[month].total += sale;
      acc[month].count++;

      return acc;
    }, {})

  }

export const calculateAveragePerMonth = (salesPerMonth) => {
    return Object.keys(salesPerMonth).reduce((result, key) => {
      const average = salesPerMonth[key].total / salesPerMonth[key].count || 0;
      result[key] = parseFloat(average.toFixed(2));
      return result;
    }, {});    
  };

export const convertToArray = (average) => {
    const months = {
      "01": "Enero",
      "02": "Febrero",
      "03": "Marzo",
      "04": "Abril",
      "05": "Mayo",
      "06": "Junio",
      "07": "Julio",
      "08": "Agosto",
      "09": "Sept.",
      "10": "Oct.",
      "11": "Nov.",
      "12": "Dic."
    };

    return Array.from({ length: 12 }, (_, index) => {
      const monthNum = (index + 1).toString().padStart(2, '0');
      const monthName = months[monthNum];
      return {
          name: monthName || `Mes ${monthNum}`,
          average: average[monthNum] || 0
      };
  });
  }

  const calculateResult = (average, total) => {
    if (average !== 0 && total !== 0) {
        const result = parseFloat(total) / average;
        return parseFloat(result.toFixed(2));
    }
    return 0;
};

export const convertToDollars = (equalArray, copyData) => {
return equalArray.map(({ name, average }) => {
    const totalObj = copyData.find(item => item.name === name);
    if (totalObj) {
        return { name, Total: calculateResult(average, totalObj.Total), averageMonth: average };
    }
    return null;
}).filter(Boolean);
}