import Axios from "axios";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Nav, NavDropdown } from "react-bootstrap";
import { notify } from "react-notify-toast";
import { Link } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";

export default function UserAdministration() {
  const history = useHistory();
  const user = useContext(UserContext);
  const userdata = JSON.parse(localStorage.getItem("userdata") || "{}");

  /**
   * Borra el 2FA del usuario actual
   */
  const delete2FA = () => {
    if (window.confirm("Eliminar 2FA?")) {
      Axios.put(
        "/api/user/2fa/remove",
        {},
        { headers: { "auth-token": localStorage.getItem("JWT") || " "} }
      ).then(({ data }) => {
        if (data.success) {
          notify.show(data.message, "success");
          var a = JSON.parse(localStorage.getItem("userdata") || "{}");
          delete a.has2FA;
          localStorage.setItem("userdata", JSON.stringify(a));
          history.push("/");
        } else {
          notify.show("Error eliminando 2FA", "error");
        }
      });
    }
  };

  return (
    <Nav>
      {user.username ? (
        <NavDropdown title={`Usuario: ${user.username}`} id="Users">
          <NavDropdown.Item target="_blank" href="https://certsadmin.rollingcodeschool.com/auth/login">
            Ir a Certificate Manager APP
          </NavDropdown.Item>
          <NavDropdown.Item>
            <Link
              className="text-dark text-decoration-none"
              to={`/users/pswrdChange/${user.userId}`}
            >
              Cambiar contraseña
            </Link>
          </NavDropdown.Item>
          {!userdata.has2FA ? (
            <NavDropdown.Item href="/users/2fa/generate">
              Agregar 2Factor Auth.
            </NavDropdown.Item>
          ) : user.hasVerified2FA ? (
            <NavDropdown.Item onClick={delete2FA}>
              Eliminar 2FA
            </NavDropdown.Item>
          ) : null}
          <NavDropdown.Item>
            <Link className="text-dark text-decoration-none" to="/users/logout">
              Cerrar Sesión
            </Link>
          </NavDropdown.Item>
        </NavDropdown>
      ) : (
          <Link className="nav-link" to="/users/login">
            Iniciar sesión
          </Link>
        )}
    </Nav>
  );
}
