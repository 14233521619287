export const screens = [
  {
    name: "Escribir un mensaje",
    disabled: false,
    icon: "far fa-edit",
    active: 0,
  },
  {
    name: "Enviar una notificación",
    disabled: false,
    icon: "far fa-comment",
    active: 1,
  },
  {
    name: "(Feature no implementada)",
    disabled: true,
    icon: "far fa-times-circle",
    active: 2,
  },
  {
    name: "(Feature no implementada)",
    disabled: true,
    icon: "far fa-times-circle",
    active: 3,
  },
];
