import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { ClassroomList } from "../../../Context/ClassroomListContext";
import Axios from "axios";
import { notify } from "react-notify-toast";
import clientAxios from "../../../clients/clientAxios";

export default function Filter({
  filterSubmit,
  isLoading,
  origin,
}: {
  filterSubmit: (query: { student: string; classroom: string, takenBy: string }) => void;
  isLoading: boolean;
  origin: string;
}) {

  const classroomList = useContext(ClassroomList);
  const [state, setState] = useState({
    student: "",
    classroom: classroomList?.crList[0]?.classroom,
    takenBy: ""
  });
  const [communicationPersonal, setCommunicationPersonal] = useState([]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    filterSubmit(state);
  };

  const clearFilter = () => {
    setState({
      student: "",
      classroom: "",
      takenBy: ""
    });

    filterSubmit({
      student: "",
      classroom: "",
      takenBy: ""
    });
  };

  const handleChange = (e: any) => {
    let { name, value } = e.target;
    if (name === "classroom") {
      setState({ ...state, [name]: value.toString().trim() });
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const getCommunicationPersonal = () => {
    clientAxios.get(`/com/personal`)
      .then(({ data }) => {
        if (data.success) {
          let communicationPersonal: any = []
          data.data.map((i: any) => {
            communicationPersonal.push({ name: i.name })
          })
          setCommunicationPersonal(communicationPersonal);
        }
      })
      .catch((err) => {
        notify.show(`Error: ${err.response.data.message}`, "error");
      });
  }

  useEffect(() => {
    getCommunicationPersonal()
    // eslint-disable-next-line
  }, [])

  return (
    <Col className="bg-light rounded shadow d-flex justify-content-center py-3 mb-3">
      <Form className="w-100" onSubmit={onSubmit}>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Estudiante:</Form.Label>
              <Form.Control
                type="text"
                name="student"
                value={state.student}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>Comisión:</Form.Label>
              <Form.Control
                onChange={handleChange}
                value={state.classroom}
                name="classroom"
                as="select"
              >
                <option value="">Ver Todas</option>
                {classroomList.crList.map(
                  (
                    i: { classroom: React.ReactNode },
                    index: string | number
                  ) => (
                    <option key={index.toString().trim()}>{i.classroom}</option>
                  )
                )}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        {origin !== "debtors" ? (
          <Row>
            <Col md="6">
              <Form.Group>
                <Form.Label>Tomado por:</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  value={state.takenBy}
                  name="takenBy"
                  as="select"
                  disabled={communicationPersonal.length === 0}
                >
                  <option value="" hidden>Seleccione una opción</option>
                  {communicationPersonal.map(
                    (
                      i: { id: string, name: string },
                      index: string | number | undefined
                    ) => (
                      <option key={index}>{i.name}</option>
                    )
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        ) : <></>}
        <Row>
          <Col md="6" className="d-flex align-items-center">
            {isLoading ? (
              <div className="text-muted">
                <Spinner animation="border" size="sm" /> Cargando...
              </div>
            ) : null}
          </Col>
          <Col className="my-auto">
            <Button
              disabled={isLoading}
              className="w-100"
              variant="info"
              type="submit"
            >
              <FontAwesomeIcon icon={faSearch} /> Filtrar
            </Button>
          </Col>
          <Col className="my-auto">
            <Button
              disabled={isLoading}
              onClick={clearFilter}
              className="w-100"
              variant="danger"
            >
              <FontAwesomeIcon icon={faTimesCircle} /> Limpiar filtro
            </Button>
          </Col>
        </Row>
      </Form>
    </Col>
  );
}
