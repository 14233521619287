import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Axios from "axios";
import { notify } from "react-notify-toast";
import LoadingScreen from "../../Layout/LoadingScreen";

export default function ChargeRcCoins() {
  // Context loading navbar

  const history = useHistory();

  const headerConfig = {
    headers: { "auth-token": localStorage.getItem("JWT") || " "},
  };
  const [phoneList, setPhoneList] = useState([]);
  const [form, setForm] = useState({
    rcCoins: 0,
    uuid: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    Axios.get(`/api/rollingkiosk/allClients`, headerConfig)
      .then(({ data }) => {
        setPhoneList(data.data);
        setIsLoading(false);
      })
      .catch((err) => notify.show(err.response.data.message, "error"));

    // Se deshabilita el error que da en la consola de useEffect debido
    // a que la variable headerConfig necesita ser llamada desde múltiples sectores
    // Y la idea es evitar código duplicado.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const saveTransaction = (e) => {
    e.preventDefault();
    setIsSaving(true);

    Axios.put(
      `api/rollingkiosk/client/addRcCoins/${form.uuid}`,
      { rcCoins: form.rcCoins },
      headerConfig
    )
      .then((i) => {
        setIsSaving(false);
        notify.show(i.data.message, "success");
        history.push("/students/viewAll");
      })
      .catch((err) => {
        setIsSaving(false);
        notify.show(err.response.data.message, "error");
      });
  };

  return (
    <Container>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Row>
            <Col className="bg-light shadow rounded mb-5 p-3">
              <Form onSubmit={saveTransaction}>
                <p className="lead">Recargar RcCoins:</p>
                <Form.Group>
                  <Form.Label>¿A quién se le realizará la recarga?</Form.Label>
                  <Form.Control
                    as="select"
                    name="uuid"
                    value={form.uuid}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Seleccione una opcion...</option>
                    {phoneList.map((item, index) => (
                      <option value={item.uuid} key={index}>
                        {item.name} {item.lastname}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>¿Cuanto recargará?</Form.Label>
                  <Form.Control
                    min={0}
                    type="number"
                    name="rcCoins"
                    value={form.rcCoins}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Button disabled={isSaving} type="submit" variant="info">
                  Recargar
                </Button>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}
