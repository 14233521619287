import React, { Component, createContext } from "react";
import Axios from "axios";
import { USER_LEVEL_START } from "../constants";

export const UserContext = createContext();

export class UserProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      isLoggedIn: "",
      id: "",
      has2FA: false,
      hasVerified2FA: false,
      level: USER_LEVEL_START,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("JWT")) {
      if (localStorage.getItem("userdata")) {
        try {
          var a = JSON.parse(localStorage.getItem("userdata"));
          this.setState(a);
        } catch (err) {
          localStorage.clear();
        }
        this.setState({ isLoggedIn: true });
      }
    } else {
      localStorage.clear();
      this.setState({
        level: USER_LEVEL_START,
      });
    }

    this.checkForValidUser();
  }

  checkForValidUser = () => {
    if (localStorage.getItem("JWT")) {
      Axios.get("/api/user/checkUser", {
        headers: { "auth-token": localStorage.getItem("JWT") || " "},
      }).then((response) => {
        this.setState({
          level: response.data.level,
        });
      });
    }
  };

  setData = (username, id, isLoggedIn, level, has2FA) => {
    this.setState({ username, id, isLoggedIn, level, has2FA });
  };

  logout = () => {
    this.setState({
      username: "",
      id: "",
      isLoggedIn: false,
      level: USER_LEVEL_START,
      has2FA: false,
      hasVerified2FA: false,
    });
  };

  render() {
    return (
      <UserContext.Provider
        value={{
          setData: (username, id, isLoggedIn, level, has2FA) =>
            this.setData(username, id, isLoggedIn, level, has2FA),
          username: this.state.username,
          isLoggedIn: this.state.isLoggedIn,
          level: this.state.level,
          userId: this.state.id,
          has2FA: this.state.has2FA,
          logout: this.logout,
          hasVerified2FA: this.state.hasVerified2FA,
          verify2FA: (e) => {
            this.setState({ hasVerified2FA: e })
          },
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
