import React, { useEffect, useRef, useState } from "react";
import { savePDF } from "@progress/kendo-react-pdf";
import { Container } from "react-bootstrap";
import Buttons from "./print/Buttons";
import Invoice from "./print/Invoice";
import { useHistory, useParams } from "react-router-dom";
import Axios from "axios";
//eslint-disable-next-line
import { invoice } from "../../Types/Invoice";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { notify } from "react-notify-toast";

export default function PrintBill() {
  const invoice = useRef<any>(<div />);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [state, setState] = useState<invoice>({
    _id: "",
    additionalNotes: "",
    amount: 0,
    billNumber: 0,
    classroom: "",
    course: "",
    createdAt: "",
    createdBy: { id: "", name: "" },
    date: "",
    email: "",
    installmentQuantity: "",
    monthlyInstallment: "",
    nameDisplay: "",
    ogAmount: 0,
    partial: false,
    payment: "",
    percent: "",
    prepay: false,
    studentId: "",
    updatedAt: "",
  });

  useEffect(() => {
    Axios.get(`/api/bills/get/${id}`, {
      headers: { "auth-token": localStorage.getItem("JWT") || " "},
    })
      .then(({ data }) => {
        setState({ ...data });
      })
      .catch((err) => {});
    //eslint-disable-next-line
  }, []);

  const exportPDFToFile = () => {
    savePDF(invoice.current, {
      paperSize: "A4",
      fileName: `Invoice - ${Intl.DateTimeFormat(navigator.language, {
        month: "numeric",
        day: "numeric",
        year: "numeric",
      }).format(new Date(state.date))} - ${id}`,
      landscape: true,
      scale: 0.75,
    });
    notify.show("PDF Guardado", "success");
    setTimeout(() => history.push("/"), 1000);
  };

  const transformPDFToBase64 = () => {
    const base64 = drawDOM(invoice.current, {
      paperSize: "A4",
      landscape: true,
      scale: 0.75,
    }).then((group: any) => {
      return exportPDF(group);
    });

    return base64;
  };

  const sendEmail = async (date: string, email: string) => {
    const file = await transformPDFToBase64();

    Axios.post(
      "/api/mail/send/",
      {
        file,
        date,
        email,
        student: state.nameDisplay,
      },
      {
        headers: { "auth-token": localStorage.getItem("JWT") || " "},
      }
    )
      .then(({ data }) => {
        let { message } = data;
        notify.show(message, "success");
        history.push("/");
      })
      .catch((error) => {
        notify.show(error.response.data, "error");
      });
  };

  return (
    <Container>
      <Buttons
        exportPDFToFile={exportPDFToFile}
        sendEmail={(date: string, email: string) => sendEmail(date, email)}
        emailFromStudent={state.email}
      />
      <div className="bg-white p-5 mb-5 shadow rounded" ref={invoice}>
        <Invoice data={state} />
        <Invoice data={state} isDuplicate />
      </div>
    </Container>
  );
}
