import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { notify } from "react-notify-toast";
import { useHistory } from "react-router-dom";

const Messages = () => {
  const history = useHistory();
  const headerConfig = {
    headers: { "auth-token": localStorage.getItem("JWT") || " "},
  };

  const [yourMessages, setYourMessages] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [mentorId, setMentorId] = useState("");

  let counter = 0;

  useEffect(() => {
    if (counter === 0) {
      fetchMessages();
    }

    //eslint-disable-next-line
  }, []);

  const fetchMessages = () => {
    axios
      .get(`api/mentor/messages`, headerConfig)
      .then(({ data }) => {
        setYourMessages(data.data);
        setMentorId(data.caller);
      })
      .catch((err) => {
        notify.show(err.response.data.message, "error");
        history.push("/");
      });
  };

  const viewedMessage = (id) => {
    setIsSaving(true);
    let message = yourMessages.filter((i) => i._id === id);
    message[0].viewed.push(mentorId);

    axios
      .put(`api/com/viewed/${id}`, message, headerConfig)
      .then(() => {
        fetchMessages();
        setIsSaving(false);
      })
      .catch((err) => {
        notify.show(err.response.data.message, "error");
        history.push("/");
      });
  };

  return (
    <Container fluid>
      {!yourMessages ? (
        <div className="pt-3 pl-3 pr-3 mt-3 bg-light rounded shadow">
          <Row className="pl-3">
            <Col>
              <p className="lead text-center py-3">
                Todavía no recibiste ningún mensaje, volve más tarde!
              </p>
            </Col>
          </Row>
        </div>
      ) : (
        yourMessages.map((i) => (
          <div
            className="pt-3 pl-3 pr-3 mt-3 bg-light rounded shadow"
            key={i._id}
          >
            <Row className="pl-3">
              <Col>
                <h1>{i.title}</h1>
              </Col>
            </Row>
            <Row className="pl-3">
              <Col>
                <p>{i.body}</p>
              </Col>
            </Row>
            <Row className="pl-3 ">
              <Col className="d-flex justify-content-end">
                <p className="">{`Mensaje de: ${
                  i.createdBy.name
                } / Enviado el: ${moment(i.createdAt).format("LLLL")}`}</p>
                <button
                  style={{
                    cursor: "pointer",
                    border: "none",
                    background: "none",
                    marginBottom: "1rem",
                  }}
                  className="p-0 ml-2"
                  disabled={isSaving}
                  onClick={() => viewedMessage(i._id)}
                >
                  <u>Marcar como leído</u>
                </button>
              </Col>
            </Row>
          </div>
        ))
      )}
    </Container>
  );
};

export default Messages;
