import React from "react";
import { Col } from "react-bootstrap";
import moment from "moment";

export default function AdditionalData({
  itKnowleadge = "",
  englishKnowleadge = "",
  codeKnowleadge = "",
  availableSat = false,
  availableTimeFrom,
  availableTimeTo,
  scholarship = "-",
  welcomeEmail,
  additionalNotes,
}) {
  const translateLevel = (level) => {
    switch (level) {
      case "high":
        return "Alto";

      default:
        return "No especificado";
    }
  };

  const translateBoolean = (boolean) => {
    return boolean ? "Si" : "No";
  };

  const translateScholarship = () => {
    switch (scholarship) {
      case "beca":
        return "Si";

      case "mediabeca":
        return "Media beca";

      default:
        return "No especificado";
    }
  };

  const displayAvailableTime = () => {
    if (!availableTimeFrom && !availableTimeTo) return "-";

    return `${availableTimeFrom ? `Desde las ${availableTimeFrom}` : ""} ${
      availableTimeTo ? `Hasta las ${availableTimeTo}` : ""
    }`;
  };

  return (
    <Col className="ml-3 mb-5 p-3 bg-light shadow rounded">
      <p>
        {additionalNotes === "" ? (
          <p className="text-secondary small">Sin Notas Adicionales.-</p>
        ) : (
          <p className="p-1 w-100 rounded bg-danger text-light small">
            Notas Adicionales:
          </p>
        )}
      </p>
      <p>
        {additionalNotes === "" ? null : (
          <small variant="secondary">{additionalNotes}</small>
        )}
      </p>
      <hr></hr>
      <p className="lead m-0 p-0">Datos adicionales:</p>
      <br />
      <p>
        <small className="text-muted">Conocimientos en computación: </small>
        <span>{translateLevel(itKnowleadge)}</span>
      </p>
      <p>
        <small className="text-muted">Conocimientos en programación: </small>
        <span>{translateLevel(englishKnowleadge)}</span>
      </p>
      <p>
        <small className="text-muted">Conocimientos en inglés: </small>
        <span>{translateLevel(codeKnowleadge)}</span>
      </p>
      <p>
        <small className="text-muted">Posee disponibilidad los sábados: </small>
        <span>{translateBoolean(availableSat)}</span>
      </p>
      <p>
        <small className="text-muted">Mail de bienvenida por MailChimp: </small>
        <span>{welcomeEmail}</span>
      </p>
    </Col>
  );
}
