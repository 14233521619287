import React, { useEffect, useState, createContext } from "react";
import Axios from "axios";

export const StudentListC = createContext();

export let studentListLet = [];

export function StudentListProvider({ children }) {
  const [stList, setStList] = useState([]);

  useEffect(() => {
    checkJWT();
    //eslint-disable-next-line
  }, [localStorage.getItem("JWT")]);

  const checkJWT = () => {
    if (
      (localStorage.getItem("JWT") && localStorage.getItem("JWT") !== "") ||
      localStorage.getItem("JWT") !== null
    ) {
      Axios.get("/api/user/verifyActiveUserNop", {
        headers: { "auth-token": localStorage.getItem("JWT") },
      })
        .then((response) => {})
        .catch((err) => {
          console.error("error del studentListContext: ", err);
          setTimeout(checkJWT, 1000);
        });
    }
  };

  return (
    <StudentListC.Provider value={stList}>{children}</StudentListC.Provider>
  );
}

export function setStudentListContext(data) {
  studentListLet = data;
}
