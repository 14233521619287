import React from "react";
import {
  ListGroup,
  ListGroupItem,
  Modal,
  Button,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

export default function ZoomModal({
  show,
  onHide,
  meetings,
  users,
  usersAnswer,
  meetingsAnswer,
}) {
  return (
    <Modal size="xl" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Selecciona una de las acciones</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            {usersAnswer.length !== 0 ? (
              <Col className="mx-2 pb-2 bg-light rounded shadow">
                <Row>
                  <Col>
                    <p className="m-0 mt-3 p-0 lead">Usuarios</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="pt-3">
                    <ListGroup
                      as="ul"
                      style={{ height: 250, overflowY: "scroll" }}
                    >
                      {usersAnswer.length === 0 ? (
                        <ListGroupItem>
                          Este Zoom no tiene usuarios
                        </ListGroupItem>
                      ) : (
                        usersAnswer.map((user, index) => {
                          return (
                            <ListGroupItem key={index}>
                              <Row>
                                <Col xs={10} xl={10}>
                                  {`${user.first_name} ${user.last_name}`}
                                </Col>
                              </Row>
                            </ListGroupItem>
                          );
                        })
                      )}
                    </ListGroup>
                  </Col>
                </Row>
              </Col>
            ) : null}
            {meetingsAnswer.length !== 0 ? (
              <Col className="mx-2 mt-2 pb-2 bg-light rounded shadow">
                <Row>
                  <Col>
                    <p className="m-0 mt-3 p-0 lead">Meetings</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="pt-3">
                    <ListGroup
                      as="ul"
                      style={{ height: 250, overflowY: "scroll" }}
                    >
                      {meetingsAnswer.meetings.length === 0 ? (
                        <ListGroupItem>
                          Este Zoom no tiene meetings
                        </ListGroupItem>
                      ) : (
                        meetingsAnswer.meetings.map((meet, index) => {
                          return (
                            <ListGroupItem key={index}>
                              <Row>
                                <Col xs={12} xl={12}>
                                  <b>Nombre:</b> {meet.topic}
                                </Col>
                                <Col xs={12} xl={12}>
                                  <b>Horario:</b>{" "}
                                  {moment(new Date(meet.start_time)).format(
                                    "YYYY-MM-DD hh-mm-ss"
                                  )}
                                </Col>
                                <Col xs={12} xl={12}>
                                  <b>Link:</b> {meet.join_url}
                                </Col>
                              </Row>
                            </ListGroupItem>
                          );
                        })
                      )}
                    </ListGroup>
                  </Col>
                </Row>
              </Col>
            ) : null}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => meetings()}>Meetings</Button>
        <Button onClick={() => users()}>Usuarios</Button>
        <Button variant="danger" onClick={onHide}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
