import Axios from "axios";
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { notify } from "react-notify-toast";
import { UserContext } from "../../Context/UserContext";
import { State, loggedInUser } from "../../Types/Login";
import { USER_LEVEL_MENTOR_STATISTICS, USER_LEVEL_STATISTICS, USER_LEVEL_MENTOR } from "../../constants";
import authToken from "../../clients/authToken";

export default function Login() {
  const history = useHistory();
  const user = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState<Partial<State>>({
    username: "",
    password: "",
    id: "",
    response: {},
  });

  const handleChange = (obj: any) => {
    let { name, value } = obj.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  /**
   *
   * Redirige al usuario según corresponda
   *
   *    @param message Mensaje a mostrar si falla o el logueo es exitoso
   *    @param has2fa Token de Google Authenticator
   *    @param firstLogin Booleano que determina si llevarte a la pantalla de Password o no
   *    @param level Nivel de accesso, 1-Admin, 2-Comunicaciones, 3-Mentor
   *    @param _id id del usuario logueado exitosamente
   */
  const redirectUser = ({
    message,
    has2fa,
    firstLogin,
    level,
    _id,
  }: loggedInUser) => {
    setTimeout(() => {
      notify.show(message, "success");
      if (has2fa) {
        history.push("/students/viewAll");
      } else if (firstLogin) {
        history.push("/users/pswrdChange/" + _id);
      } else if (level === USER_LEVEL_MENTOR || level === USER_LEVEL_MENTOR_STATISTICS) {
        history.push("/teacher");
      } else if (level === USER_LEVEL_STATISTICS) {
        history.push("/graphic");
      } else {
        history.push("/students/viewAll");
      }
      setIsLoading(false);
    }, 2000);
  };

  /**
   * Guarda datos al localStorage para la persistencia
   * de sesión
   *
   * @param auth Token de JWT para el login
   * @param data Objeto de respuesta del login (See backend)
   */
  const saveToLocalStorage = (auth: string, data: any) => {
    localStorage.setItem("JWT", auth);
    authToken(auth);
    localStorage.setItem(
      "userdata",
      JSON.stringify({
        username: data.username,
        id: data._id,
        has2FA: data.has2fa,
      })
    );
  };

  const onSubmit = (e: any) => {
    let { username, password } = state;

    e.preventDefault();
    setIsLoading(true);

    Axios.post("/api/user/login", { email: username, password: password })
      .then((res) => {
        setState({ id: res.data._id, response: res });
        saveToLocalStorage(res.headers.auth, res.data);
        user.setData(
          res.data.username,
          res.data._id,
          true,
          res.data.level,
          res.data.has2fa
        );
        localStorage.setItem('userLevel', res.data?.level);
        redirectUser(res.data);
      })
      .catch((err) => {
        notify.show(err.response.data.message, "error");
        setIsLoading(false);
      });
  };

  return (
    <Container>
      <Row>
        <Col md="3" />
        <Col className="border-gray mb-5 bg-light shadow rounded p-4">
          <Form onSubmit={onSubmit} autoComplete="off">
            <Row>
              <Col>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Dirección de correo</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="usuario@dominio.com"
                    name="username"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="*************"
                    name="password"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="d-flex justify-content-end">
                <Button variant="primary" type="submit" disabled={isLoading}>
                  {isLoading ? "Iniciando sesión... " : "Ingresar"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col md="3" />
      </Row>
    </Container>
  );
}
