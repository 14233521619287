import React from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import Smallbox from "./Smallbox";
import USDSmallbox from "./USDSmallbox";

export default function Graphics({ history }) {

  let tabs = [{ eventKey: "ars", title: "Pesos ARS", currency: "ARS" }, { eventKey: "usd", title: "Dolar USD", currency: "USD" }, { eventKey: "eur", title: "Euros EUR", currency: "EUR" }]

  return (
    <Container className="mb-5">
      <Tabs id="tabs" defaultActiveKey="ars" className="border-0">
        {tabs.map((currency) => (
          <Tab eventKey={currency.eventKey} title={currency.title}>
            <Smallbox currency={currency.currency} history={history} />
          </Tab>
        ))}
        {/* <Tab eventKey="ars" title="Pesos ARS">
          <Smallbox currency="ARS" history={history} />
        </Tab>
        <Tab eventKey="usd" title="Dolar USD">
          <Smallbox currency="USD" history={history} />
        </Tab> */}
      </Tabs>
    </Container>
  );
}
