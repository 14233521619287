import React, { useState } from 'react';
import { Modal, Row, Button, Form, InputGroup, Container } from "react-bootstrap";
import { notify } from "react-notify-toast";
import axios from "axios";

export default function EditPasswordTeacher({
    show,
    onHide,
    modalState,
}: {
    show: boolean;
    onHide: () => void;
    modalState: {
        id: string,
        delete: boolean,
        edit: boolean,
        editPass: boolean,
        name: string,
        email: string,
        isActive: boolean
    };
}) {
    const [newPass, setNewPass] = useState("");
    const [newPassConf, setNewPassConf] = useState("");

    const updateMentorPass = async () => {
        if (newPass) {
            if (newPass === newPassConf) {
                try {
                    await axios.put(`api/user/updateMentor/${modalState.id}`,
                        {
                            password: newPass,
                        },
                        { headers: { "auth-token": localStorage.getItem("JWT") || " "} })
                    notify.show("Password modificada con éxito!", "success")
                    onHide()
                } catch (err) {
                    notify.show("Ocurrió un error al actualizar la password!", "error")
                }
            } else {
                notify.show("Las contraseñas no coinciden!", "error")
            }

        } else {
            notify.show("Por favor ingrese una nueva password!", "error")
        }

    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>Editar Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Nueva Password</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                onChange={(e) => { setNewPass(e.target.value) }}
                                type="password"
                                required
                            >
                            </Form.Control>
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Confirmar Password</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                onChange={(e) => { setNewPassConf(e.target.value) }}
                                type="password"
                                required
                            >
                            </Form.Control>
                        </InputGroup>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onHide}>
                    Cancelar
        </Button>
                <Button variant="success" onClick={updateMentorPass}>
                    Actualizar
        </Button>
            </Modal.Footer>
        </Modal >
    )
}