import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Button,
  Popover,
  OverlayTrigger,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMugHot, faLightbulb } from "@fortawesome/free-solid-svg-icons";
import BirthdaySidebar from "../sidebar/Birthday";
import StudentsFilter from "./Filter";
import PaginationBar from "../../Pagin";
import ListDisplay from "./ListDisplay";
import Axios from "axios";
import { notify } from "react-notify-toast";
import LoadingScreen from "../../../Layout/LoadingScreen";
import { useHistory } from "react-router-dom";
import InternalNotifications from "../../../Layout/InternalNotifications/InternalNotifications";
import { setStudentListContext } from "../../../Context/StudentListContext";
import clientAxios from "../../../clients/clientAxios";

export default function StudentList() {
  const history = useHistory();
  const [students, setStudents] = useState([]);
  const [paginatedResults, setPaginatedResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSmartDeviceApiWaiting, setIsSmartDeviceApiWaiting] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(false);

  useEffect(() => {
    const currentTimeLocalString = new Date().toLocaleString();
    setLastUpdate(currentTimeLocalString);
  }, [students]);

  const getStudentListOptions = (query) => {
    getStudentListFromDatabase(query);
  };

  const getStudentListFromDatabase = (query) => {
    setLoadingFilter(true);
    const params =
      query && !query.hasOwnProperty("email") ? { getAll: true } : { query };
    Axios.get(`/api/students`, {
      params: params,
      headers: { "auth-token": localStorage.getItem("JWT") },
    })
      .then((response) => {
        if (!response || !response.data.success) {
          notify.show(
            "Ha ocurrido un error obteniendo los estudiantes. Por favor, reintente",
            "error"
          );
        } else {
          setLoadingFilter(false);
          setIsLoading(false);
          setStudents(response.data.data);
          setStudentListContext(response.data.data);
        }
      })
      .catch((err) => {
        if (err && err.response) {
          notify.show(err.response.data.message, "error");
        }
        history.push("/");
      });
  };

  const onChangePage = (pagination) => {
    setPaginatedResults(pagination);
  };

  const toggleCoffeeSignal = (scene) => {
    setIsSmartDeviceApiWaiting(true);
    clientAxios
      .post(`/smartthings/scenes`, { sceneName: scene })
      .then(({ data }) => {
        if (data.success) {
          notify.show("Dispositivo actualizado", "success");
          setIsSmartDeviceApiWaiting(false);
        } else {
          notify.show(data?.message, "error");
          setIsLoading(false);
          setIsSmartDeviceApiWaiting(false);
        }
      })
      .catch((err) => {
        notify.show(
          "No se pudo actualizar el estado del dispositivo, por favor verifique que se encuentre correctamente conectado e intente de nuevo",
          "error"
        );
        setIsLoading(false);
      });
  };

  const popoverTop = (
    <Popover id="popover-positioned-top" positionLeft={300} positionTop={-50}>
      {isSmartDeviceApiWaiting ? (
        <Spinner> Brewing cofee...</Spinner>
      ) : (
        <div>
          <Button size="sm" className="w-10 coffee-signal-button">
            <FontAwesomeIcon
              icon={faLightbulb}
              onClick={() => toggleCoffeeSignal("COFFEE_SIGNAL_UP")}
            />
          </Button>
          <Button size="sm" className="w-10 coffee-signal-button lightBulbOff">
            <FontAwesomeIcon
              icon={faLightbulb}
              onClick={() => toggleCoffeeSignal("COFFEE_SIGNAL_DOWN")}
            />
          </Button>
        </div>
      )}
    </Popover>
  );

  if (!isLoading) {
    return (
      <Container>
        <OverlayTrigger
          trigger="click"
          placement="left"
          overlay={popoverTop}
          rootClose
        >
          <Button size="sm" className="w-10 coffee-signal-button">
            <FontAwesomeIcon icon={faMugHot} />
          </Button>
        </OverlayTrigger>
        <InternalNotifications />
        <BirthdaySidebar classroom="general" />
        <Row>
          <StudentsFilter
            filterStudents={(query) => getStudentListOptions(query)}
            loadingFilter={loadingFilter}
          />
        </Row>
        <Row>
          {loadingFilter && (
            <div className="m-auto my-3 py-3">
              <div className="spinner"></div>
              <p className="m-0 p-0 strong rclogo-center">{"< >"}</p>
            </div>
          )}
          {!loadingFilter && (
            <ListDisplay
              studentsList={paginatedResults}
              studentCount={students.length}
              refreshList={getStudentListFromDatabase}
              lastUpdate={lastUpdate}
            />
          )}
        </Row>
        <Row className="d-flex justify-content-center p-3 mb-5 bg-white rounded shadow">
          <PaginationBar
            items={students}
            pageSize={5}
            onChangePage={onChangePage}
          />
        </Row>
      </Container>
    );
  } else {
    return <LoadingScreen customText="Cargando datos de alumnos..." />;
  }
}
