import React from 'react'
import { Row, Col, ListGroup, Button } from 'react-bootstrap'
import moment from 'moment'

export default function CustomCard(props) {

    const { barcode, updatedAt, name, price, stock } = props.data

    return (
        <ListGroup.Item>
            <Row>
                <Col md='3' className='text-truncate w-100'>{name}</Col>
                <Col md='1' className='text-center'>${price}</Col>
                <Col md='1' className='text-center'>{stock}</Col>
                <Col className='text-center'>{barcode}</Col>
                <Col className='text-center'>{moment(updatedAt).format('L LTS')}</Col>
                <Col md='2' className='text-center'>
                    <Button size='sm' className='mr-2' onClick={props.editItem} variant='info'><i className="fas fa-pen" /></Button>
                    <Button size='sm' onClick={() => props.deleteItem(barcode)} variant='danger'><i className="fas fa-trash" /></Button>
                </Col>
            </Row>
        </ListGroup.Item>
    )
}
