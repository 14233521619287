import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

export default function AgeGroupChart({ dataToShow }) {
  return (
    <BarChart
      width={1000}
      height={500}
      data={dataToShow}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip formatter={(value) => [`Cantidad: ${value}`]} />
      <Bar dataKey="value" fill="#8884d8" />
    </BarChart>
  );
}
