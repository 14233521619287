import React, { Component } from "react";
import { Modal, Button, Form, InputGroup, Row, Col } from "react-bootstrap";
import Axios from "axios";
import { exportToCSV } from "./SendCSVEmail";
import moment from "moment";

export default class ConfirmClose extends Component {
  constructor(props) {
    super(props);

    this.state = {
      difference: 0,
      amountExternal: 0,
      notes: "",
      buttonText: "Enviar mail",
      buttonActive: false,
      todayData: [],
      successGetDay: false,
    };

    this.HEADERCONFIG = {
      headers: { "auth-token": localStorage.getItem("JWT") || " "},
    };
  }

  goHome = () => {
    this.props.history.push("/students/viewAll");
  };

  onSubmit = (e) => {
    e.preventDefault();

    Axios.get(
      `api/smallbox/getDay/${moment()
        .startOf("day")
        .format("YYYY-MM-DD")}/${moment().endOf("day").format("YYYY-MM-DD")}`,
      { headers: { "auth-token": localStorage.getItem("JWT") || " "} }
    ).then(({ data }) => {
      this.setState({ todayData: data.data, successGetDay: data.success });
    });

    let POSTDATA = {
      ...this.state,
      action: "close",
      amountInternal: this.props.additionalData.amountInternal,
      difference:
        parseFloat(this.state.amountExternal) -
        parseFloat(this.props.additionalData.amountInternal),
    };

    Axios.post("/api/smallbox", POSTDATA, this.HEADERCONFIG).then(({data}) => {
      if (this.state.successGetDay && data.success) {
        this.setState({ buttonText: "Enviando...", buttonActive: true });
        exportToCSV(
          this.props.onHide,
          {
            ...this.state,
            ...this.props.additionalData,
            difference:
              parseFloat(this.state.amountExternal) -
              parseFloat(this.props.additionalData.amountInternal),
            createdAt: data.createdAt
          },
          this.goHome
        );
      } else {
        this.setState({ buttonText: "Error, intente nuevamente", buttonActive: false });
      }
    });
  };

  render() {
    const DIFFERENCE =
      parseFloat(this.state.amountExternal) -
      parseFloat(this.props.additionalData.amountInternal);

    return (
      <Modal
        centered
        size="lg"
        show={this.props.show}
        onHide={this.props.onHide}
      >
        <Form onSubmit={this.onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Cierre de caja</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>
              Está por cerrar la caja. Esta acción sólo se puede deshacer con
              una nota de credito.
            </p>
            <Row>
              <Col />
              <Col>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        <small>Monto reportado en caja chica.</small>
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          min={0}
                          type="number"
                          name="amountExternal"
                          value={this.state.amountExternal}
                          onChange={(e) =>
                            this.setState({ amountExternal: e.target.value })
                          }
                          required
                        />
                      </InputGroup>
                      <div
                        className={
                          this.state.amountExternal > 0 ? "d-block" : "d-none"
                        }
                      >
                        <Form.Text className="text-muted">
                          Diferencia:{" "}
                          <strong
                            className={
                              DIFFERENCE < 0 ? "text-danger" : "text-muted"
                            }
                          >{`${
                            DIFFERENCE > 0 ? "+" : ""
                          }${DIFFERENCE}`}</strong>
                        </Form.Text>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        <small>Nota adicional.</small>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="notes"
                        value={this.state.notes}
                        onChange={(e) =>
                          this.setState({ notes: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onHide}>
              Cerrar
            </Button>
            <Button
              variant="info"
              disabled={this.state.buttonActive}
              type="submit"
            >
              {" "}
              {this.state.buttonText}{" "}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
